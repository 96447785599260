export interface OnChangeEvent {
  (event: React.ChangeEvent<HTMLInputElement>): void;
}

export enum HeightUnits {
  CM = 'cm',
  FT = 'ft',
}

export enum WeightUnits {
  LBS = 'lbs',
  KG = 'kg',
}

export interface HeightValue {
  cm?: number;
  feet?: number;
  inches?: number;
  unit: HeightUnits;
}

export interface WeightValue {
  weight?: number;
  unit: WeightUnits;
}
