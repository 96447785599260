import React, { useState, useEffect } from 'react';

import '../css/femmar.css';
import { useParams, useNavigate } from 'react-router';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import BigPinkBtns from '../components/BigPinkBtns';
import MyPlanComp from '../components/MyPlanComp';
import LessonPlanModuleComp from '../components/LessonPlanModuleComp';
import myContent from '../jsonContent/trans_SixWk0.json';
import {
  selectLanguageIndex,
  selectProgramStartDate,
} from '../redux/slices/auth';
import SixWk0Image from '../images/pictures/weekly/week0.jpg';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { registerProgramStartAction } from '../redux/actions/user';
import { getProgramDetailction, getProgramLessonsAction } from '../redux/actions/sixweekprograms';
import { selectProgramDetail, selectProgramLessons } from '../redux/slices/sixweekprogram';
import { getWeekZeroAction } from '../api/weekzero';
import iconVideo from '../images/icons/iconVideo.png';


interface WeekData {
  id: number;
  week_number: number;
  week_name: string;
  label: string;
  image_url: string;
  markCompleted: boolean;
}

const SixWk0 = () => {
  const navigate = useNavigate();
  const user = useAppSelector(({ auth }) => auth.user);
  const userName = user?.firstName;
  const userNameActionPlan = `${userName}'s Plan`;
  const [weekData, setWeekData] = useState<WeekData[] | null>(null);
  const programStartDate = useAppSelector(selectProgramStartDate);

  const programLessons = useAppSelector(selectProgramLessons);

  const programDetail = useAppSelector(selectProgramDetail);

  const dispatch = useAppDispatch();

  const params = useParams();
  useEffect(() => {
    const fetchWeekZero = async () => {
      try {
        const response = await getWeekZeroAction();
        setWeekData(response.data.data);
      } catch (error) {
        console.log('error getting action detail', error);
      } 
    };
    fetchWeekZero();
  }, []);

  useEffect(() => {
    if (params.weekNo) {
      dispatch(getProgramLessonsAction({ 'filter[week_id][_eq]': parseInt(params.weekNo, 10), sort: 'order,id' }))
    }
  }, [params.weekNo]);


  useEffect(() => {
    if (params.weekNo) {
      dispatch(getProgramDetailction({ id: parseInt(params.weekNo, 10) }))
    }
  }, [params.weekNo]);

  console.log(" **** programDetails", programLessons);

  const langNum = useAppSelector(selectLanguageIndex);

  const handleBtnActionPlan = () => {
    navigate('/ActionDetail');
  };
  useEffect(() => {
    if (!programStartDate) {
      dispatch(registerProgramStartAction());
    }
  }, [programStartDate]);

  const isWeekZeroCompleted = weekData?.find((week: WeekData) => week.week_number === 0)?.markCompleted;
  const { weekNo } = useParams();
  const gotoScreen = () => {
    
    if (weekNo === '1') {
      navigate(`/Quiz/${18}/${0}`);
    } 
    if (weekNo === '4') {
      navigate(`/Quiz/${27}/${3}`);
    } 
    if (weekNo === '6') {
      navigate(`/Quiz/${41}/${5}`);
    } 
  };
  const renderExtraButton = () => {
    if (weekNo === '1') {
      return (
        <button
          type="button"
           onClick={() => gotoScreen()}
        className="weekly-menu-btn"
        >
          <div className="flex flex-row flex-item-center flex-space-between gap0">
          <div className="bg-femmar-purple flex1 video-btn-container margin-half">
            <img src={iconVideo} className="video-btn" alt="" />
          </div>
          
            <p className="flex7 font14px">Symptom Benchmark</p>
          </div>
        </button>
      );
    }
    if (weekNo === '4' ) {
      return (
        <button
          type="button"
           onClick={() => gotoScreen()}
        className="weekly-menu-btn"
        >
          <div className="flex flex-row flex-item-center flex-space-between gap0">
          <div className="bg-femmar-purple flex1 video-btn-container margin-half">
            <img src={iconVideo} className="video-btn" alt="" style={{"width":"2rem ","height":"3rem","padding":"0.3rem"}}/>
          </div>
          
          <p className="flex7 font14px">Symptom Tracker Questionnaire</p>
          </div>
        </button>
      );
    }
    if ( weekNo === '6') {
      return (
        <button
          type="button"
           onClick={() => gotoScreen()}
        className="weekly-menu-btn"
        >
          <div className="flex flex-row flex-item-center flex-space-between gap0">
          <div className="bg-femmar-purple flex1 video-btn-container margin-half">
            <img src={iconVideo} className="video-btn" alt="" style={{"width":"2rem ","height":"3rem","padding":"0.3rem"}}/>
          </div>
          
          <p className="flex7 font14px"> Symptom Tracker Questionnaire</p>
          </div>
        </button>
      );
    }
    return null;
  };
  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1 margin-bottom1">
      <TopComp />
      <PageTitleComp weekNum={programDetail?.week_number} pageTitle={`Week ${programDetail?.week_number}: ${programDetail?.week_name || ''}`} />
      <BigPinkBtns />
      {isWeekZeroCompleted && (
        <div className="flex flex-col flex-item-center flex-space-between gap-half">
          <p className="h2 margin-top-half">{userNameActionPlan}</p>
          

          <button
            type="button"
            onClick={handleBtnActionPlan}
            className="myPlan-expanding-btn width9030"
            style={{ backgroundColor: '#ffcbe1' }}>
            <div className="flex flex-row flex-item-center flex-space-around margin-left1">
              <p className="flex7 font16px color-black">My Action Plan</p>
              <i className="bi bi-arrow-right-circle flex1 arrow-more-less fw-bold" />
            </div>
          </button>
          <MyPlanComp />
        </div>
      )}

      <img className="theBigImage" src={programDetail?.image_url} alt="" />

      <div className="flex flex-col flex-item-center flex-space-around gap1">
     
        {
          programLessons.map((item, index) => (
            <LessonPlanModuleComp
              id={item.id}
              isQuiz={item.is_quiz}
              weekNum={programDetail?.week_number || 0}
              screen={`SixWk${item.week_id}L${item.id}Expectations`}
              // menuItem={`${programDetail?.week_number}.${index + 1} ${item.listing_label}`}
              menuItem={` ${item.listing_label}`}
            />
          ))
        }


        {/* <LessonPlanModuleComp screen="SixWk0L02Behavior" menuItem={menuItem2} />

        <LessonPlanModuleComp
          screen="SixWk0L03Preparation"
          menuItem={menuItem3}
        />

        <LessonPlanModuleComp screen="SixWk0L04Fruit" menuItem={menuItem4} />

        <LessonPlanModuleComp screen="SixWk0L05Fatty" menuItem={menuItem5} />

        <LessonPlanModuleComp screen="SixWk0L06Wheat" menuItem={menuItem6} />

        <LessonPlanModuleComp screen="SixWk0L07Caffeine" menuItem={menuItem7} />

        <LessonPlanModuleComp screen="SixWk0L08Curb" menuItem={menuItem8} />
        <LessonPlanModuleComp screen="SixWk0L09Hydrated" menuItem={menuItem9} />

        <LessonPlanModuleComp screen="SixWk0L10Alcohol" menuItem={menuItem10} />

        <LessonPlanModuleComp screen="SixWk0L11Moving" menuItem={menuItem11} />

        <LessonPlanModuleComp screen="SixWk0L12Rest" menuItem={menuItem12} />

        <LessonPlanModuleComp screen="SixWk0L13Phyto" menuItem={menuItem13} />

        <LessonPlanModuleComp
          screen="SixWk0L14Flaxseeds"
          menuItem={menuItem14}
        />

        <LessonPlanModuleComp screen="SixWk0L15Meals" menuItem={menuItem15} />

        <LessonPlanModuleComp screen="SixWk0L16Portion" menuItem={menuItem16} /> */}
      </div>
    </div>
  );
};

export default SixWk0;
