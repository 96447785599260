import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/femmar.css';

import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import BigPinkBtns from '../components/BigPinkBtns';
import MyPlanComp from '../components/MyPlanComp';
import LessonPlanModuleComp from '../components/LessonPlanModuleComp';

import FooterBtns from '../components/FooterBtns';

import myContent from '../jsonContent/trans_SixWk5.json';
import SixWk5Image from '../images/pictures/weekly/week5.jpg';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  changeCurrentStep,
  completeWeekStep,
  selectCurrentStep,
} from '../redux/slices/visitedscreens';

const SixWk5 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(selectCurrentStep);

  const gotoScreen = (screen: string) => {
    dispatch(completeWeekStep({ step: currentStep }));
    dispatch(changeCurrentStep(screen));
    navigate(`/${screen}`);
  };
  const langNum = useAppSelector(selectLanguageIndex);
  const {
    pageTitle,
    menuItem1,
    menuItem2,
    menuItem3,
    menuItem4,
    menuItem5,
    menuItem6,
    menuItem7,
    menuItem8,
  } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center gap1">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <BigPinkBtns />
      <MyPlanComp />

      <img className="theBigImage" src={SixWk5Image} alt="" />

      <div className="flex flex-col flex-item-center flex-space-around gap1">
        <LessonPlanModuleComp
          screen="SixWk5L1ProgressQuestionnaire"
          menuItem={menuItem1}
        />

        <LessonPlanModuleComp screen="SixWk5L2SexDry" menuItem={menuItem2} />

        <LessonPlanModuleComp screen="SixWk5L3SexLack" menuItem={menuItem3} />

        <LessonPlanModuleComp screen="SixWk5L4Stress" menuItem={menuItem4} />

        <LessonPlanModuleComp screen="SixWk5L5Anxiety" menuItem={menuItem5} />

        <LessonPlanModuleComp screen="SixWk5L6Sleep" menuItem={menuItem6} />

        <LessonPlanModuleComp screen="SixWk5L7Research" menuItem={menuItem7} />

        <LessonPlanModuleComp screen="SixWk5L8Quiz" menuItem={menuItem8} />
      </div>

      <FooterBtns />
    </div>
  );
};

export default SixWk5;
