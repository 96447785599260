import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import FieldInput from '../../molecules/FieldInput/FieldInput';
import {
  composeValidators,
  emailValidator,
  requiredValidator,
} from '../../../utils/validations.utils';
import { ForgotPasswordValues } from '../../../types/models/ForgotPassword';

interface ForgotPasswordFormProps
  extends FormRenderProps<ForgotPasswordValues> {
  loading: boolean;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  form,
  loading,
}) => {
  return (
    <div className="flex flex-col flex-item-left flex-space-around gap1 margin-left5pcnt-portrait margin-left25pcnt-landscape">
      <p className="registration-label">Email</p>
      <Field
        component={FieldInput}
        name="email"
        placeholder="Input"
        validate={composeValidators(requiredValidator, emailValidator)}
      />
      <button
        disabled={loading}
        type="button"
        className="purple-btn"
        onClick={form.submit}>
        {loading ? 'Requesting...' : 'Reset'}
      </button>
    </div>
  );
};

export default ForgotPasswordForm;
