import React, { useEffect, useMemo, useState } from 'react';
import '../css/femmar.css';
/* import parse from 'html-react-parser'; */
import isEmpty from 'lodash/isEmpty';
import FooterBtns from './FooterBtns';

import CustomForm from './Organisms/CustomForm/CustomForm';
import FormTemplate from './template/FormTemplate';
import { QuestionTypes } from '../types/models/QuestionTypes';
import {
  formatValuesToForm,
  formatValuesToSave,
  verifyIfAnswersAreCorrect,
} from '../utils/result.utils';
/* import myContent from '../jsonContent/trans_SixWkSymptomsTracker.json';
import { selectLanguageIndex } from '../redux/slices/auth'; */
import { useAppDispatch, useAppSelector } from '../redux/store';
import { saveAnswersAction } from '../redux/actions/questionAction';
import { selectWeekSymptomAnswers } from '../redux/slices/visitedscreens';
import { getQuizByTypeAction } from '../redux/actions/quiz';
import { QuizTypes } from '../types/models/QuizTypes';
import { getQuestionFieldName } from '../utils/question.utils';
import { getQuizAnswer } from '../api/quiz';
import { CorrectAnswer } from '../types/models/ApiResult';
import { showSuccsesMessage } from '../utils/banner.utils';

interface QuestionCompProps {
  weekNumber: number;
  className: string;
  type: QuizTypes;
}

const QuestionComp: React.FC<QuestionCompProps> = ({
  weekNumber,
  className,
  type,
}) => {
  /*   const langNum = useAppSelector(selectLanguageIndex); */
  const dispatch = useAppDispatch();
  const answers = useAppSelector(selectWeekSymptomAnswers);
  const quiz = useAppSelector((state) => state.quiz);
  const [correctValues, setCorrectValues] = useState<CorrectAnswer[]>([]);
  const { loading, currentQuiz } = quiz;

  // const filteredQuestions = useMemo(() => {
  //   if (!currentQuiz) {
  //     return [];
  //   }

  //   let constantName: string | null = null;
  //   if (weekNumber === 0) {
  //     constantName = "First week";
  //   } else if (weekNumber === 3 || weekNumber === 5) {
  //     constantName = "Second week";
  //   }
  //   const filtered = currentQuiz.questions.filter((question) => 
  //     question.constantName === constantName || question.constantName === null
  //   );
  //   // return filtered.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
  //   const sortedQuestions = filtered.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    
  //   // Sort the options for each question
  //   return sortedQuestions.map(question => ({
  //     ...question,
  //     options: question.options.sort((a, b) => a.order - b.order)
  //   }));
  // }, [currentQuiz, weekNumber]);

  const filteredQuestions = useMemo(() => {
    if (!currentQuiz || !currentQuiz.questions) {
      return [];
    }

    let constantName: string | null = null;
    if (weekNumber === 0) {
      constantName = "First week";
    } else if (weekNumber === 3 || weekNumber === 5 || weekNumber ===6) {
      constantName = "Second week";
    }

    const filtered = currentQuiz.questions
      .filter((question) => 
        question.constantName === constantName || question.constantName === null
      )
      .map((question) => ({
        ...question,
        order: question.order ?? 0,
        options: question.options 
          ? question.options.map((option) => ({
              ...option,
              order: option.order ?? 0,
            }))
          : [],
      }));

    // Sort the questions by their order
    return filtered
      .sort((a, b) => a.order - b.order)
      .map((question) => ({
        ...question,
        options: question.options.length > 0
          ? [...question.options].sort((a, b) => a.order - b.order)
          : [],
      }));
  }, [currentQuiz, weekNumber]);


  // const initialValues = useMemo(() => {
  //   if (!currentQuiz) {
  //     return undefined;
  //   }
  //   let values = {};
  //   // This is because progress questions are same for all the weeks
  //   // so filter week specific answers
  //   const finalAnswers =
  //     type === QuizTypes.PROGRESS
  //       ? answers.filter((answer) => answer.weekNumber === weekNumber)
  //       : answers;
  //   if (finalAnswers.length) {
  //     values = formatValuesToForm(finalAnswers, currentQuiz.questions);
  //   }
  //   if (!isEmpty(values)) {
  //     return values;
  //   }
  //   return currentQuiz.questions.reduce<{ [key: string]: any }>(
  //     (partial, question) => {
  //       const fieldName = getQuestionFieldName(question.id);
  //       if (question.type === QuestionTypes.MULTIPLE_CHOICE) {
  //         partial[fieldName] = [];
  //       } else if (question.type === QuestionTypes.NESTED_MULTIPLE_CHOICE) {
  //         partial[fieldName] = [];
  //       }
  //       return partial;
  //     },
  //     {},
  //   );
  // }, [currentQuiz, weekNumber]);
  
  const initialValues = useMemo(() => {
    if (!currentQuiz) {
      return undefined;
    }
    let values = {};
    const finalAnswers =
      type === QuizTypes.PROGRESS
        ? answers.filter((answer) => answer.weekNumber === weekNumber)
        : answers;
    if (finalAnswers.length) {
      values = formatValuesToForm(finalAnswers, filteredQuestions);
    }
    if (!isEmpty(values)) {
      return values;
    }
    return filteredQuestions.reduce<{ [key: string]: any }>(
      (partial, question) => {
        const fieldName = getQuestionFieldName(question.id);
        if (question.type === QuestionTypes.MULTIPLE_CHOICE) {
          partial[fieldName] = [];
        } else if (question.type === QuestionTypes.NESTED_MULTIPLE_CHOICE) {
          partial[fieldName] = [];
        }
        return partial;
      },
      {},
    );
  }, [currentQuiz, weekNumber, filteredQuestions]);

  /*   const translations = myContent.trans[langNum];
  const pageText = parse(translations.pageText); */

  const saveWeekAnswer = async (values: any) => {
    if (type === QuizTypes.Quiz) {
      if (!quiz.currentQuiz) {
        return;
      }
      const result = await getQuizAnswer({
        quizId: quiz.currentQuiz.id,
      });
      console.log(result);

      if (
        !verifyIfAnswersAreCorrect(
          formatValuesToSave(values, weekNumber),
          result.data,
        )
      ) {
        setCorrectValues(result.data);
        throw new Error(
          'Please retry - we have highlighted the correct answers',
        );
      }
      showSuccsesMessage('Well Done');
      return;
    }
    console.log(formatValuesToSave(values, weekNumber));
    await dispatch(
      saveAnswersAction({ result: formatValuesToSave(values, weekNumber) }),
    );
  };

  useEffect(() => {
    dispatch(
      getQuizByTypeAction({
        type,
        weekNumber: type === QuizTypes.Quiz ? weekNumber : undefined,
      }),
    );
    // console.log('nikhil',weekNumber)
  }, [type, weekNumber]);

  if (loading || !currentQuiz) {
    return <div>Loading...</div>;
  }
  return (
    <div className={className}>
      <div className="flex flex-col flex-item-center flex-space-around gap1">
        {/*  {type === `Progress` && <div className="page-text">{pageText}</div>}; */}
        


        <FormTemplate
          onSubmit={saveWeekAnswer}
          Component={CustomForm}
          // questions={currentQuiz.questions}
          questions={filteredQuestions}
          initialValues={initialValues}
          correctValues={correctValues}
        />
        <FooterBtns />
      </div>
    </div>
  );
};

export default QuestionComp;