import { AxiosPromise } from 'axios';
import { client } from './client';
import { IOrder } from '../types/models/Order';
import { PaymentProduct } from '../types/models/PaymentProduct';

export const createPaymentIntent = (): AxiosPromise<{
  clientSecret: string;
  product: PaymentProduct;
}> => client.post('/payment/create-payment-intent');

export const validateOrder = (params: {
  clientSecret: string;
  paymentIntentId: string;
}): AxiosPromise<any> => client.get('/payment/status', { params });

export const getMyOrders = (): AxiosPromise<IOrder[]> =>
  client.get('/payment/orders');

export const applyCoupon = (coupon: string): AxiosPromise<any> =>
  client.post('/payment/coupon/apply', { coupon });

export const getProgramInfo = (): AxiosPromise<PaymentProduct> =>
  client.get('/payment/program');

export const createCheckoutSession = (
  email: string,
  type: number,
): AxiosPromise<{ url: string }> =>
  client.post('/payment/create-checkout-session', { email ,type});
  // export const createCheckoutSession = (   email: string,   types: number, ): 
  // AxiosPromise<{ url: string }> => {   
  //   // Log the request parameters  
  //   console.log('Request Parameters:', { email, types });   
  //    return client.post('/payment/create-checkout-session', { email, types }); };
  

export const verifyCheckoutSession = (
  sessionId: string,
): AxiosPromise<{ paymentStatus: 'paid' | 'unpaid' }> =>
  client.post(`/payment/verify-session/${sessionId}`);
