import React, { useEffect, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
// import { Editor } from '@tinymce/tinymce-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CKEditor } from '@ckeditor/ckeditor5-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router';
import recommendedText from '../jsonContent/recommendedContent';
import { client } from '../api/client';
import {
  getAppointmentAction,
  setAppointmentUserAction,
} from '../redux/actions/appointmentpool';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  selectAppointment,
  selectAppointmentUser,
} from '../redux/slices/appointmentpool';
// import { tuple } from 'yup';

type Editor = any;

const Nutritionprogrecom = () => {
  const appointmentData = useAppSelector(selectAppointment);

  const appointmentUser = useAppSelector(selectAppointmentUser);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [danger, setDanger] = useState<string | null>(null);
  const [text, setText] = useState(recommendedText);

  const dispatch = useAppDispatch();

  const editorRef = useRef<Editor | null>(null);
  const autoSaveTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const appointmentId = localStorage.getItem('appointmentId');

    if (appointmentId) {
      dispatch(getAppointmentAction({ id: parseInt(appointmentId, 10) }));
    }
  }, []);

  useEffect(() => {
    if (appointmentData) {
      if (appointmentData.recommendation_doc) {
        setText(appointmentData.recommendation_doc);
      }
    }
  }, [appointmentData]);

  const notify = () => toast('Program Saved Successfully!');
  const error = () => toast('Something went wrong!');
  const complete = () => toast('Consultation Completed Successfully!');

  const submit = (manualSave= false) => {
   
    if (editorRef.current) {
      const content = editorRef.current.getData();
      const appointmentId = localStorage.getItem('appointmentId');


      if (!content.trim()) {
        setDanger('Please add your recommendtions before saving.');
        return;
      }
      if (appointmentId && (!isAutoSaving|| manualSave)) {
        
        setDanger(null);
        if (autoSaveTimeout.current) {
          clearTimeout(autoSaveTimeout.current); // Clear auto-save timeout
        }
        
        client
          .post('/nutritionist/update-appointment', {
            id: parseInt(appointmentId, 10),
            recommendation_doc: content,
          })
          .then((response: any) => {
            notify();
          })
          .finally(() => {
            setIsAutoSaving(false);
          });
      }
    }
  };

  const finish = () => {
    setLoading(true);
    const appointmentId = localStorage.getItem('appointmentId');
    if (appointmentId) {
      client
        .post('/nutritionist/finish-appointment', {
          id: parseInt(appointmentId, 10),
        })
        .then((response: any) => {
          localStorage.removeItem('appointmentUser');
          localStorage.removeItem('appointmentId');
          dispatch(setAppointmentUserAction(null));
          complete();
          setLoading(false);
          navigate('/NutrionalDashInfo');
        })
        .catch((err: any) => {
          setLoading(false);
          error();
        });
    }
  };
  
  const handleEditorChange = (event: any, editor: any) => {
    if (editor) {
      const content = editor.getData();
      setText(content);
      setIsAutoSaving(true);
  
      if (autoSaveTimeout.current) {
        clearTimeout(autoSaveTimeout.current);
      }
  
      autoSaveTimeout.current = setTimeout(() => {
        setIsAutoSaving(false);
        submit();
      }, 10000);
    }
  
  
    
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="flex flex-col flex-item-center flex-space-between gap1">
          <p className="nutrional-dash-heading">
            Personalized program and recommendations for{' '}
            {appointmentUser?.user.firstName}
          </p>

          <Alert variant="warning">
            <p className="text-dark">
            This is the current Personalized program, You can edit it as per your consultation with the {appointmentUser?.user.firstName}.
            </p>
          </Alert>
          <div className="personalizedprogram-editor">
          <CKEditor
              editor={ClassicEditor as any}
              data={text}
              onChange={(event, editor) => {
                
                console.log('Editor instance:', editor);
                
                editorRef.current = editor;
                handleEditorChange(event, editor);
              }}
             
              
            />
          </div>
          


          {/* <Editor
            onInit={(evt, editor) => {
              (editorRef.current as any) = editor;
            }}
            initialValue={text}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
            onEditorChange={handleEditorChange}
          /> */}
          {isAutoSaving && <p className="text-dark">Auto-saving...</p>}
          {danger && <p className="text-danger mb-1 nutrional-form-error">{danger}</p>}
          <button
            type="submit"
            className="nutrional-dash-btn text-dark"
            onClick={()=>{
              submit(true);
              }}>
            Save
          </button>
          <button
            type="submit"
            disabled={loading}
            className="nutrional-dash-btn text-dark"
            onClick={finish}>
            {!loading ? 'Finish' : 'Please wait..'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Nutritionprogrecom;
