import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, FormRenderProps } from 'react-final-form';
import { UserRoles } from '../../../types/models/User';
import FieldInput from '../../molecules/FieldInput/FieldInput';
import FormField from '../../admin/molecules/FormField/FormField';
import FieldSelect from '../../molecules/FieldSelect/FieldSelect';
import {
  composeValidators,
  emailValidator,
  requiredValidator,
} from '../../../utils/validations.utils';
import './UserAddEditForm.scss';

interface IUserAddEditFormProps
  extends FormRenderProps<{ name: string; userRole: UserRoles }> {
  visible: boolean;
  handleClose: () => void;
  loading: boolean;
  isEdit: boolean;
}

const UserAddEditForm: React.FC<IUserAddEditFormProps> = ({
  form,
  visible,
  handleClose,
  loading,
  isEdit,
}) => {
  useEffect(() => {
    if (!visible) {
      form.reset();
    }
  }, []);

  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Edit' : 'Add'} User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="user-form">
          <FormField title="Name">
            <Field
              component={FieldInput}
              name="firstName"
              className="form-control width-100"
              placeholder="First Name"
              validate={requiredValidator}
            />
          </FormField>
          <FormField title="Name">
            <Field
              component={FieldInput}
              name="lastName"
              className="form-control width-100"
              placeholder="Last Name"
              validate={requiredValidator}
            />
          </FormField>
          <FormField title="Email">
            <Field
              component={FieldInput}
              name="email"
              className="form-control width-100"
              placeholder="email address"
              validate={composeValidators(requiredValidator, emailValidator)}
              disabled={isEdit}
            />
          </FormField>
          <FormField title="User Role">
            <Field
              component={FieldSelect}
              name="userRole"
              className="form-select"
              options={Object.keys(UserRoles).map((roleKey) => ({
                title: roleKey,
                value: UserRoles[roleKey as keyof typeof UserRoles],
              }))}
              placeholder="select role"
              validate={requiredValidator}
            />
          </FormField>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} variant="danger" onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={loading} variant="primary" onClick={form.submit}>
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserAddEditForm;
