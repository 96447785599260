import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { getAllProgramLessonsAction, getLessonDetailAction, getLessonsChildrenAction, getProgramDetailction, getProgramLessonsAction, getProgramsAction } from '../actions/sixweekprograms';
import { Program } from '../../types/models/Program';
import { ProgramDetail } from '../../types/models/ProgramDetail';

export interface SixWeekProgramState {
    programs: Program[];
    programDetail: Program | null,
    programLessons: ProgramDetail[]
    allProgramLessons: ProgramDetail[]
    lessonChildren: ProgramDetail[]
    lessonDetail: ProgramDetail | null
}

const initialState: SixWeekProgramState = {
    programs: [],
    programDetail: null,
    programLessons: [],
    allProgramLessons: [],
    lessonChildren: [],
    lessonDetail: null
};

export const ProgramScreensSlice = createSlice({
    name: 'sixweekprograms',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getProgramsAction.fulfilled, (state, action) => {
            state.programs = action.payload;
        });
        builder.addCase(getProgramLessonsAction.fulfilled, (state, action) => {
            state.programLessons = action.payload;
        });

        builder.addCase(getProgramDetailction.fulfilled, (state, action) => {
            state.programDetail = action.payload;
        });

        builder.addCase(getLessonDetailAction.fulfilled, (state, action) => {
            state.lessonDetail = action.payload;
        });

        builder.addCase(getAllProgramLessonsAction.fulfilled, (state, action) => {
            state.allProgramLessons = action.payload;
        });

        builder.addCase(getLessonsChildrenAction.fulfilled, (state, action) => {
            state.lessonChildren = action.payload;
        });

    },
});

// export const { completeWeekStep, changeCurrentStep } =
//     VisitedScreensSlice.actions;

export const selectPrograms = (state: RootState) =>
    state.sixweekprograms.programs || [];

export const selectProgramLessons = (state: RootState) =>
    state.sixweekprograms.programLessons || [];

export const selectProgramDetail = (state: RootState) =>
    state.sixweekprograms.programDetail || null;

export const selectLessonDetail = (state: RootState) =>
    state.sixweekprograms.lessonDetail || null;

export const selectLessonChildren = (state: RootState) =>
    state.sixweekprograms.lessonChildren || [];

export const selectAllLessons = (state: RootState) =>
    state.sixweekprograms.allProgramLessons || [];

export default ProgramScreensSlice.reducer;
