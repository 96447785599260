import React from 'react';
import { Field } from 'react-final-form';
import { noop } from 'lodash';
import FieldSingleChoice from '../FieldSingleChoice/FieldSingleChoice';
import { IQuestion } from '../../../types/models/Quiz';
import { getQuestionFieldName } from '../../../utils/question.utils';
import { requiredValidator } from '../../../utils/validations.utils';

interface ISingleChoiceQuestionProps {
  question: IQuestion;
  correctValue?: number[];
}

const SingleChoiceQuestion: React.FC<ISingleChoiceQuestionProps> = ({
  question,
  correctValue,
}) => {
  return (
    <div className="padding1 bg-white border-round">
      <p className="h3">{question.text}</p>
      <Field
        component={FieldSingleChoice}
        name={getQuestionFieldName(question.id)}
        options={question.options}
        validate={question.optional ? noop : requiredValidator}
        correctValue={correctValue}
      />
    </div>
  );
};

export default SingleChoiceQuestion;
