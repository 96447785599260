import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import VideoComp from '../components/VideoComp';


import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';

import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk0L14Flaxseeds.json';
import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';
import image from '../images/lessons/W0L14Flaxseeds.jpg';

const SixWk0L14Flaxseeds = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle } = myContent.trans[langNum];
  const pageText = parse(myContent.trans[langNum].pageText);

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
     <LessonPageHeaderComp pageTitle={pageTitle} />
      <img className="theBigImage" src={image} alt="" />
      <div>
        <VideoComp url="https://vimeo.com/850720724/70404da43c?share=copy" />
      </div>
      <p className="page-text">{pageText}</p>
      <TickComp />
      <FooterBtns />

    </div>
  );
};

export default SixWk0L14Flaxseeds;
