import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUser } from '../../../types/models/User';
import { getAllUsers } from '../../../api/user';
import { IPaginationResponse } from '../../../types/common';
import { withToastForError } from '../../../utils/redux.utils';

export const getAllUsersAction = createAsyncThunk<
  { users: IPaginationResponse<IUser> },
  { page: number }
>(
  'admin/users/list',
  withToastForError(async (params) => {
    const response = await getAllUsers(params);
    return response.data;
  }),
);
