import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';

import FooterCopyright from '../components/FooterCopyright';
import myContent from '../jsonContent/trans_Privacy.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';

const Privacy = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle } = myContent.trans[langNum];
  const pageText = parse(myContent.trans[langNum].pageText);
  return (
    <div className="flex flex-col flex-item-center gap1 fill-page">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />

      <div className="page-text">{pageText}</div>

      <FooterCopyright />
    </div>
  );
};

export default Privacy;
