import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import last from 'lodash/last';
import { useNavigate } from 'react-router-dom';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import myContent from '../jsonContent/trans_CourseProgress.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import { arrayScreens } from '../constants/global';

const CourseProgress = () => {
  const navigate = useNavigate();

  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText, labLastLesson, btnNextLesson } =
    myContent.trans[langNum];

  const completedSteps = useAppSelector(
    ({ visitedscreens }) => visitedscreens.completedSteps,
  );
  const lastStep = last(completedSteps || []);
  console.log(completedSteps);
  let nextStep = 'None';
  const index = arrayScreens.indexOf(lastStep as string);
  if (index > -1 && index < arrayScreens.length - 1) {
    nextStep = arrayScreens[index + 1];
  }
  const lastStepPrettified = lastStep
    ? lastStep
        .replace('SixWk', 'Week #')
        .replace('L', 'Lesson #')
        .replace(/([A-Z])/g, ' $1')
        .trim()
    : 'none';

  const handleBtnNextLesson = () => {
    navigate(`/${nextStep}`);
  };

  return (
    <div>
      <div className="flex flex-col flex-item-center flex-space-between gap-half page-full">
        <TopComp />
        <PageTitleComp pageTitle={pageTitle} />
        <br />

        <div className="page-text">{parse(pageText)}</div>

        <div className="flex flex-row margin1">
          <p className="label">{labLastLesson}</p>
          <p className="border-thin border-round bg-whitesmoke padding1">
            {lastStepPrettified}
          </p>
        </div>

        <button
          type="button"
          className="footer-btn"
          onClick={handleBtnNextLesson}>
          {btnNextLesson}
        </button>
      </div>{' '}
    </div>
  );
};

export default CourseProgress;
