import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { HeightUnits, HeightValue } from '../../../types/types';
import FieldError from '../FieldError/FieldError';
import ToggleButtons from '../ToggleButtons/ToggleButtons';

const FieldHeight: React.FC<FieldRenderProps<HeightValue>> = ({
  input,
  meta,
  compact,
  ...rest
}) => {
  const onChange = (value: string, key: string) => {
    if (Number.isNaN(value)) {
      return;
    }
    input.onChange({ ...input.value, [key]: value });
  };
  return (
    <div className="flex-item-center flex flex-col">
      <div className="flex flex-row">
        <div className="marginR1">
          <div className="flex flex-row">
            {input.value.unit === HeightUnits.FT ? (
              <>
                <input
                  type="number"
                  value={input.value?.feet || ''}
                  placeholder="Feet"
                  onChange={(event) => onChange(event.target.value, 'feet')}
                  {...rest}
                  className="border-thin border-round padding02 width5R text-center"
                />
                &nbsp;
                <input
                  type="number"
                  value={input.value?.inches || ''}
                  placeholder="Inches"
                  onChange={(event) => onChange(event.target.value, 'inches')}
                  {...rest}
                  className="border-thin border-round padding02 width5R text-center"
                />
              </>
            ) : (
              <input
                type="number"
                value={input.value?.cm || ''}
                placeholder="Height in CM"
                onChange={(event) => onChange(event.target.value, 'cm')}
                {...rest}
                className="border-thin border-round padding02 width5R text-center"
              />
            )}
          </div>
        </div>
        <ToggleButtons
          buttons={[
            { title: 'CM', id: HeightUnits.CM },
            { title: 'FT', id: HeightUnits.FT },
          ]}
          onChange={(btn) => input.onChange({ ...input.value, unit: btn.id })}
          selected={input.value.unit}
        />
      </div>
      <FieldError
        compact={compact}
        visible={!!(meta.touched && meta.error)}
        errorId={meta.error}
      />
    </div>
  );
};

export default FieldHeight;
