import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import myContent from '../jsonContent/trans_nutrional_dashboard.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import FooterCopyright from '../components/FooterCopyright';
import PageTitleComp from '../components/PageTitleComp';
import Nutionisitinput from './NutrionalDashConsultation/Nutionisitinput';
import Nutrionistnotes from './NutrionalDashConsultation/Nutrionistnotes';
import Nutritionprogrecom from './Nutritionprogrecom';
import NutritionClientDetail from './NutritionClientDetail';
import NutrionalClientHistory from './NutritionalClientHistory';


const NutrionalDashConsultation = () => {

  const [key, setKey] = useState('Nutritionist input screen');
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle } = myContent.trans[langNum];
 
  

  return (
    <div className="flex flex-col flex-item-center flex-space-between mt-3">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => k && setKey(k)}
        className="nutrional-dash-tabs mb-3">
          <Tab
          eventKey="clientinfo"
          title="User Information">
          <NutritionClientDetail />
        </Tab>
        <Tab
          eventKey="clienthistory"
          title="User History">
          <NutrionalClientHistory/>
        </Tab>
        <Tab
          eventKey="Nutritionist input screen"
          title="Notes Before Consultation">
          <Nutionisitinput />
        </Tab>
        <Tab
          eventKey="profile"
          title="Notes During Consultation">
          <Nutrionistnotes />
        </Tab>
        <Tab
          eventKey="program"
          title="Personalized program">
          <Nutritionprogrecom />
        </Tab>
        
      </Tabs>
      <FooterCopyright />
    </div>
  );
};

export default NutrionalDashConsultation;
