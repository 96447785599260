// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect, useState } from 'react';
import '../css/femmar.css';
import { Link } from 'react-router-dom';
import TopComp from '../components/TopComp';
import { AppRoutes } from '../constants/routes';

const BookingCompleted = () => {

    return (
        <div className="flex flex-col flex-item-center flex-space-between gap1">
            <TopComp />

            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="150"
                fill="green"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>

            <h3>Appointment Scheduled</h3>
            <p>
                Your Appointment has been scheduled and Pre Consultation Information has been saved successfully.
            </p>

            <Link className="nutrional-dash-btn text-dark" to={AppRoutes.DashboardMain}>Go To Dashboard</Link>

        </div>
    );
};

export default BookingCompleted;
