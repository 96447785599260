// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect, useState } from 'react';
import '../css/femmar.css';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { useQuery } from '../hooks/useQuery';
import { verifyCheckoutSession } from '../api/payment';
import { getMyOrdersAction } from '../redux/actions/user';
import TopComp from '../components/TopComp';
import { checkIfUserPaid } from '../utils/auth.utils';
import { AppRoutes } from '../constants/routes';

const PaymentComplete = () => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector((state) => state.auth.orders);
  const query = useQuery();
  const sessionId = query.get('sessionId');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const verifyPayment = async () => {
    try {
      const response = await verifyCheckoutSession(sessionId as string);
      setMessage(response.data.paymentStatus);
      await dispatch(getMyOrdersAction());
    } catch (err: any) {
      setMessage(err.toString());
    }
    setLoading(false);
  };

  useEffect(() => {
    verifyPayment();
  }, []);

  if (loading) {
    return <div>Verifying your payment...</div>;
  }
  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="150"
        fill="green"
        className="bi bi-check-circle-fill"
        viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </svg>


      {
        (message === 'paid') && <p>
          Thank you for your payment, Click on the button below to book your consultation
        </p>
      }

      {
        (message !== 'paid') && <p>
          We could not verify your payment. Please try again in sometime.
        </p>
      }

      {
        (message === 'paid') && <>
          <p>Click the link below to begin.</p>
          {checkIfUserPaid(orders) && <Link className="nutrional-dash-btn text-dark" to={AppRoutes.AppointmentPool}>Book Now</Link>}
        </>
      }
    </div >
  );
};

export default PaymentComplete;
