import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import FooterCopyright from '../components/FooterCopyright';
import myContent from '../jsonContent/trans_MealPlans.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';

const MealPlans = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageSubtitle, pageText1, header1, pageText2, header2 } =
    myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap-half page-full">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />

      <br />

      <p className="h3">{pageSubtitle}</p>
      <p className="page-text">{parse(pageText1)}</p>
      <p className="h3">{header1}</p>

      <div className="border-thin padding1 margin1">
        <table
          className="table-grid table-small"
          cellSpacing="0"
          cellPadding="2px">
          <tr>
            <th>Breakfast – suitable for vegetarians and vegans</th>
            <th>Isoflavones</th>
            <th>
              Lignans
              <br />1 tbsp flaxseed
            </th>
          </tr>

          <tr>
            <td>Phyto muesli with 1 x cup soy milk (250 ml), flaxseeds</td>
            <td>30 mg</td>
            <td> 28 mg </td>
          </tr>
          <tr>
            <td>
              ½ cup soy yogurt (approx. 100g), with 1 tbsp flaxseeds and nuts
            </td>
            <td>17 mg</td>
            <td>28 mg</td>
          </tr>
          <tr>
            <td>Polenta porridge made with 1 x cup (250 ml) of soy milk</td>
            <td>20 mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
          <tr>
            <td>
              Buckwheat pancakes with 100g ½ cup soy yoghurt and fruit or honey
            </td>
            <td>20 mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
          <tr>
            <td>
              Chopped apple, almond, flaxseeds, honey and 100 g ½ cup soy
              yoghurt
            </td>
            <td>20 mg</td>
            <td>28 mg </td>
          </tr>
          <tr>
            <td>
              Power shake with 1 cup (250 ml) soy milk, ½ banana and handful
              berries
            </td>
            <td>20 mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
          <tr>
            <td>Soy and rice or polenta pancakes</td>
            <td>10 mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
        </table>
      </div>

      <div id="tableLunchOmnivore" className="border-thin padding1 margin1">
        <table
          className="table-grid table-small"
          cellSpacing="0"
          cellPadding="2px">
          <tr>
            <th>Lunch – omnivore</th>
            <th>Isoflavones</th>
            <th>
              Lignans
              <br />1 tbsp flaxseed
            </th>
          </tr>
          <tr>
            <td>
              2 x egg omelette with salad sprinkled with 100 g/3.5 oz raw
              edamame beans{' '}
            </td>
            <td>40mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
          <tr>
            <td>
              Avocado salad with arugula, beetroot, tomatoes, flaxseeds, with
              olive oil and balsamic dressing. Add salmon or prawns if desired
            </td>
            <td aria-label="space">&nbsp;</td>
            <td>28 mg</td>
          </tr>
          <tr>
            <td>
              Baked potato with tinned tuna, raw edamame beans and flaxseeds
            </td>
            <td aria-label="space">&nbsp;</td>
            <td>28 mg</td>
          </tr>
        </table>
      </div>

      <div id="tableLunchVegetarian" className="border-thin padding1 margin1">
        <table
          className="table-grid table-small"
          cellSpacing="0"
          cellPadding="2px">
          <tr>
            <th>Lunch – vegetarian</th>
            <th>Isoflavones</th>
            <th>
              Lignans
              <br />1 tbsp flaxseed
            </th>
          </tr>
          <tr>
            <td>Baked potato with canned spicy soy beans (100g/3.5 ounces)</td>
            <td>53mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
          <tr>
            <td>
              Mixed salad with raw edamame beans 100g/3.5 oz and feta cheese
            </td>
            <td>40 mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
        </table>
      </div>

      <div id="tableLunchVegan" className="border-thin padding1 margin1">
        <table
          className="table-grid table-small"
          cellSpacing="0"
          cellPadding="2px">
          <tr>
            <th>Lunch – vegetarian</th>
            <th>Isoflavones</th>
            <th>
              Lignans
              <br />1 tbsp flaxseed
            </th>
          </tr>
          <tr>
            <td>Grilled tempeh (100g/3.5 oz) with salad</td>
            <td>60mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
          <tr>
            <td>Sesame tofu (100g/3.5 oz) with salad</td>
            <td>25 mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
          <tr>
            <td>Mixed bean stir fry including 25 grams of canned soy beans</td>
            <td>13mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
          <tr>
            <td>
              Crudities with hummus and one slice gluten-free soy and linseed
              bread
            </td>
            <td>11 mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>

          <tr>
            <td>Baked sweet potato with raw edamame beans (50g/1.75 oz)</td>
            <td>20 mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
        </table>
      </div>

      <div id="tableDinnerOmnivore" className="border-thin padding1 margin1">
        <table
          className="table-grid table-small"
          cellSpacing="0"
          cellPadding="2px">
          <tr>
            <th>Dinner – omnivore</th>
            <th>Isoflavones</th>
            <th>
              Lignans
              <br />1 tbsp flaxseed
            </th>
          </tr>
          <tr>
            <td>
              Salmon and avocado salad with wholegrain rice and 100 g/3.5 oz
              cooked edamame beans
            </td>
            <td>18mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
          <tr>
            <td>Seabass with stir fry vegetables and rice with flaxseeds</td>
            <td aria-label="space">&nbsp;</td>
            <td>28 mg</td>
          </tr>
          <tr>
            <td>Lamb stir fry with rice and flaxseeds</td>
            <td aria-label="space">&nbsp;</td>
            <td>28 mg</td>
          </tr>
          <tr>
            <td>
              Salmon and avocado salad with whole grain rice and 50 g/1.75 oz
              cooked edamame beans
            </td>
            <td>9 mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>

          <tr>
            <td>Grilled chicken with nutty tofu rice (25 g tofu/0.88 oz) </td>
            <td>6.2 mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>

          <tr>
            <td>
              Nicoise salad with 25 g/o.88 oz silken tofu and soy milk ¼ cup
              dressing
            </td>
            <td>11.25 mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
        </table>
      </div>

      <div id="tableDinnerVegVegan" className="border-thin padding1 margin1">
        <table
          className="table-grid table-small"
          cellSpacing="0"
          cellPadding="2px">
          <tr>
            <th>Dinner – vegetarian and vegan</th>
            <th>Isoflavones</th>
            <th>
              Lignans
              <br />1 tbsp flaxseed
            </th>
          </tr>
          <tr>
            <td>
              Soba noodles with 75 g cooked edamame beans and 75 g grilled tofu
            </td>
            <td>39mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
          <tr>
            <td>
              Nutty Brussels sprout stir fry with brown rice and salad with 50
              g/1.75 oz raw edamame beans{' '}
            </td>
            <td>20mg</td>
            <td aria-label="space">&nbsp;</td>
          </tr>
          <tr>
            <td>
              Bean stir fry with mixed vegetables, brown rice and flaxseeds{' '}
            </td>
            <td aria-label="space">&nbsp;</td>
            <td>28 mg</td>
          </tr>
          <tr>
            <td>Polenta with grilled or roasted vegetables and flaxseeds</td>
            <td aria-label="space">&nbsp;</td>
            <td>28 mg</td>
          </tr>
        </table>
      </div>

      <p className="h3">{header2}</p>
      <p className="page-text">{parse(pageText2)}</p>

      <FooterCopyright />
    </div>
  );
};

export default MealPlans;
