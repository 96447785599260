import React from 'react';
import { IInputOption } from '../../../types/FormFields';

interface IRadioInputGroupProps {
  value: number;
  options: IInputOption[];
  onChange: (value: number | string) => void;
}

const RadioInputGroup: React.FC<IRadioInputGroupProps> = ({
  value,
  options,
  onChange,
}) => {
  return (
    <div className="flex flex-col flex-wrap">
      {options.map((option) => (
        <div
          key={`${option.id}-${option.text}`}
          className="margin-left1 padding02 text-left">
          <input
            type="radio"
            id={`${option.id}-${option.text}`}
            checked={value === option.id}
            onChange={() => onChange(option.id)}
          />

          <label
            htmlFor={`${option.id}-${option.text}`}
            className="padding-half">
            {option.text}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioInputGroup;
