import { toast } from 'react-toastify';
import { getErrorMessage } from './error.utils';

export const showSuccsesMessage = (message: string) => {
  toast.success(message, { position: 'bottom-right' });
};

export const showErrorMessage = (e: any) => {
  toast.error(getErrorMessage(e), { position: 'bottom-right' });
};
