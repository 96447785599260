import React, { useEffect, useMemo, useState } from 'react';
import { unionBy } from 'lodash';
import parse from 'html-react-parser';
import Select, { SingleValue } from 'react-select';
import moment from 'moment';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import FooterCopyright from '../components/FooterCopyright';
import myContent from '../jsonContent/trans_SymptomReport.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { fetchQuizByType } from '../api/quiz';
import { QuizTypes } from '../types/models/QuizTypes';
import { QuestionTypes } from '../types/models/QuestionTypes';
import { IQuestion, NestedQuestion } from '../types/models/Quiz';
import { selectWeekSymptomAnswers } from '../redux/slices/visitedscreens';
import { WeekSymptomAnswer } from '../types/models/QuestionModel';
import { SelectOption } from '../types/common';
import SymptomIcon from '../components/atoms/SymptomIcon/SymptomIcon';
import { getAnswersAction } from '../redux/actions/questionAction';

const SymptomReport = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const answers = useAppSelector(selectWeekSymptomAnswers);
  const dispatch = useAppDispatch();
  const [selectedWeek, setSelectedWeek] = useState<number>(0);
  const [nestedQuestion, setNestedQuestion] = useState<IQuestion>();

  const { pageTitle, pageText } = myContent.trans[langNum];

  useEffect(() => {
    dispatch(getAnswersAction({}))
  }, [])

  const weeks = useMemo(() => {
    // console.log("Answers in useMemo:", answers);
    const uniqAnswers = unionBy(answers, ({ weekNumber }) => weekNumber).filter(
      ({ weekNumber }) => weekNumber !== undefined && weekNumber !== null,
    );
    // console.log("Unique Answers after filter:", uniqAnswers);
    if (uniqAnswers.length > 0) {
      setSelectedWeek(uniqAnswers[0].weekNumber);
    }
    return uniqAnswers;
  }, [answers]);
  
  const weekAnswers = useMemo(() => {
    const filteredAnswers = answers.filter(({ weekNumber }) => weekNumber === selectedWeek);
    const specificWeekAnswer = filteredAnswers.find(({ questionId }) => questionId === nestedQuestion?.id);
    // console.log("Week Answers for selected week:", filteredAnswers);
    return specificWeekAnswer?.answers || [];
  }, [selectedWeek, answers, nestedQuestion]);

  const initialize = async () => {
    const response = await fetchQuizByType(QuizTypes.PROGRESS, undefined);
    const symptomQuestion = response.data.quiz.questions.find(
      (question) => question.type === QuestionTypes.NESTED_MULTIPLE_CHOICE,
    );

    if (symptomQuestion?.nestedQuestions?.length) {
      setNestedQuestion(symptomQuestion);
    }
  };

  const handleSelect = (option: SingleValue<SelectOption>) => {
    if (option) {
      setSelectedWeek(option.value);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const getReportDate = () => {
    const date = weeks.find(
      (week) => week.weekNumber === selectedWeek,
    )?.createdAt;
    if (date) {
      return moment(date).format('DD, MMM YYYY');
    }

    return '';
  };

  const getSelectedWeek = () => {
    const week = weeks.find(({ weekNumber }) => weekNumber === selectedWeek);
    if (week) {
      return { label: `Report ${weeks.length - weeks.indexOf(week)}`, value: week.weekNumber };
    }
    return undefined;
  };
  // const renderSymptomRow = (symptom: NestedQuestion) => {
  //   const optionId = weekAnswers.find(
  //     (answer) => answer.questionId === symptom.id,
  //   )?.selectedOptionId;
  //   const text = nestedQuestion?.options?.find(({ id }) => id === optionId)
  //     ?.text as string;
  //   const isMild = text === 'Mild';
  //   const isLot = text === 'Moderate';
  //   const isSevere = text === 'Severe';

  //   return (
  //     <tr key={symptom.text}>
  //       <td>{symptom.text}</td>
  //       <th aria-label="icon">
  //         <SymptomIcon active />
  //       </th>
  //       <th>
  //         {isMild || isLot || isSevere ? (
  //           <SymptomIcon active />
  //         ) : (
  //           <SymptomIcon />
  //         )}
  //       </th>
  //       <th>{isLot || isSevere ? <SymptomIcon active /> : <SymptomIcon />}</th>
  //       <th>{isSevere ? <SymptomIcon active /> : <SymptomIcon />}</th>
  //     </tr>
  //   );
  // };
  const renderSymptomRow = (symptom: NestedQuestion) => {
    const optionId = weekAnswers.find(
      (answer) => answer.questionId === symptom.id,
    )?.selectedOptionId;
  
    return (
      <tr key={symptom.text}>
        <td>{symptom.text}</td>
        {nestedQuestion?.options?.map((option) => {
          const isActive = option.id === optionId || (!optionId && option.text === 'None');
          return (
            <th key={option.id}>
              {isActive ? <SymptomIcon active /> : <SymptomIcon />}
            </th>
          );
        }) || []}
      </tr>
    );
  };
  
  const renderAdditionalQuestions = () => {
    const additionalQuestions = answers
      .filter((answer) => {
        if (selectedWeek === 0) {
          // For week number 0, show constantName 'First week'
          return (
            answer.weekNumber === selectedWeek &&
            answer.question?.constantName === 'First week'
          );
        }
        // For week numbers 3, 5, 6, show constantName 'Second week'
        if ([3, 5, 6].includes(selectedWeek)) {
          return (
            answer.weekNumber === selectedWeek &&
            answer.question?.constantName === 'Second week'
          );
        }
        // For other weeks, return empty array (no questions displayed)
        return false;
      })
      .sort((a, b) => (a.question?.order || 0) - (b.question?.order || 0)); // Sort based on order
  
    return additionalQuestions.map((answer) => (
      <div key={answer.questionId} className="width9060 text-center mt-2">
        <p className="text-left">
          <b>Question:</b> {answer.question?.text}
        </p>
        <p><b>Answer:</b></p>
        <ul>
        {answer.answers.map((ans) => (
          <li key={ans.id}>
            {ans.option?.text}
          </li>
        ))}
      </ul>
      </div>
    ));
  };
  
  

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />

      <div className="page-text">{parse(pageText)}</div>

      {!weeks.length ? (
        <div>
          Please submit at least week 1 questionnaire before you see any reports
        </div>
      ) : (
        <div>
          <Select<SelectOption>
            onChange={handleSelect}
            placeholder="Select"
            // options={weeks.map((week) => ({
            //   value: week.weekNumber,
            //   label: `Report ${weeks.indexOf(week) + 1}`,
            //   ...week,
            // }))}
            options={weeks
              .sort((a, b) => b.weekNumber - a.weekNumber) 
              .map((week) => ({
                value: week.weekNumber,
                label: `Report ${weeks.length - weeks.indexOf(week)}`,
                ...week,
              }))
            }
            value={getSelectedWeek()}
            className="width9030 border-thin border-round margin-half"
          />
          <p>
            <b>Symptom Report:</b> {getReportDate()}
          </p>

          <table className="symptom-report-table width9060 text-center">
            <thead>
              <tr>
                <th className="text-left">Symptom</th>
                <th>None</th>
                <th>Some</th>
                <th className="alot">A lot</th>
                <th>Severe</th>
              </tr>
            </thead>
            <tbody>
              {nestedQuestion?.nestedQuestions.map(renderSymptomRow)}
            </tbody>
          </table>
          {renderAdditionalQuestions()}
        </div>
      )}

      <FooterCopyright />
    </div>
  );
};

export default SymptomReport;
