import React from 'react';

interface IFormFieldProps {
  children: React.ReactNode;
  title: string;
}

const FormField: React.FC<IFormFieldProps> = ({ children, title }) => {
  return (
    <div className="row mb-3">
      <label className="col-sm-3 col-form-label">{title}</label>
      <div className="col-sm-9">{children}</div>
    </div>
  );
};

export default FormField;
