import React, { useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';
import myContent from '../jsonContent/trans_HistoryQuestionnaire.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';

import FormTemplate from '../components/template/FormTemplate';
import CustomForm from '../components/Organisms/CustomForm/CustomForm';
import { QuizTypes } from '../types/models/QuizTypes';
import { formatValuesToForm, formatValuesToSave } from '../utils/result.utils';
import { selectWeekSymptomAnswers } from '../redux/slices/visitedscreens';
import { getQuestionFieldName } from '../utils/question.utils';
import { QuestionTypes } from '../types/models/QuestionTypes';
import { getQuizByTypeAction } from '../redux/actions/quiz';
import PageTitleComp from '../components/PageTitleComp';
import { saveAnswersAction } from '../redux/actions/questionAction';
import {
  HeightUnits,
  HeightValue,
  WeightUnits,
  WeightValue,
} from '../types/types';
import WelcomeFooterBtns from '../components/WelcomeFooterBtn';

const HistoryQuestionnaire = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle } = myContent.trans[langNum];

  const dispatch = useAppDispatch();
  const answers = useAppSelector(selectWeekSymptomAnswers);
  const quiz = useAppSelector((state) => state.quiz);
  const { loading, currentQuiz } = quiz;

  const questions = useMemo(() => {
    if (!currentQuiz?.questions.length) {
      return [];
    }
    return currentQuiz.questions
    // .map((question, index) =>
    //   index < 8 ? { ...question, optional: true } : question,
    // );
  }, [currentQuiz?.questions]);

  const initialValues = useMemo(() => {
    if (!currentQuiz) {
      return undefined;
    }
    let values = {};
    if (answers.length) {
      values = formatValuesToForm(answers, currentQuiz.questions);
    }
    if (isEmpty(values)) {
      values = currentQuiz.questions.reduce<{ [key: string]: any }>(
        (partial, question) => {
          const fieldName = getQuestionFieldName(question.id);
          if (question.type === QuestionTypes.MULTIPLE_CHOICE) {
            partial[fieldName] = [];
          } else if (question.type === QuestionTypes.NESTED_MULTIPLE_CHOICE) {
            partial[fieldName] = [];
          } else if (question.type === QuestionTypes.HEIGHT) {
            partial[fieldName] = { unit: HeightUnits.CM } as HeightValue;
          } else if (question.type === QuestionTypes.WEIGHT) {
            partial[fieldName] = { unit: WeightUnits.KG } as WeightValue;
          }
          return partial;
        },
        {},
      );
    }
    return values;
  }, [currentQuiz]);

  // const saveWeekAnswer = async (values: any) => {
  //   // Add the type to the payload
  //   const payload = {
  //     result: formatValuesToSave(values),
  //     type: 'HistoryQuestionnaire'
  //   };
  //   await dispatch(saveAnswersAction(payload));
  // };
  const saveWeekAnswer = async (values: any, type: string) => {
    // Add the type to the payload
    const payload = {
      result: formatValuesToSave(values),
      type
    };
    await dispatch(saveAnswersAction(payload));
  };
  
  const handleSubmit = (values: any) => {
    saveWeekAnswer(values, 'HistoryQuestionnaire');
    localStorage.setItem('isFirstTimeUser',JSON.stringify(false))
  };
  useEffect(() => {
    dispatch(getQuizByTypeAction({ type: QuizTypes.HISTORY }));
  }, []);

  if (loading || !currentQuiz) {
    return <div>Loading...</div>;
  }

  // console.log(initialValues);

  return (
    <div className="padding-bottom5 minHeight100vh flex flex-col flex-item-center flex-space-between gap-half page-full ">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <div className="flex mx-auto flex-col flex-item-center flex-space-around ">
      <p className="padding1 flex-item-center text-left justify-content-center mx-auto item-center text-dark ">Welcome to Femmar’s Natural Menopause Solution!  We’re delighted you’re taking the right steps to support your menopause and future health. <br /> We’ll be supporting you in every step of your journey. For the program to be most effective, we just need a little more information about your current  <br />diet,  lifestyle and health.  This also helps you to better understand your symptoms.  Every question provides important information and it won’t take you too long <br /> to complete. Let’s get started! Enjoy your journey to better health! </p>
        <FormTemplate
          onSubmit={handleSubmit}
          Component={CustomForm}
          questions={questions}
          initialValues={initialValues}
        />
        <br />
        <p className="padding1 item-start text-dark">Many thanks for completing the questionnaire and welcome to better health!
        </p>
        <WelcomeFooterBtns />
        <FooterCopyright />
      </div>
    </div>
  );
};

export default HistoryQuestionnaire;
