import { AxiosPromise } from 'axios';
import {
  ChangePasswordParams,
  IUser,
  IUserAttributes,
  SaveUserParams,
} from '../types/models/User';
import { client } from './client';
import { IPaginationResponse } from '../types/common';

export const getCurrentUser = (): AxiosPromise<{ user: IUser ,PaymentStatus:boolean}> =>
  client.get('/users/me');

export const registerProgramStart = (
  params: Partial<IUserAttributes>,
): AxiosPromise<{ userAttributes: IUserAttributes }> =>
  client.post('/users/register-program-start', { values: params });

export const getAllUsers = (params: {
  page: number;
}): AxiosPromise<{
  users: IPaginationResponse<IUser>;
}> => client.get('/admin/users', { params: { ...params } });

export const saveUser = (params: SaveUserParams) =>
  client.post('/admin/users', params);

export const deleteUser = (userId: number) =>
  client.delete('/admin/users', { params: { userId } });

export const changePassword = (params: ChangePasswordParams) =>
  client.post('/users/change-password', params);

export const updateProfile = (params: {
  user: Partial<IUser>;
}): AxiosPromise<{ user: IUser }> => client.put('/users/me', params);
