export const convertCMToFeetInches = (
  value: number,
): { feet: number; inches: number } => {
  const realFeet = (value * 0.3937) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return { feet, inches };
};

export const convertFeetInchesToCM = (feet: number, inches: number): number => {
  const cmTotal = feet * 30.48 + inches * 2.54;

  return cmTotal;
};

export const convertPoundsToKilograms = (pounds: number): number => {
  const kilograms = pounds * 0.453592;
  return kilograms;
};

export const convertKilogramsToPounds = (kilograms: number): number => {
  const pounds = kilograms * 2.20462;
  return pounds;
};
