import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import FieldInput from '../../molecules/FieldInput/FieldInput';
import {
  composeValidators,
  mustMatchFieldValidator,
  requiredValidator,
} from '../../../utils/validations.utils';
import { ResetPasswordValues } from '../../../types/models/ResetPassword';

interface ResetPasswordFormProps extends FormRenderProps<ResetPasswordValues> {
  loading: boolean;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  form,
  loading,
}) => {
  return (
    <div className="flex flex-col flex-item-left flex-space-around gap1 margin-left5pcnt-portrait margin-left25pcnt-landscape">
      <p className="registration-label">Password</p>
      <Field
        component={FieldInput}
        name="password"
        placeholder="Input"
        validate={requiredValidator}
        inputType="password"
      />
      <p className="registration-label">Confirm Password</p>
      <Field
        component={FieldInput}
        name="confirmPassword"
        placeholder="Input"
        validate={composeValidators(
          requiredValidator,
          mustMatchFieldValidator('password'),
        )}
        inputType="password"
      />
      <button
        disabled={loading}
        type="button"
        className="purple-btn"
        onClick={form.submit}>
        {loading ? 'Requesting...' : 'Reset'}
      </button>
    </div>
  );
};

export default ResetPasswordForm;
