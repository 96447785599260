import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import FieldError from '../FieldError/FieldError';

export interface IFieldTextarea extends FieldRenderProps<string> {
  className?: string;
}

const FieldTextarea: React.FC<IFieldTextarea> = ({
  input,
  meta,
  compact,
  className,
}) => {
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    input.onChange(event.target.value || null);
  };
  return (
    <div>
      <textarea
        className={className}
        value={input.value}
        onChange={onChange}
        placeholder="Input"
      />
      <FieldError
        compact={compact}
        visible={!!(meta.touched && meta.error)}
        errorId={meta.error}
      />
    </div>
  );
};

export default FieldTextarea;
