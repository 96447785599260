export enum QuestionTypes {
  MULTIPLE_CHOICE = '0',
  SINGLE_CHOICE = '1',
  NESTED_MULTIPLE_CHOICE = '2',
  HEIGHT = '3',
  WEIGHT = '4',
}

export interface INestedValue {
  questionId: number;
  answer: number;
}

export interface Question{
  constantName: string;
  id: number;
  quizId: number;
  text: string;
  type: string;
  hasCommonSelection: any;
  parentId: any;
  createdAt: string;
  updatedAt: string;
}
export interface Option{
  id: number;
  questionId: number;
  text: string;
  isCorrect: number;
  createdAt: string;
  updatedAt: string;
}
