import { ADMIN_URL_PREFIX } from './global';

export enum AppRoutes {
  /*   Home = '/', */

  LoginB2C = '/',
  NutrionalFeedBackSucess = '/NutrionalFeedBackSucess',
  Nutriotionalfeedbackform = '/Nutriotionalfeedbackform/:id',
  Register0 = '/Register0',
  Register1 = '/Register1',
  Register2 = '/Register2',
  EmailVerify = '/auth/verify',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  Payment = '/payment',
  PaymentComplete = '/payment/complete',
  AppointmentPool = '/appointment/pool',
  AppointmentSuccess = '/appointment/success',
  Recommendations = '/recommendations',

  Welcome = '/Welcome',
  HistoryQuestionnaireIntro = '/HistoryQuestionnaireIntro',
  HistoryQuestionnaire = '/HistoryQuestionnaire',
  DashboardTour = '/DashboardTour',
  DashboardMain = '/DashboardMain',
  SupplementInfo = '/SupplementInfo',

  Actiondetail = '/ActionDetail',
  Actiondetaildesc='/ActionDetaildesc/:id',

  Faqs = '/Faqs',
  Faqdetail='/Faq/:id',
  Faqs01 = '/Faqs01',
  Faqs02 = '/Faqs02',
  Faqs03 = '/Faqs03',
  Faqs04 = '/Faqs04',
  Faqs05 = '/Faqs05',
  Faqs06 = '/Faqs06',
  Faqs07 = '/Faqs07',
  Faqs08 = '/Faqs08',
  Faqs09 = '/Faqs09',
  Faqs10 = '/Faqs10',
  Faqs11 = '/Faqs11',
  Faqs12 = '/Faqs12',
  Faqs13 = '/Faqs13',
  Faqs14 = '/Faqs14',
  Faqs15 = '/Faqs15',
  Faqs16 = '/Faqs16',
  Faqs17 = '/Faqs17',

  AccountSettings = '/AccountSettings',
  ChangePassword = '/ChangePassword',
  Consult = '/Consult',
  Contact = '/Contact',
  ContactConfirm = '/ContactConfirm',
  CourseCurriculum = '/CourseCurriculum',
  CourseProgress = '/CourseProgress',
  DayOneForm = `/DayOneForm/:appointmentId?/:orderId?`,
  DayTwoForm = `/DayTwoForm/:appointmentId?/:orderId?`,
  DayThreeForm = `/DayThreeForm/:appointmentId?/:orderId?`,
  MyAppointments='/MyAppointments',
  // NutrionalDashTable= '/NutrionalDashTable',
  NutrionalDashInfo = '/NutrionalDashInfo',
  NutrionalDashConsultation = '/NutrionalDashConsultation',
  Nutritionprogrecom = '/Nutritionprogrecom',
  MealPlans = '/MealPlans',
  NutritionalAssessment = '/NutritionalAssessment',
  PhytoTracker = '/PhytoTracker',
  Privacy = '/Privacy',
  Recipes = '/Recipes',
  Resources = '/Resources',
  Search = '/Search',
  Shop = '/Shop',
  SymptomReport = '/SymptomReport',
  Terms = '/Terms',

  SixWeekMenu = '/SixWeekMenu',
  SixWk0 = '/SixWk/:weekNo',
  SixWk0L01Expectations = '/SixWkLesson/:id/:weekNum',
  SixWk0L02Behavior = '/SixWk0L02Behavior',
  SixWk0L03Preparation = '/SixWk0L03Preparation',
  SixWk0L04Fruit = '/SixWk0L04Fruit',
  SixWk0L05Fatty = '/SixWk0L05Fatty',
  SixWk0L06Wheat = '/SixWk0L06Wheat',
  SixWk0L07Caffeine = '/SixWk0L07Caffeine',
  SixWk0L08Curb = '/SixWk0L08Curb',
  SixWk0L09Hydrated = '/SixWk0L09Hydrated',
  SixWk0L10Alcohol = '/SixWk0L10Alcohol',
  SixWk0L11Moving = '/SixWk0L11Moving',
  SixWk0L12Rest = '/SixWk0L12Rest',
  SixWk0L13Phyto = '/SixWk0L13Phyto',
  SixWk0L14Flaxseeds = '/SixWk0L14Flaxseeds',
  SixWk0L15Meals = '/SixWk0L15Meals',
  SixWk0L16Portion = '/SixWk0L16Portion',

  SixWk1 = '/SixWk1',
  SixWk1L1MeetMaryon = '/SixWk1L1MeetMaryon',
  SixWk1L2ProgressQuestionnaire = '/SixWk1L2ProgressQuestionnaire',
  SixWk1L3Supplements = '/SixWk1L3Supplements',
  SixWk1L4Body = '/SixWk1L4Body',

  SixWk1L5Symptoms = '/SixWk1L5Symptoms',
  SixWk1L5SympAching = '/SixWk1L5SympAching',
  SixWk1L5SympDry = '/SixWk1L5SympDry',
  SixWk1L5SympHeadaches = '/SixWk1L5SympHeadaches',
  SixWk1L5SympHot = '/SixWk1L5SympHot',
  SixWk1L5SympInsomnia = '/SixWk1L5SympInsomnia',
  SixWk1L5SympMood = '/SixWk1L5SympMood',
  SixWk1L5SympPainful = '/SixWk1L5SympPainful',
  SixWk1L5SympPanic = '/SixWk1L5SympPanic',

  SixWk1L6Research = '/SixWk1L6Research',
  SixWk1L7Quiz = '/Quiz/:id/:weekNum',

  SixWk2 = '/SixWk2',
  SixWk2L1GoodShape = '/SixWk2L1GoodShape',
  SixWk2L2Robbers = '/SixWk2L2Robbers',
  SixWk2L3RightFoods = '/SixWk2L3RightFoods',
  SixWk2L4Research = '/SixWk2L4Research',
  SixWk2L5Quiz = '/SixWk2L5Quiz',

  SixWk3 = '/SixWk3',
  SixWk3L1ProgressQuestionnaire = '/SixWk3L1ProgressQuestionnaire',
  SixWk3L2WhatPhyto = '/SixWk3L2WhatPhyto',
  SixWk3L3PhytoDiet = '/SixWk3L3PhytoDiet',
  SixWk3L4Sugar = '/SixWk3L4Sugar',
  SixWk3L5Bloat = '/SixWk3L5Bloat',
  SixWk3L6Constipation = '/SixWk3L6Constipation',
  SixWk3L7Research = '/SixWk3L7Research',
  SixWk3L8Quiz = '/SixWk3L8Quiz',

  SixWk4 = '/SixWk4',
  SixWk4L1MLRBox = '/SixWk4L1MLRBox',
  SixWk4L2Supplements = '/SixWk4L2Supplements',
  SixWk4L3Joylux = '/SixWk4L3Joylux',
  SixWk4L4Exercise = '/SixWk4L4Exercise',
  SixWk4L5Relaxation = '/SixWk4L5Relaxation',
  SixWk4L6Research = '/SixWk4L6Research',
  SixWk4L7Quiz = '/SixWk4L7Quiz',

  SixWk5 = '/SixWk5',
  SixWk5L1ProgressQuestionnaire = '/SixWk5L1ProgressQuestionnaire',
  SixWk5L2SexDry = '/SixWk5L2SexDry',
  SixWk5L3SexLack = '/SixWk5L3SexLack',
  SixWk5L4Stress = '/SixWk5L4Stress',
  SixWk5L5Anxiety = '/SixWk5L5Anxiety',
  SixWk5L6Sleep = '/SixWk5L6Sleep',
  SixWk5L7Research = '/SixWk5L7Research',
  SixWk5L8Quiz = '/SixWk5L8Quiz',

  SixWk6 = '/SixWk6',
  SixWk6L1Sharp = '/SixWk6L1Sharp',
  SixWk6L2Hair = '/SixWk6L2Hair',
  SixWk6L3Positivity = '/SixWk6L3Positivity',
  SixWk6L4BoneHealth = '/SixWk6L4BoneHealth',
  SixWk6L5Plan = '/SixWk6L5Plan',
  SixWk6L6Next = '/SixWk6L6Next',
  SixWk6L7Research = '/SixWk6L7Research',
  SixWk6L8Quiz = '/SixWk6L8Quiz',
  SixWk6L9ProgressQuestionnaire = '/SixWk6L9ProgressQuestionnaire',
  SixWk6L10Congrats = '/SixWk6L10Congrats',

  ManagerMenu = '/ManagerMenu',
}

export enum AdminRoutes {
  Dashboard = 'dashboard',
  Users = 'users',
}

// export const getDayOneFormRoute = (appointmentId?: string) => {
//   return appointmentId ? `${AppRoutes.DayOneForm}/${appointmentId}` : AppRoutes.DayOneForm;
// };
// export const getDayTwoFormRoute = (appointmentId?: string) => {
//   return appointmentId ? `${AppRoutes.DayTwoForm}/${appointmentId}` : AppRoutes.DayTwoForm;
// };


export const adminRoutes = {} as Record<keyof typeof AdminRoutes, string>;
Object.keys(AdminRoutes).forEach((routeKey) => {
  const key = routeKey as keyof typeof AdminRoutes;
  adminRoutes[key] = `${ADMIN_URL_PREFIX}/${AdminRoutes[key]}`;
});
