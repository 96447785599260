import {
  AnyAction,
  Store,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk, { ThunkDispatch } from 'redux-thunk';
import authReducer from './slices/auth';
import visitedScreensReducer from './slices/visitedscreens';
import quizReducer from './slices/quiz';
import adminUsersReducer from './slices/admin/users';
import phytoTrackerReducer from './slices/phytotracker';
import sixweekprogram from './slices/sixweekprogram';
import appointmentpool from './slices/appointmentpool';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'visitedscreens', 'quiz', 'adminUsers'],
};

const reducers = combineReducers({
  auth: authReducer,
  visitedscreens: visitedScreensReducer,
  quiz: quizReducer,
  adminUsers: adminUsersReducer,
  phytoTracker: phytoTrackerReducer,
  sixweekprograms: sixweekprogram,
  appointmentpool
});

const persistedReducer = persistReducer(persistConfig, reducers);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reducers>;

// Create a type for thunk dispatch
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

// Create a type for store using RootState and Thunk enabled dispatch
export type AppStore = Omit<Store<RootState, AnyAction>, 'dispatch'> & {
  dispatch: AppThunkDispatch;
};

export const store: AppStore = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export const persistor = persistStore(store);

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
