import React from 'react';
import '../css/femmar.css';
import { useNavigate } from 'react-router-dom';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import FooterCopyright from '../components/FooterCopyright';

const Resources = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col flex-item-center gap-half fill-page">
      <TopComp />
      <PageTitleComp pageTitle="Resources" />

      <br />

      <div className="flex flex-col flex-item-center flex-space-between gap-half">
        <button
          type="button"
          onClick={() => navigate('/NutritionalAssessment')}
          className="purple-btn">
          Nutritional Assessment Tool
        </button>
      </div>

      <div className="bottom">
        <FooterCopyright />
      </div>
    </div>
  );
};

export default Resources;
