import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import SingleChoice from '../SingleChoice/SingleChoice';
import { IQuestionOptions } from '../../../types/models/Quiz';
import FieldError from '../FieldError/FieldError';

export interface IFieldSingleChoice extends FieldRenderProps<number> {
  options: IQuestionOptions[];
  correctValue?: number[];
}

const FieldSingleChoice: React.FC<IFieldSingleChoice> = ({
  input,
  meta,
  compact,
  options,
  correctValue,
}) => {
  const onChange = (value: number) => {
    input.onChange(value);
  };
  return (
    <div>
      <SingleChoice
        onChange={onChange}
        value={input.value}
        options={options}
        correctValue={correctValue?.[0]}
      />
      <FieldError
        compact={compact}
        visible={!!(meta.touched && meta.error)}
        errorId={meta.error}
      />
    </div>
  );
};

export default FieldSingleChoice;
