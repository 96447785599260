import React from 'react';
import './ToggleButtons.css';

interface PartialButtonItemProp {
  title: string;
  id: string;
}

interface ToggleButtonItem extends PartialButtonItemProp {
  [key: string]: string;
}

interface ToggleButtonsProps {
  buttons: ToggleButtonItem[];
  onChange: (value: ToggleButtonItem) => void;
  selected: string;
}

const ToggleButtons: React.FC<ToggleButtonsProps> = ({
  buttons,
  onChange,
  selected,
}) => {
  return (
    <div className="flex flex-row toggle-button-container">
      {buttons.map((btn) => (
        <button
          type="button"
          className={selected === btn.id ? 'active' : ''}
          key={btn.title}
          onClick={() => onChange(btn)}>
          {btn.title}
        </button>
      ))}
    </div>
  );
};

export default ToggleButtons;
