import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import '../css/femmar.css';
import { useNavigate, useParams } from 'react-router';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import TopComp from '../components/TopComp';
import myContent from '../jsonContent/trans_nutrional.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import FooterCopyright from '../components/FooterCopyright';
import PageTitleComp from '../components/PageTitleComp';
import { client, directusClient } from '../api/client';
import { Question, Option } from '../types/models/QuestionTypes';

type InitialValuesType = { [key: number]: number };

const Nutriotionalfeedbackform = () => {
  const navigate = useNavigate();
  const langNum = useAppSelector(selectLanguageIndex);

  const { id } = useParams();

  const [questions, setQuestions] = useState<Question[]>([]);
  const [schema, setSchema] = useState(null as any);

  const [initialValues, setInitialValues] = useState(null as any);
  const [quizId, setQuizId] = useState(0);
  const [options, setOptions] = useState<{ [key: number]: Option[] }>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuizAndQuestions = async () => {
      try {
        const quizResponse = await directusClient.get(
          '/items/Quizzes?filter[type][_eq]=FeedBack',
        );
        const fetchquizId = quizResponse.data.data[0].id;

        const questionsResponse = await directusClient.get(
          `/items/Questions?filter[quizId][_eq]=${fetchquizId}`,
        );
        setQuestions(questionsResponse.data.data);

        const optionsPromises = questionsResponse.data.data.map(
          (question: any) => {
            return directusClient.get(
              `/items/QuestionOptions?filter[questionId][_eq]=${question.id}`,
            );
          },
        );

        const optionsResponses = await Promise.all(optionsPromises);
        const optionsData = optionsResponses.reduce((acc, response, index) => {
          const questionId = questionsResponse.data.data[index].id;
          acc[questionId] = response.data.data;
          return acc;
        }, {});

        setOptions(optionsData);

      } catch (error) {
        console.error('Error fetching quiz and questions:', error);
      }
    };

    fetchQuizAndQuestions();
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      const newSchema = generateValidationSchema();
      setSchema(newSchema);
      setLoading(false);
    }

  }, [questions])

  const generateValidationSchema = () => {
    const schemaObject: any = {};
    const iniValues: any = {}
    questions.forEach((question) => {
      const questionKey = `question_${question.id}`;
      iniValues[questionKey] = '';
      if (question.type === '0') {
        schemaObject[questionKey] = Yup.number().required(
          'Please select an option',
        );
      } else if (question.type === '1') {
        schemaObject[questionKey] = Yup.string().required(
          'Please provide feedback',
        );
      }
    });

    console.log("*** iniValues", iniValues);

    setInitialValues(iniValues);

    return Yup.object().shape(schemaObject);
  };


  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <PageTitleComp pageTitle="Feedback Form" />
      <p className="day-one-heading">Consultation feedback form</p>
      <p className="nutriotional-feedback-para">
        Your feedback is very important to us. Any answers or comments are
        confidential and used only to inform improvements in our program.
      </p>
      {loading ? (
        <p>Please wait...</p>
      ) : ((initialValues && schema) &&
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={schema}
          onSubmit={async (value: InitialValuesType) => {
            try {
              const appointmentId = id;

              const payload = {
                appointment_id: appointmentId,
                feedbackAnswer: questions.map((question) => {
                  const questionId = question.id;
                  const optionId = parseInt(
                    (value as any)[`question_${questionId}`],
                    10,
                  );

                  if (question.type === '0') {
                    return {
                      question_id: questionId,
                      question_option_id: optionId,
                    };
                  }

                  if (question.type === '1') {
                    return {
                      question_id: questionId,
                      answer: (value as any)[`question_${questionId}`],
                    };
                  }

                  return {};
                }),
              };
              const response = await client.post(
                `/nutritionist/save-feedback`,
                payload,
              );
              navigate('/NutrionalFeedBackSucess')
              console.log('Feedback submitted successfully:', response.data);
            } catch (error) {
              console.log('Nutrional feedback form error', error);
            }
          }}>
          {({ values, handleChange }) => (
            <Form>
              {questions.map((question) => (
                <div key={question.id} className="flex flex-col items-center">
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <label
                        htmlFor={`question_${question.id}`}
                        className="nutrition-feedback-label mb-3 mt-4 ms-5 ">
                        {question.text}
                      </label>
                    </li>
                  </ul>

                  {question.type === '0' && options[question.id] ? (
                    <div className="form-check mb-2">
                      {options[question.id].map((option) => (
                        <div key={option.id}>
                          <div className="row">
                            <div className="col-12 text-start">
                              <Field
                                type="radio"

                                id={`question_${question.id}_${option.id}`}
                                name={`question_${question.id}`}
                                value={option.id}
                                checked={
                                  ((values as any)[`question_${question.id}`] ||
                                    '') === option.id.toString()
                                }
                                onChange={handleChange}
                                className="feedback-radio mb-3 p-3 mt-2 "
                              />{' '}
                              <label
                                htmlFor={`question_${question.id}_${option.id}`}
                                className="nutrition-feedback-option-text">
                                {option.text}
                              </label>
                            </div>

                          </div>

                        </div>
                      ))}
                      <ErrorMessage
                        name={`question_${question.id}`}
                        component="div"
                        className="text-danger mb-1 mt-1 nutrional-form-error"
                      />
                    </div>
                  ) : question.type === '1' ? (
                    <div>
                      <Field
                        as="textarea"
                        id={`question_${question.id}`}
                        name={`question_${question.id}`}
                        rows={5}
                        cols={50}
                        placeholder="Input"
                        className="feedback-textarea mb-3 p-2 mt-3 "
                      />
                      <ErrorMessage
                        name={`question_${question.id}`}
                        component="div"

                        className="text-danger mb-1 mt-1 ms-5 nutrional-form-error"
                      />
                    </div>
                  ) : null}
                </div>
              ))}
              <div className="flex flex-col align-items-center">
                <button
                  type="submit"
                  className="nutrional-feedback-btn text-dark ">
                  Submit
                </button>
              </div>

            </Form>
          )}
        </Formik>
      )}

      <FooterCopyright />
    </div>
  );
};

export default Nutriotionalfeedbackform;
