import React from 'react';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import FooterCopyright from '../components/FooterCopyright';
import { useQuery } from '../hooks/useQuery';

const ContactConfirm = () => {
  const queryParams = useQuery();
  const ticketNumber = queryParams.get('ticket'); // temporary!

  return (
    <div className="padding-bottom5 minHeight100vh">
      <TopComp />
      <PageTitleComp pageTitle="Thank you for your Query" />
      <p>&nbsp;</p>
      <div className="flex flex-col flex-item-center flex-space-around gap1">
        <p className="bg-femmar-pink padding-half width9030 h3">
          Your ticket number is {ticketNumber}
        </p>

        <p className="width9030 h3">
          We will provide you with a response within 24 hours.
        </p>

        <FooterCopyright />
      </div>
    </div>
  );
};

export default ContactConfirm;
