export function withToastForError<Args, Returned>(
  payloadCreator: (args: Args) => Promise<Returned>,
) {
  return async (args: Args, { rejectWithValue }: any) => {
    try {
      return await payloadCreator(args);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  };
}
