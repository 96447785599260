import React ,{useEffect}from 'react';
import '../css/femmar.css';
import { useNavigate } from 'react-router-dom';
import { arrayScreens } from '../constants/global';
import myContent from '../jsonContent/trans_FooterBtns.json';
import { changeCurrentStep } from '../redux/slices/visitedscreens';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';
import { selectLessonChildren, selectProgramLessons } from '../redux/slices/sixweekprogram';

interface FooterBtnsProps {
  nextButtonLabel?: string;
  isLesson?: number
  id?: number
  weekNum?: number
  showPrevButton?: boolean;
}

const FooterBtns: React.FC<FooterBtnsProps> = ({
  nextButtonLabel,
  isLesson,
  id,
  weekNum=0,
  showPrevButton = true,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const programLessons = useAppSelector(selectProgramLessons);

  const lessonChildren = useAppSelector(selectLessonChildren);
  
  const langNum = useAppSelector(selectLanguageIndex);
  const completedSteps = useAppSelector(
    ({ visitedscreens }) => visitedscreens.completedSteps,
  );

  const { prevStep, nextStep } = myContent.trans[langNum];

  const currentLocation = window.location.pathname;
  const currentLocationNoSlash = currentLocation.slice(1);
  const currentSectionIndex = arrayScreens.indexOf(currentLocationNoSlash);
  const prevSectionIndex = currentSectionIndex - 1;
  const nextSectionIndex = currentSectionIndex + 1;
 
  
  const handlePrev = () => {
    navigate(`/${arrayScreens[prevSectionIndex]}`);
    dispatch(changeCurrentStep(arrayScreens[prevSectionIndex]));
  };

  const handleNext = () => {
    if (isLesson) {
      const index = programLessons.findIndex((item) => item.id === id);
      const nextIndex = index + 1;
  
      if (programLessons[nextIndex]) {
        const nextLesson = programLessons[nextIndex];
        if (nextLesson && nextLesson.is_quiz) {
          navigate(`/Quiz/${nextLesson.id}/${weekNum}`);
        } else if (nextLesson) {
          navigate(`/SixWkLesson/${nextLesson.id}/${weekNum}`);
        }
      } else {
        // No more lessons in the current week, check for the next week
        
        const isLastLessonInWeek = programLessons.findIndex((item) => item.id === id) === programLessons.length - 1;
        
        const nextWeekNum = isLastLessonInWeek ? weekNum + 1 : weekNum;
       
        const nextLessonIndex = isLastLessonInWeek ?nextIndex + 1:0 ; 
       
        
        const nextLesson = programLessons[nextLessonIndex];
        
        if (nextLesson) {
          navigate(`/SixWkLesson/${nextLesson.id}/${nextWeekNum}`);
        }
        else{
          const nxtnum= nextWeekNum +1;
          navigate(`/SixWk/${nxtnum}`)
        }
      }
  
      dispatch(changeCurrentStep(arrayScreens[nextSectionIndex]));
    } else {
      const index = lessonChildren.findIndex((item) => item.id === id);
      const nextIndex = index + 1;
  
      if (lessonChildren[nextIndex]) {
        const nextLesson = lessonChildren[nextIndex];
        if (nextLesson && nextLesson.is_quiz) {
          navigate(`/SixWkLesson/${nextLesson.id}/${weekNum}`);
        } else if (nextLesson) {
          navigate(`/Quiz/${nextLesson.id}/${weekNum}`);
        }
      } else {
        // No more lessons in the current week, check for the next week
        const isLastLessonInWeek = lessonChildren.findIndex((item) => item.id === id) === lessonChildren.length - 1;
        const nextWeekNum = isLastLessonInWeek ? weekNum + 1 : weekNum;
        const nextLessonIndex = isLastLessonInWeek ? 0 : index + 1; // Increment lesson index
        const nextLesson = lessonChildren[nextLessonIndex];
        if (nextLesson) {
          navigate(`/Quiz/${nextLesson.id}/${nextWeekNum}`);
        }
      }
  
      dispatch(changeCurrentStep(arrayScreens[nextSectionIndex]));
    }
  };

  return (
    <div className="margin2">
      <div className="flex flex-row flex-space-around gap3">
        {/* {showPrevButton && prevSectionIndex > 0 && (
          <button type="button" onClick={handlePrev} className="footer-btn">
            <div className="flex flex-row flex-items-center flex-space-around gap0 color-black">
              <span className="font-xxl">&lt;</span>&nbsp;
              <span className="font14px margin-top08R">{prevStep}</span>
            </div>
          </button>
        )} */}

        {(isLesson === 1) && (
          <button
            type="button"
            disabled={!completedSteps.includes(currentLocationNoSlash)}
            onClick={handleNext}
            className="footer-btn">
            <div className="flex flex-row flex-items-center flex-space-around gap0 color-black">
              <span className="font14px margin-top08R">
                {nextButtonLabel || nextStep}
              </span>&nbsp;
              <span className="font-xxl">&gt;</span>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export default FooterBtns;
