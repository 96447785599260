import React, { useState } from 'react';
import { omit } from 'lodash';
import { useNavigate } from 'react-router';
import TopComp from '../components/TopComp';
import FormTemplate from '../components/template/FormTemplate';
import RegistrationForm from '../components/Organisms/RegistrationForm/RegistrationForm';
import { RegistrationValues } from '../types/models/Register';
import { showErrorMessage, showSuccsesMessage } from '../utils/banner.utils';
import { register } from '../api/auth';
import '../css/femmar.css';
import { AppRoutes } from '../constants/routes';
import FooterCopyright from '../components/FooterCopyright';
import { REGISTER_EMAIL_KEY } from '../constants/global';

const Register1 = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSignup = async (values: RegistrationValues) => {
    try {
      setLoading(true);
      await register({
        ...omit(values, 'confirmPassword', 'terms'),
        langNum: 0,
      });
      showSuccsesMessage('You have registered successfully!');
      localStorage.setItem(REGISTER_EMAIL_KEY, values.email);
      navigate(AppRoutes.LoginB2C);
    } catch (e) {
      showErrorMessage(e);
    }
    setLoading(false);
  };

  return (
    <div>
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-around">
        <p className="portal-h1 width9060">Create Account</p>




        <FormTemplate
          Component={RegistrationForm}
          onSubmit={onSignup}
          loading={loading}
        />
        <p>&nbsp;</p>
      </div>
      <FooterCopyright />
    </div>
  );
};

export default Register1;
