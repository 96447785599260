import React from 'react';
import '../css/femmar.css';
import parse from 'html-react-parser';
import TopComp from '../components/TopComp';
import myContent from '../jsonContent/trans_Faqs09.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import FooterCopyright from '../components/FooterCopyright';

const Faqs09 = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { q9, a9 } = myContent.trans[langNum];

  return (
    <div style={{ background: 'linear-gradient(whitesmoke, ivory)' }}>
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-around gap1 margin1">
        <p className="h2">{q9}</p>
        <div className="color-navy">{parse(a9)}</div>
      </div>     <FooterCopyright />
    </div>
  );
};

export default Faqs09;
