import React, { useState } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
// import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
// import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import FieldInput from '../../molecules/FieldInput/FieldInput';
import {
  composeValidators,
  emailValidator,
  mustMatchFieldValidator,
  passwordValidator,
  requiredValidator,
  dobValidator,
  requireddobValidator
} from '../../../utils/validations.utils';
import { RegistrationValues } from '../../../types/models/Register';
import '../../../css/femmar.css';
import FieldCheckbox from '../../molecules/FieldCheckbox/FieldCheckbox';

interface RegistrationFormProps extends FormRenderProps<RegistrationValues> {
  loading: boolean;
}

const DisabledInput = (props: any) => {
  return (
    <FieldInput
      {...props}
      // readOnly
    />
  );
};

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  form,
  loading,
}) => {
  const [searchParams] = useSearchParams();

  const [pwdShow, setPwdShow] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const handleBtnPwdShow = () => {
    setPwdShow(true);
  };
  const todayDate = moment().format('YYYY-MM-DD');

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value;

    // If dateOfBirth exists, convert it to 'YYYY-MM-DD' string format for comparison
    const currentDateString = dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : '';
    
    const [year, month, day] = currentDateString ? currentDateString.split('-') : ['', '', ''];
    const [newYear, newMonth, newDay] = selectedDate.split('-');

    // Update only the year if the user changes the year but keeps month and day intact
    if (newYear !== year && month && day) {
      setDateOfBirth(new Date(`${newYear}-${month}-${day}`));
    } else {
      setDateOfBirth(new Date(selectedDate)); // Otherwise, update with the new date
    }
  };
  const handleBtnPwdHide = () => {
    setPwdShow(false);
  };

  // const navigate = useNavigate();

  const handleBtnTerms = () => {
    window.open('/Terms', '_blank');
  };

  const handleBtnPrivacy = () => {
    window.open('/Privacy', '_blank');
  };

  return (
    <div className="flex flex-col flex-item-left flex-space-around gap1 margin-left-1R padding-right">
      <p className="registration-label">Email</p>
      <Field
        component={DisabledInput}
        name="email"
        defaultValue={searchParams.get('email')}
        placeholder="Input"
        validate={composeValidators(requiredValidator, emailValidator)}
        className="register-input"
      />
      <p className="registration-label">First name</p>
      <Field
        component={FieldInput}
        name="firstName"
        placeholder="Input"
        validate={requiredValidator}
        className="register-input"
      />

      <p className="registration-label">Last name</p>
      <Field
        component={FieldInput}
        name="lastName"
        placeholder="Input"
        validate={requiredValidator}
        className="register-input"
      />
      <p className="registration-label">
        Date of birth: (select year, month then date from calendar)
        {/* <span className="small"><b> Please select a Year first, then a Month and then a Date to enter correct date of birth.</b>
        </span> */}
        </p>
      {/* <Field name="dob" validate={composeValidators(requiredValidator, dobValidator)}>
  {({ input, meta }) => (
    <div>
      <input
        type="date"
        value={input.value}
        onChange={(e) => {
          handleDateChange(e);
          input.onChange(e.target.value);
        }}
        className="register-input width9030" 
        placeholder="Select date of birth"
        max={todayDate}
        onKeyDown={(e) => e.preventDefault()}
      /> <br />
      {meta.error && meta.touched && (
        <span className="text-danger">{meta.error}</span>
      )}
    </div>
  )}
</Field>

      {/* <Field name="dob" validate={composeValidators(requiredValidator, dobValidator)}>
  {({ input, meta }) => (
    <div>
      <DatePicker
        selected={dateOfBirth}
        onChange={(date) => {
          setDateOfBirth(date);
          input.onChange(moment(date).format('YYYY-MM-DD'));
        }}
        dateFormat="yyyy-MM-dd"
        placeholderText="Select date of birth"
        className="register-input"
        showYearDropdown
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        onKeyDown={(e) => e.preventDefault()}
      />
      <br />
      {meta.error && meta.touched && (
        <span className="text-danger">{meta.error}</span>
      )}
    </div>
  )}
</Field> */}
{/* <Field
  component="input"
  name="dob"
  type="date"
  placeholder="Select date of birth"
  validate={requireddobValidator}
  className="register-input width9030"
  max={new Date().toISOString().split('T')[0]}
/> */}

<Field name="dob" validate={requireddobValidator}>
  {({ input, meta }) => (
    <div>
      <input
        {...input}
        type="date"
        className="register-input width9030"
        placeholder="Select date of birth"
        max={new Date().toISOString().split('T')[0]}
      /> <br />
      {meta.error && meta.touched && (
        <span className="text-danger">{meta.error}</span> 
      )}
    </div>
  )}
</Field>



      <p className="registration-label">Password</p>

      <div className="flex flex-row flex-item-left flex-space-between gap-half">
        <Field
          component={FieldInput}
          name="password"
          placeholder="Input"
          inputType={!pwdShow ? 'password' : 'text'}
          validate={composeValidators(requiredValidator, passwordValidator)}
          className="registration-pwd"
        />
        {pwdShow && (
          <button
            type="button"
            onClick={handleBtnPwdHide}
            className="btn-pwd-show-hide mt-2"
            aria-label="Toggle password visibility">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="none"
              viewBox="0 0 16 16">
              <rect width="16" height="16" fill="white" />
              <path
                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                fill="#a639e8"
              />
              <circle
                cx="8"
                cy="8"
                r="2.7"
                stroke="#a639e8"
                strokeWidth="1"
                fill="none"
              />
              <circle
                cx="8"
                cy="8"
                r="2.7"
                stroke="#a639e8"
                strokeWidth="0.2"
                fill="none"
              />
            </svg>
          </button>
        )}
        {!pwdShow && (
          <button
            type="button"
            onClick={handleBtnPwdShow}
            className="btn-pwd-show-hide mt-2"
            aria-label="Toggle password visibility">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="none"
              viewBox="0 0 16 16">
              <rect width="16" height="16" fill="white" />
              <path
                d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486z"
                fill="#a639e8"
              />
              <path
                d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"
                fill="#a639e8"
              />
              <path
                d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708"
                fill="#a639e8"
              />
            </svg>
          </button>
        )}
      </div>

      <p className="registration-label">Confirm Password</p>
      <Field
        component={FieldInput}
        name="confirmPassword"
        placeholder="Input"
        inputType={!pwdShow ? 'password' : 'text'}
        className="registration-pwd"
        validate={composeValidators(
          requiredValidator,
          mustMatchFieldValidator('password'),
        )}
      />

      <p className="margin-right-1R">
        Password has to be at least 8 characters in length and contain at least
        one of the following:
        <ul>
          <li>One capital letter</li>
          <li>One number</li>
          <li>One special character</li>
        </ul>
      </p>

      <p className="h2">Terms of Service and Privacy Settings</p>
      <p className="margin-right-1R">
        Your data is kept private and confidential and is not shared without
        consent. We will never sell your data.
      </p>
      <button
        onClick={handleBtnTerms}
        type="button"
        className="register1-link margin-bottom-minus1R">
        Click to read our terms of service
      </button>
      <button
        onClick={handleBtnPrivacy}
        type="button"
        className="register1-link ">
        Click to read our privacy policies
      </button>

      {/*     <span>Check All</span> */}
      <p className="margin-right-1R margin-bottom-minus0pt5R  ">
        By checking the box below, you are indicating that you have read and
        agree to our Terms of Service and Privacy Policies.
      </p>
      <div>
        <Field
          component={FieldCheckbox}
          text="I agree to the Femmar Terms of Service and Privacy Policies."
          name="terms"
          validate={requiredValidator}
        />
      </div>

      <div className="flex flex-row gap-half">
        <input type="checkbox" name="newsletter" />
        &nbsp;
        <label className="text-left">
          I agree to receive the Femmar newsletter and promotional emails. You
          can opt out anytime in settings.
        </label>
      </div>

      <div className="container">
        <button
          disabled={loading}
          type="button"
          className="purple-btn create-account"
          onClick={form.submit}>
          {loading ? 'Registering...' : 'Create Account'}
        </button>
      </div>
    </div>
  );
};

export default RegistrationForm;
