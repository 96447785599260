import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/femmar.css';
import * as Yup from 'yup';
import { useNavigate,useParams } from 'react-router';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import myContent from '../jsonContent/trans_nutrional.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import FooterCopyright from '../components/FooterCopyright';
import FormProgressBar from '../components/FormProgressBar';
import DayOneFormSchema from '../schema/dayOneSchema';
import { Question } from '../types/models/QuestionTypes';
import { client, directusClient } from '../api/client';

import { AppRoutes } from '../constants/routes';

type InitialValues = Record<string, string>;
const DayThreeForm = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(3);
  const totalSteps = 3;
  const user = useAppSelector((state) => state.auth.user);


  const langNum = useAppSelector(selectLanguageIndex);
  const pageTitle = "Pre Consultation";

  const [questions, setQuestions] = useState<Question[]>([]);

  const [schema, setSchema] = useState(DayOneFormSchema as any);
  const [initialValues, setInitialValues] = useState({});
  const [quizId, setQuizId] = useState(0);

  const [loading, setLoading] = useState(true);
  const [answerValues, setAnswerValues] = useState<{ [key: number]: string }>({});
  const [isCompleted, setIsCompleted] = useState(false);

  const { appointmentId, orderId } = useParams();
  const appointmentIdAsNumber = Number(appointmentId);
  const orderIdAsNumber = orderId === 'null' || orderId === 'undefined' || orderId === undefined || orderId === null ? 0 : Number(orderId);

  const handlePrevious=()=>{
    navigate(`/DayTwoForm/${appointmentId}/${orderId}`)
  };

  useEffect(() => {
    const fetchAppointmentDetails = async () => {
      try {
        // const currentAppointmentId = localStorage.getItem('currentAppointmentId');
        
        if (!appointmentId) {
          setInitialValues({});
          setLoading(false);
          // console.log('Error: appointmentId not found');
          return;
        }
        
        const response = await client.get(`nutritionist/appointment-details?id=${appointmentId}`);
        console.log('resp',response.data.data.dietDiaryData)
        const {dietDiaryData} = response.data.data;
        const Iscompleted = response.data.data.appointment.Is_completed;

        // Create a map of question IDs to answer values
        const answerMap: { [key: number]: string } = {};
        dietDiaryData.forEach((item: any) => {
          answerMap[item.questionId] = item.answer;
        });
        
        // Update the answerValues state
        setAnswerValues(answerMap);
        setIsCompleted(Iscompleted);
      } catch (error) {
        console.error('Error fetching appointment details:', error);
      }
    };
  
    fetchAppointmentDetails();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const response = await client.get(`nutritionist/userDetails?userId=${userId}`);
        const data = response.data.userData.dietDiaryAns.result;
        // console.log('nik', data);
        const preFilledData: InitialValues = {};
        data.forEach((item: {
          answer: string;
          questionId: any; quiz: { type: string; }; forEach: (arg0: (subitem: { questionId: number; answer: string; }) => void) => void; 
  }) => {  
          questions.forEach((question) => { 
            
            if (item.quiz.type === 'Diet Diary Day 3' && item.questionId === question.id) { 
              preFilledData[`question_${question.id}`] = item.answer;
              // console.log('nit',preFilledData)
            }
          });
        });
        setInitialValues(preFilledData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserData();
  }, [questions]);

  useEffect(() => {

    const fetQuiz = async () => {
      return directusClient.get(
        '/items/Quizzes?filter[type][_eq]=Diet Diary Day 3',
      );
    }

    const fetchData = async (id: number) => {
      try {
        const response = await directusClient.get(
          `/items/Questions?filter[quizId][_eq]=${id}`,
        );
        setQuestions(response.data.data);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetQuiz().then(res => {
      setQuizId(res.data.data[0].id);
      fetchData(res.data.data[0].id);
    });
  }, []);


  useEffect(() => {
    if ((questions && questions.length > 0) ) {
      
      const initialValueData: any = {};
      const schemaData: any = {};
      questions.forEach((question) => {
        const questionId = question.id;
        const preFilledValue = answerValues[questionId] || '';
        initialValueData[`question_${questionId}`] = preFilledValue;
        // Set the schema as required for all fields
        schemaData[`question_${questionId}`] = Yup.string().required("This field is required");
      });
  
      const combinedSchema = Yup.object().shape(schemaData);
  
      setSchema(combinedSchema);
      setInitialValues(initialValueData);
      setLoading(false);
    }
  }, [ questions, answerValues]);

 

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <FormProgressBar steps={totalSteps} currentStep={currentStep} />
      <p className="day-one-heading">{user?.firstName}’s Pre-consultation questions</p>

      <p className="day-one-head">Dietary Information</p>
      <p className="day-three-para-one align-items-start">
        Please add information about what food and drink you
        <br />
        have consumed for three days to allow the consultant <br />
        to assess changes you might need to make.
      </p>

      <h4 className="day-one-form-head">Day 3</h4>

      {
        loading ? <p>Please wait...</p> :
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize
            onSubmit={async (values: any) => {
              try {
                if (isCompleted) {
                  navigate('/MyAppointments');
                } else {
                  // const currentAppointmentId = localStorage.getItem('currentAppointmentId');
            
                  // Check if currentAppointmentId is null
                  // eslint-disable-next-line no-lonely-if
                  if (!appointmentIdAsNumber) {
                    // console.log('Error: currentAppointmentId not found');
            
                    // Payload without appointmentId
                    const payload = {
                      quizId,
                      answers: Object.keys(values).map((key) => ({
                        questionId: parseInt(key.replace('question_', ''), 10),
                        value: values[key],
                      })),
                    };
            
                    await client.post('/result/save-diet-diary', payload).then(() => {
                      setCurrentStep(currentStep + 1);
                      navigate('/appointment/success');
                    });
                  } else {
                    // Payload with appointmentId
                    const payload = {
                      quizId,
                      appointmentId,
                      orderId: orderIdAsNumber ,
                      answers: Object.keys(values).map((key) => ({
                        questionId: parseInt(key.replace('question_', ''), 10),
                        value: values[key],
                      })),
                    };
            
                    await client.post('/result/save-diet-diary', payload).then(() => {
                      setCurrentStep(currentStep + 1);
                      navigate('/MyAppointments');
                    });
                  }
                }
              } catch (error) {
                console.error('Error submitting form:', error);
              }
            }}
            >
              {({ values, handleChange }) => (
            <Form>
              {questions.map((question) => (
                <div key={question.id} className="flex flex-col items-center ms-5 ps-2 ms-md-0">
                  {question.type === '0' ? (
                    <>
                      <label
                        htmlFor={`question_${question.id}`}
                        className="day-one-label mb-2 align-items-start">
                        {question.text}
                      </label>
                      <Field
                        as="textarea"
                        id={`question_${question.id}`}
                        name={`question_${question.id}`}
                        rows={4}
                        cols={50}
                        value={values[`question_${question.id}`] || ''}
                        placeholder="Input"
                        className="day-one-input mb-3 p-3"
                        disabled={isCompleted}
                      />
                      <ErrorMessage
                        name={`question_${question.id}`}
                        component="div"
                        className="text-danger mb-1 nutrional-form-error"
                      />
                    </>
                  ) : question.type === '1' ? (
                    <>
                      {question.constantName === 'MEDICATIONS' && (
              
                <label className="day-three-label mb-2 align-items-start mt-3">
                  Medications
                </label>

            )}
            {question.constantName === 'SUPPLEMENTS' && (
              
                <label className="day-three-label mb-2 align-items-start mt-3">
                  Supplements
                </label>

            )}
                     <p className="day-three-para">{question.text}</p>

                      <Field
                        as="textarea"
                        id={`question_${question.id}`}
                        name={`question_${question.id}`}
                        rows={4}
                        cols={50}
                        value={values[`question_${question.id}`] || ''}
                        placeholder="Input"
                        className="day-three-input mb-3 p-3"
                        disabled={isCompleted}
                      />
                      <ErrorMessage
                        name={`question_${question.id}`}
                        component="div"
                        className="text-danger mb-1 nutrional-form-error"
                      />
                    </>
                  ) : null}
                </div>
              ))}
              <div className="day-two-btn-main">
              <button type="button" className="day-two-btn text-dark" onClick={handlePrevious}>
                Previous
              </button>
              <button type="submit" className="day-two-btn text-dark">
                Submit
              </button>
              </div>
            </Form>
            )}
          </Formik>
      }


      <FooterCopyright />
    </div>
  );
};

export default DayThreeForm;
