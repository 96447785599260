import React, { useState } from 'react';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';
import FormTemplate from '../components/template/FormTemplate';
import ForgotPasswordForm from '../components/Organisms/ForgotPasswordForm/ForgotPasswordForm';
import { ForgotPasswordValues } from '../types/models/ForgotPassword';
import { forgotPassword } from '../api/auth';
import { showErrorMessage } from '../utils/banner.utils';

const ForgetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const onSubmit = async (values: ForgotPasswordValues) => {
    try {
      setLoading(true);
      await forgotPassword(values);
      setSent(true);
    } catch (e) {
      showErrorMessage(e);
    }
    setLoading(false);
  };
  return (
    <div>
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-between gap2 padding-bottom5">
        <p className="h1 margin-bottom-minus1pt5">Forgot Password?</p>
        {sent ? (
          <div>We have sent you an email for reset password instructions.</div>
        ) : (
          <FormTemplate
            Component={ForgotPasswordForm}
            onSubmit={onSubmit}
            loading={loading}
          />
        )}
      </div>
      <FooterCopyright />
    </div>
  );
};

export default ForgetPassword;
