import { useLocation, useNavigate } from 'react-router';
import { useAppSelector } from '../redux/store';
import { UserRoles } from '../types/models/User';
import { checkIfUserPaid, isFreeUser } from '../utils/auth.utils';
import { AppRoutes } from '../constants/routes';

export const useCheckPayment = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const auth = useAppSelector((state) => state.auth);
  const { orders, user } = auth;
  const isFree = isFreeUser(user?.userAttributes?.userRole as UserRoles);
  const isPaid = checkIfUserPaid(orders);
  const noPaymentNeeded = isFree || isPaid;

  return;
  
  if (pathname.includes(AppRoutes.Payment)) {
    if (noPaymentNeeded) {
      // TODO: find the last step using the same login from login
      // Navigate user to app if already paid
      //   navigate(AppRoutes.DashboardMain, { replace: true });
    }
    return;
  }
  if (noPaymentNeeded) {
    return;
  }
  navigate(AppRoutes.Payment, { replace: true });
};
