import React, { useEffect } from 'react';
import { useParams } from 'react-router';
// import { useNavigate } from 'react-router-dom';
import QuestionComp from '../components/QuestionComp';
import { QuizTypes } from '../types/models/QuizTypes';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import FooterCopyright from '../components/FooterCopyright';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { selectLessonDetail } from '../redux/slices/sixweekprogram';
import { getLessonDetailAction } from '../redux/actions/sixweekprograms';


const SixWk1L7Quiz = () => {
  const params = useParams();
  const lessonDetail = useAppSelector(selectLessonDetail);

  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  // const [isNextButtonDisabled, setNextButtonDisabled] = useState(false);

  useEffect(() => {
    if (params.id) {
      dispatch(getLessonDetailAction({ id: parseInt(params.id, 10) }));
    }
    
  }, [params.id]);

  console.log(" **** lessonDetail ", lessonDetail);
  // const handleNext=()=>{
  //   navigate(`/SixWkLesson/1/0`);
  // }

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap-half page-full">
      <TopComp />
      <PageTitleComp weekNum={parseInt(params.weekNum || "0", 10)} pageTitle={lessonDetail?.program_title || ''} />
      {lessonDetail && (
      <div><QuestionComp
        weekNumber={parseInt(params.weekNum || "1", 10)}
        className="flex flex-col flex-item-center flex-space-between gap1"
        type={lessonDetail.listing_label.includes("Symptom") ? QuizTypes.PROGRESS : QuizTypes.Quiz}
      />
      {/* {lessonDetail.listing_label.includes("Progress") && (
            <button
              type="button"
            
              onClick={handleNext}
              className="footer-btn">
              <div className="flex flex-row flex-items-center flex-space-around gap0 color-black">
                <span className="font14px margin-top08R">
                  Go To next Lesson
                </span>&nbsp;
                <span className="font-xxl">&gt;</span>
              </div>
            </button>
          )} */}
      </div>)}
      
      <FooterCopyright />
    </div>
  );
};

export default SixWk1L7Quiz;
