import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import NestedChoice from '../NestedChoice/NestedChoice';
import { INestedValue } from '../../../types/models/QuestionTypes';
import { IQuestion, IQuestionOptions } from '../../../types/models/Quiz';
import FieldError from '../FieldError/FieldError';

export interface IFieldNestedChoice extends FieldRenderProps<INestedValue[]> {
  options: IQuestionOptions[];
  nestedQuestions: IQuestion['nestedQuestions'];
}

const FieldNestedChoice: React.FC<IFieldNestedChoice> = ({
  input,
  meta,
  compact,
  options,
  nestedQuestions,
}) => {
  const onChange = (value: INestedValue) => {
    let newValue = [] as INestedValue[];
    const index = input.value.findIndex(
      (inputValue) => inputValue.questionId === value.questionId,
    );
    if (index !== -1) {
      newValue = input.value.map((inputValue, valueIndex) =>
        valueIndex === index ? value : inputValue,
      );
    } else {
      newValue = [...input.value, value];
    }
    input.onChange(newValue);
  };
  return (
    <div >
      <NestedChoice
        onChange={onChange}
        value={input.value}
        options={options}
        nestedQuestions={nestedQuestions}
      />
      <FieldError
        compact={compact}
        visible={!!(meta.touched && meta.error)}
        errorId={meta.error}
      />
    </div>
  );
};

export default FieldNestedChoice;
