import { AxiosPromise } from 'axios';
import { client } from './client';

interface getWeekZerotResponse {
  id: number;
  week_number: number;
  week_name: string;
  label: string;
  image_url: string;
  markCompleted: boolean;
}

export const getWeekZeroAction = (): AxiosPromise<{ data: getWeekZerotResponse[] }> =>
  client.get('/users/six-week-program');
