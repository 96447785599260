import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAnswers, saveAnswers } from '../../api/questionApi';
import { WeekSymptomAnswer } from '../../types/models/QuestionModel';
import { SaveResultDto } from '../../types/models/ApiResult';
import { withToastForError } from '../../utils/thunk.utilts';

export const getAnswersAction = createAsyncThunk<
  WeekSymptomAnswer[],
  { weekNumber?: number, userId?: number }
>(
  'symptomtracker/get',
  withToastForError(async (params) => {
    const response = await fetchAnswers(params);
    return response.data.result;
  }),
);

export const saveAnswersAction = createAsyncThunk<
  WeekSymptomAnswer[],
  { result: SaveResultDto[] }
>(
  'symptomtracker/save',
  withToastForError(async (params) => {
    const response = await saveAnswers(params);
    return response.data.answers;
  }),
);
