import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import FieldInput from '../../molecules/FieldInput/FieldInput';
import {
  composeValidators,
  mustMatchFieldValidator,
  passwordValidator,
  requiredValidator,
} from '../../../utils/validations.utils';
import { ChangePasswordFormValues } from '../../../types/models/User';

interface ChangePasswordFormProps
  extends FormRenderProps<ChangePasswordFormValues> {
  loading: boolean;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  form,
  loading,
}) => {
  return (
    <div className="flex flex-col flex-item-left flex-space-around gap1 margin-left5pcnt-portrait margin-left25pcnt-landscape">
      <p className="registration-label">Current Password</p>
      <Field
        component={FieldInput}
        name="currentPassword"
        placeholder="Input"
        validate={requiredValidator}
        inputType="password"
      />
      <p className="registration-label">New Password</p>
      <Field
        component={FieldInput}
        name="newPassword"
        placeholder="Input"
        validate={composeValidators(requiredValidator, passwordValidator)}
        inputType="password"
      />
      <p className="registration-label">Confirm New Password</p>
      <Field
        component={FieldInput}
        name="confirmNewPassword"
        placeholder="Input"
        validate={composeValidators(
          requiredValidator,
          mustMatchFieldValidator('newPassword'),
        )}
        inputType="password"
      />
      <button
        disabled={loading}
        type="button"
        className="purple-btn"
        onClick={form.submit}>
        {loading ? 'loading...' : 'Change Password'}
      </button>
    </div>
  );
};

export default ChangePasswordForm;
