import { AxiosPromise } from 'axios';
import { client } from './client';
import { QuizTypes } from '../types/models/QuizTypes';
import { IQuiz } from '../types/models/Quiz';
import { CorrectAnswer, SaveResultDto } from '../types/models/ApiResult';

export const fetchQuizByType = (
  type: QuizTypes,
  weekNumber?: number,
): AxiosPromise<{
  quiz: IQuiz;
}> => client.get('/quiz', { params: { type, weekNumber } });

export const verifyQuizAnswer = (params: {
  result: SaveResultDto[];
  quizId: number;
}): AxiosPromise<{ isCorrect: boolean }> =>
  client.post('/quiz/verify-answers', params);

export const getQuizAnswer = (params: {
  quizId: number;
}): AxiosPromise<CorrectAnswer[]> => client.get('/quiz/answers', { params });
