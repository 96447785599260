import React from 'react';
import { IQuestionOptions } from '../../../types/models/Quiz';

export interface IMultipleChoiceProps {
  value: number[];
  title: string;
  options: IQuestionOptions[];
  onChange: (value: number) => void;
  correctValue?: number[];
}

const MultipleChoice: React.FC<IMultipleChoiceProps> = ({
  value,
  title,
  options,
  onChange,
  correctValue,
}) => {
  const getHiglightClass = (option: IQuestionOptions) => {
    if (!correctValue?.length) return '';
    return correctValue.includes(option.id) ? 'highlight' : 'no-highlight';
  };
  return (
    <div className="flex flex-col flex-left gap-half">
      {/*  probably not in use??? */}
      <p className="h3">{title}</p>

      {options.map((option) => (
        <div
          key={`${option.id}-${option.text}`}
          className={`flex flex-row flex-align-center gap-half  ${getHiglightClass(
            option,
          )}`}>
          <input
            type="checkbox"
            id={`${option.id}-${option.text}`}
            checked={value.includes(option.id)}
            onChange={() => onChange(option.id)}
          />

          <div className="text-left">
            <label
              htmlFor={`${option.id}-${option.text}`}
              className="padding-half">
              {option.text}
            </label>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultipleChoice;
