import React from 'react';
import '../css/femmar.css';
import mQuotes from '../jsonContent/motivationalQuotes.json';

const MotivationalQuotesComp = () => {
  const dayOfYear = Math.floor(
    (new Date().getTime() -
      new Date(new Date().getFullYear(), 0, 0).getTime()) /
      86400000,
  );
  const quotationNumber = (dayOfYear + 14) % 14;
  const quote = `"${mQuotes.motivationalQuotes[quotationNumber].mq}"`;
  const author = ` ${mQuotes.motivationalQuotes[quotationNumber].by}`;

  return (
    <div>
      <div className="motivationalQuote">
        <p className="motivationalQuote-mq">{quote}</p>
        <p className="motivationalQuote-by">{author}</p>
      </div>
    </div>
  );
};

export default MotivationalQuotesComp;
