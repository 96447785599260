import React, { useState, useEffect } from 'react';
import '../css/femmar.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserAction } from '../redux/actions/user';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import BigPinkBtns from '../components/BigPinkBtns';
import { useAppSelector,useAppDispatch } from '../redux/store';
import MyPlanComp from '../components/MyPlanComp';
import FooterCopyright from '../components/FooterCopyright';
import { selectCurrentStep } from '../redux/slices/visitedscreens';
import MotivationalQuotesComp from '../components/MotivationalQuotesComp';
import ProgressBarComp from '../components/ProgressBarComp';
import { UserRoles } from '../types/models/User';
import { getWeekZeroAction } from '../api/weekzero';
import Personalizedsupp from '../components/Personalizedsupp';

interface WeekData {
  id: number;
  week_number: number;
  week_name: string;
  label: string;
  image_url: string;
  markCompleted: boolean;
}

const DashboardMain = () => {
  const [weekData, setWeekData] = useState<WeekData[] | null>(null);
  const navigate = useNavigate();
  
  const user = useAppSelector(({ auth }) => auth.user);
  const userName = user?.firstName;
  const pageTitle = `Hello, ${userName}`;
  const userNameActionPlan = `${userName}'s Plan`;

  const currentStep = useAppSelector(selectCurrentStep);
  const dispatch=useAppDispatch();
  const [courseStatus, setCourseStatus] = useState<boolean | null>(null);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await dispatch(getCurrentUserAction()).unwrap();
        // console.log('Course status:', userData.courseStatus); 
        setCourseStatus(userData.courseStatus);
      } catch (error) {
        console.error('Error getting user data:', error);
      }
    };

    fetchUserData();
  }, [dispatch]);

  const buttonText = courseStatus === false ? 'Click to Start Program' : 'Click to Continue Program';

  // console.log(`currentStep=${currentStep}`); // check this on a new user
  // console.log(user?.firstName); // SAGAR why is this undefined?
 
  // //////////////
  useEffect(() => {
    const fetchWeekZero = async () => {
      try {
        const response = await getWeekZeroAction();
        setWeekData(response.data.data);
      } catch (error) {
        console.log('error getting action detail', error);
      }
    };
    fetchWeekZero();
  }, []);

  const handleStartContinue = () => {
    navigate('/SixWeekMenu');
  };

  useEffect(() => {
    if (user?.userAttributes?.userRole === UserRoles.Nutritionist) {
      navigate('/NutrionalDashInfo');
    }
  }, []);
  const handleBtnActionPlan = () => {
    navigate('/ActionDetail');
  };

  const isWeekZeroCompleted = weekData?.find(
    (week: WeekData) => week.week_number === 0,
  )?.markCompleted;
 
  // ////////////////77

  return (
    <div className="flex flex-col flex-item-center gap0 bg-white">
      <TopComp />

      <PageTitleComp pageTitle={pageTitle} />
      <BigPinkBtns />

      <br />

      <button
        type="button"
        onClick={handleStartContinue}
        className="dashboard-start-continue">
          {buttonText}
        {/* {currentStep === 'DashboardTour' ? 'Start Program' : 'Click to Continue Program'}  */}
      </button>

      <ProgressBarComp />

      <br />

      <div className="width9030">
        <MotivationalQuotesComp />
      </div>

      <br />

      {isWeekZeroCompleted && (
        <div className="flex flex-col flex-item-center flex-space-between gap-half">
          <p className="h2 margin-top-half">{userNameActionPlan}</p>

          <button
            type="button"
            onClick={handleBtnActionPlan}
            className="myPlan-expanding-btn width9030"
            style={{ backgroundColor: '#ffcbe1' }}>
            <div className="flex flex-row flex-item-center flex-space-around margin-left1">
              <p className="flex7 font16px color-black">My Action Plan</p>
              <i className="bi bi-arrow-right-circle flex1 arrow-more-less fw-bold" />

              {/* {actionPlanVisible === false ? (
      <img src={arrowMore} alt="" className="flex1 arrow-more-less" />
    ) : (
      <img src={arrowLess} alt="" className="flex1 arrow-more-less" />
    )} */}
            </div>
          </button>
          <MyPlanComp />
          <Personalizedsupp/>
        </div>
      )}
      <FooterCopyright />
    </div>
  );
};

export default DashboardMain;
