import moment from 'moment';
import { FieldValidator } from 'final-form';
import { HeightUnits, HeightValue, WeightValue } from '../types/types';

type Validator = FieldValidator<any>;

export const composeValidators =
  (...validators: Validator[]) =>
  (value: any, allValues: any, meta: any) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues, meta),
      undefined,
    );
    export const dobValidator: Validator = (value: any) => {
      if (!value) return 'required';
    
      
      const dob = moment(value, 'YYYY-MM-DD');
    
      if (!dob.isValid()) return 'invalid date';
    
      
    
      return undefined;
    };
export const requiredValidator: Validator = (value) =>
  !value ? 'required' : undefined;

export const requireddobValidator = (value: any) => (value ? undefined : 'required');


export const arrayRequiredValidator: Validator = (value) => {
  return !value || !value.length ? 'required' : undefined;
};

export const emailValidator: Validator = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'invalid email address'
    : undefined;

type MustMatchFieldValidator = (otherFieldName: string) => Validator;

export const mustMatchFieldValidator: MustMatchFieldValidator =
  (otherFieldName: string) => (value: any, allValues: any) =>
    value !== allValues[otherFieldName] ? "field doesn't match" : undefined;

export const heightValidator: Validator = (value: HeightValue) => {
  if (!value?.unit) return 'invalid height unit';
  if (value.unit === HeightUnits.CM && !value.cm)
    return 'Height can not be empty';
  if (value.unit === HeightUnits.FT && !value.feet)
    return 'Feet can not be empty';
  if (value.unit === HeightUnits.FT && !value.inches)
    return 'Inches can not be empty';
  return undefined;
};

export const weightValidator: Validator = (value: WeightValue) => {
  if (!value?.unit) return 'invalid weight unit';
  if (!value.weight) return 'Weight can not be empty';
  return undefined;
};

export const passwordValidator: Validator = (value: string) => {
  const pattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/;
  return !pattern.test(value) ? 'invalid password' : undefined;
};
