import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import FieldInput from '../../molecules/FieldInput/FieldInput';
import {
  IUser,
  NotificationPreference,
  PromotionalMessage,
  UserPreferenceKeys,
} from '../../../types/models/User';
import { requiredValidator } from '../../../utils/validations.utils';
import FieldRadioInputGroup from '../../molecules/FieldRadioInputGroup/FieldRadioInputGroup';
import FieldCheckboxInputGroup from '../../molecules/FieldCheckboxInputGroup/FieldCheckboxInputGroup';

interface AccountSettingsFormProps extends FormRenderProps<IUser> {
  loading: boolean;
  email: string;
}

const AccountSettingsForm: React.FC<AccountSettingsFormProps> = ({
  loading,
  form,
  email,
}) => {
  return (
    <div className="flex flex-col flex-item-left flex-space-around gap1 margin-left5pcnt-portrait margin-left25pcnt-landscape">
      <p className="registration-label">First Name</p>
      <Field
        component={FieldInput}
        name="firstName"
        placeholder="First Name"
        validate={requiredValidator}
      />
      <p className="registration-label">Last Name</p>
      <Field
        component={FieldInput}
        name="lastName"
        placeholder="Last Name"
        validate={requiredValidator}
      />
      <p className="registration-label">Company Name</p>
      <Field
        component={FieldInput}
        name="companyName"
        placeholder="Company Name"
      />
      <p className="registration-label">Job Title</p>
      <Field component={FieldInput} name="jobTitle" placeholder="Job Title" />
      <p className="registration-label">Email</p>
      <input
        type="text"
        className="padding-half border-thin border-round width9030"
        value={email}
        disabled
      />
      <p className="registration-label">
        Mobile Number (use international format e.g. “+1 555 234 332)This is
        used for notifications. We will not sell your personal data.
      </p>
      <Field component={FieldInput} name="phoneNumber" placeholder="Phone" />

      <br />
      <p className="h2">Notifications</p>
      <b>Receive Notifications via (tick all that apply)</b>
      <Field
        component={FieldRadioInputGroup}
        options={[
          { id: NotificationPreference.EMAIL, text: 'Email Only' },
          { id: NotificationPreference.SMS, text: 'Text Message (SMS) Only' },
          {
            id: NotificationPreference.BOTH,
            text: 'Both Email and Text Message (SMS)',
          },
          { id: NotificationPreference.NONE, text: 'No Notifications' },
        ]}
        name={UserPreferenceKeys.Notification}
      />
      <b>Opt In/Out of promotional messages (Tick all that apply)</b>
      <Field
        component={FieldCheckboxInputGroup}
        options={[
          { id: PromotionalMessage.FEMMAR, text: 'Femmar Emails' },
          { id: PromotionalMessage.NEWSLETTER, text: 'Newsletters' },
          {
            id: PromotionalMessage.PARTNER,
            text: 'Femmar Partners Emails',
          },
        ]}
        name={UserPreferenceKeys.PromotionalMessage}
      />
      <button
        disabled={loading}
        type="button"
        className="purple-btn"
        onClick={form.submit}>
        {loading ? 'loading...' : 'Save Changes'}
      </button>
    </div>
  );
};

export default AccountSettingsForm;
