import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import myContent from '../../jsonContent/trans_nutrional_dashboard.json';
import { selectLanguageIndex } from '../../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../../redux/store';

import { client } from '../../api/client';
import { getAppointmentAction } from '../../redux/actions/appointmentpool';
import {
  selectAppointment,
  selectAppointmentUser,
} from '../../redux/slices/appointmentpool';

const schema = Yup.object().shape({
  selectedDate: Yup.string().required('Please select a date'),
  selectedTime: Yup.string().required('Please select time'),
  notes: Yup.string().required('Notes are required'),
});

const TimePickerInput = (props: any) => {
  return (
    <input
      type="time"
      value={props.form.values.selectedTime}
      onChange={(e) => props.form.setFieldValue('selectedTime', e.target.value)}
      className="nutrional-dash-consult-timepicker p-2"
    />
  );
};

const DatePickerInput = (props: any) => {
  return (
    <DatePicker
      popperPlacement="bottom-start"
      selected={props.form.values.selectedDate}
      onChange={(date) => {
        if (date) {
          props.form.setFieldValue('selectedDate', date);
        } else {
          props.form.setFieldValue('selectedDate', '');
        }
      }}
      className="nutrional-dash-consult-datepicker p-2 me-5 pe-5 "
    />
  );
};
const Nutionisitinput = () => {
  const navigate = useNavigate();
  const langNum = useAppSelector(selectLanguageIndex);
  const appointmentData = useAppSelector(selectAppointment);
  const appointmentUser = useAppSelector(selectAppointmentUser);
  const { questions } = myContent.trans[langNum];
  const [initialValues, setInitialValues] = useState({
    selectedDate: '' as any,
    selectedTime: '',
    notes: '',
  });
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const lastTypingTimestamp = useRef<number>(0);
  const [isAutoSaveNotificationShown, setIsAutoSaveNotificationShown] =
    useState(false);
  const notify = () => toast('Notes Saved Successfully!');
  const dispatch = useAppDispatch();

  useEffect(() => {
    const appointmentId = localStorage.getItem('appointmentId');
    // console.log(' **** appointmentId', appointmentId);
    if (appointmentId) {
      dispatch(getAppointmentAction({ id: parseInt(appointmentId, 10) }));
    }
  }, []);

  useEffect(() => {
    if (appointmentData) {
      setInitialValues({
        selectedDate: moment(appointmentData.appointment_date).toDate(),
        selectedTime: appointmentData.appointment_time_from.replace(':00', ''),
        notes: appointmentData.before_appointment,
      });
    }
  }, [appointmentData]);

  useEffect(() => {
    // when isAutoSaveNotificationShown becomes true , display notification
    if (isAutoSaveNotificationShown) {
      notify();
    }
  }, [isAutoSaveNotificationShown]);

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <p className="nutrional-dash-heading">
        Nutritionist Notes Before Consultation
      </p>

      <Alert variant="warning">
        <p className="text-dark">
          You can utilize below textbox for adding notes before consulation.
        </p>
      </Alert>
      <ul className="nutrional-dash-notes-ques">
        {questions.map((question: string) => (
          <li key={question}>{question}</li>
        ))}
      </ul>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          const appointmentId = localStorage.getItem('appointmentId');

          if (appointmentId) {
            client
              .post('/nutritionist/update-appointment', {
                id: parseInt(appointmentId, 10),
                appointment_date: moment(values.selectedDate).format(
                  'YYYY-MM-DD HH:mm:ss',
                ),
                appointment_time_from: values.selectedTime,
                before_appointment: values.notes,
              })
              .then((response: any) => {
                if (!isAutoSaveNotificationShown) {
                  notify();
                  
                }
              });
          } else {
            client
              .post('/nutritionist/save-appointment', {
                user_id: appointmentUser?.user.id,
                appointment_date: moment(values.selectedDate).format(
                  'YYYY-MM-DD HH:mm:ss',
                ),
                appointment_time_from: values.selectedTime,
                before_appointment: values.notes,
              })
              .then((response: any) => {
                localStorage.setItem(
                  'appointmentId',
                  response.data.response.id,
                );
                if (!isAutoSaveNotificationShown) {
                  notify();
                  
                }
              });
          }
        }}>
        {({ values, handleChange, submitForm }) => (
          <Form>
            <div className="row">
              <div className="col-6 text-left">
                <label>
                  <b>Date of Appointment</b>
                </label>
                <Field name="selectedDate" component={DatePickerInput} />
                <ErrorMessage
                  name="selectedDate"
                  component="div"
                  className="text-danger mb-1 mt-2 nutrional-form-error"
                />
              </div>
              <div className="col-6 text-left">
                <label>
                  <b>Time of Appointment</b>
                </label>
                <div>
                  <Field name="selectedTime" component={TimePickerInput} />
                  <ErrorMessage
                    name="selectedTime"
                    component="div"
                    className="text-danger mb-1 mt-2 nutrional-form-error"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12 text-left">
                <label>
                  <b>Notes</b>
                </label>
                <div>
                  <Field
                    as="textarea"
                    name="notes"
                    rows="8"
                    className="p-3 nutrional-dash-notes-input "
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      handleChange(e);
                      lastTypingTimestamp.current = Date.now();
                      setIsAutoSaving(true);
            
                      setTimeout(() => {
                        const currentTime = Date.now();
                        const timeSinceLastTyping = currentTime - lastTypingTimestamp.current;
            
                        if (timeSinceLastTyping >= 10000) {
                          setIsAutoSaving(false);
                          submitForm();
                        } else {
                          setIsAutoSaving(false);
                        }
                      }, 10000);
                    }}
                  />
                  <ErrorMessage
                    name="notes"
                    component="div"
                    className="text-danger mb-1 nutrional-form-error"
                  />
                </div>
              </div>
            </div>

            {isAutoSaving && values.notes.trim().length > 0 && (
              <p className="">Auto-saving...</p>
            )}
            <button
              type="submit"
              className="nutrional-dash-btn text-dark mt-2 w-100"
              onClick={() => {
                setIsAutoSaving(false);
                submitForm();

                if (values.notes.trim().length > 0 && !isAutoSaveNotificationShown) {
                  setIsAutoSaveNotificationShown(true);
                  
                }
              }}>
              Save
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Nutionisitinput;
