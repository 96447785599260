import React, { useEffect, useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { client } from '../api/client';
import arrowMore from '../images/icons/arrowMore2.png';
import arrowLess from '../images/icons/arrowLess2.png';

interface SupplementDetail {
    id: number;
    brand: string;
    product: string;
    supplement: string | null;
    directions: string;
    link: string;
    shortDisplayNames: string;
    longDisplayNames: string;
    order: number;
    visible: boolean;
    createdAt: string;
    updatedAt: string;
    right_side_ans: string;
  }

interface AppointmentDetails {
  appointment_id: number;
  directions: string;
  supplement: string;
  right_side_ans: string;
  updatedAt: string;
}

interface AppointmentState {
  loading: boolean;
  error: string | null;
  data: SupplementDetail[] | null;
}

const Personalizedsupp = () => {
  const [appointment, setAppointment] = useState<AppointmentState>({
    loading: true,
    error: null,
    data: null,
  });
  const [supplementScheduleVisible, setSupplementScheduleVisible] =
  useState(false);
  useEffect(() => {
    const fetchAppointmentIdAndDetails = async () => {
      try {
        
        const userId = localStorage.getItem('userId');

        if (!userId) {
          throw new Error('User ID not found in localStorage');
        }

        const firstResponse = await client.get(`/users/product-suppliments`);
        
        // const appointmentId = firstResponse.data.personalizedSupplementPlan[0].appointment_id;
        // console.log('ece',appointmentId)

        // if (!appointmentId) {
        //   throw new Error('Appointment ID not found in response');
        // }

        
        // const secondResponse = await client.get(
        //   `/nutritionist/appointment-details?id=${appointmentId}&userId=${userId}`
        // );
        
        // Assuming the response contains appointment details in the 'personalizedSupplementPlan' field
        const appointmentDetails = firstResponse.data.personalizedSupplementPlan;
        // console.log('nn',appointmentDetails)
        

        // Update state with fetched data
        setAppointment({
          loading: false,
          error: null,
          data: appointmentDetails,
        });
      } catch (error: any) {
        // Handle errors and update state
        setAppointment({
          loading: false,
          error: error.message || 'An error occurred while fetching data.',
          data: null,
        });
      }
    };

    fetchAppointmentIdAndDetails();
  }, []);
  const handleBtnSupplementSchedule = () => {
    const y =
      supplementScheduleVisible === true
        ? setSupplementScheduleVisible(false)
        : setSupplementScheduleVisible(true);
  };

  const openLinkInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  
 
  if (appointment.error || (Array.isArray(appointment.data) && appointment.data.length === 0)) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Alert variant="info">
          No personalized supplement plan exist currently.
        </Alert>
      </div>
    );
  }

  return (
    <>
    {appointment.data &&(
      <button
        type="button"
        onClick={handleBtnSupplementSchedule}
        className="myPlan-expanding-btn width9030">
        <div className="flex flex-row flex-item-center flex-space-around margin-left1">
          <p className="flex7 font16px color-black">Personalized supplement plan</p>

          {supplementScheduleVisible === false ? (
            <img src={arrowMore} alt="" className="flex1 arrow-more-less" />
          ) : (
            <img src={arrowLess} alt="" className="flex1 arrow-more-less" />
          )}
        </div>
      </button>
    )}
    
      {supplementScheduleVisible && appointment.data && (
        <div className="flex flex-col flex-item-center">
          <table className="supp-schedule margin1 width9055 flex-space-around flex-wrap gap1" cellSpacing="0">
            <thead>
              <tr>
                <th>Supplement</th>
                <th>Instructions</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
            {appointment.data.map((item: SupplementDetail) => (
                <tr key={item.id}>
                  <td>{item.supplement || item.product}</td>
                  <td>{item.directions}</td>
                  <td className="d-flex justify-content-center">
                    {item.right_side_ans && (
                      <button
                      type="button"
                      className="fmButton ms-2"
                      onClick={() => window.open(item.right_side_ans, '_blank')}
                    >
                      Buy
                    </button>
                    )}
                  </td>
                </tr>
              ))}
    </tbody>
  </table>
</div>
      )}
      
</>
  );
};

export default Personalizedsupp;
