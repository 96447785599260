import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import FooterCopyright from '../components/FooterCopyright';
import FormTemplate from '../components/template/FormTemplate';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  IUser,
  UpdateUserFormValues,
  UserPreferenceKeys,
} from '../types/models/User';
import { updateProfileAction } from '../redux/actions/user';
import { showErrorMessage, showSuccsesMessage } from '../utils/banner.utils';
import AccountSettingsForm from '../components/Organisms/AccountSettingsForm/AccountSettingsForm';
import { AppRoutes } from '../constants/routes';
import { formatUpdateUserParams } from '../utils/user.utils';

const AccountSettings = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(() => {
    return {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      companyName: user?.userAttributes?.companyName,
      jobTitle: user?.userAttributes?.jobTitle,
      Notification: user?.userPreferences?.find(
        (preference) => preference.key === UserPreferenceKeys.Notification,
      )?.value,
      PromotionalMessage: (user?.userPreferences || [])
        ?.filter(
          (preference) =>
            preference.key === UserPreferenceKeys.PromotionalMessage,
        )
        .map((preference) => preference.value),
    };
  }, [user]);

  const onSubmit = async (values: UpdateUserFormValues) => {
    try {
      setLoading(true);
      await dispatch(
        updateProfileAction({
          user: formatUpdateUserParams(values, user as IUser),
        }),
      ).unwrap();
    } catch (e) {
      console.log(e);
      showErrorMessage(e);
    }
    showSuccsesMessage('Profile updated successfully');
    setLoading(false);
  };

  const user_title = `${user?.firstName}'s Account Settings`;

  return (
    <div className="flex flex-col flex-item-center gap-half fill-page">
      <TopComp />
      <PageTitleComp pageTitle={user_title} />
      <br />

      <div
        id="block1"
        className="flex flex-col flex-item-center border-round border-thin padding1 width9050 text-center">
        <p className="h2">Your password</p>
        <button
          type="button"
          className="fmButton"
          onClick={() => navigate(AppRoutes.ChangePassword)}>
          Change Password
        </button>
      </div>

      <br />

      <div className="flex flex-col flex-item-center flex-space-around gap-half padding1 width9050">
        <p className="h2">Your details</p>
        <FormTemplate
          Component={AccountSettingsForm}
          onSubmit={onSubmit}
          loading={loading}
          initialValues={initialValues}
          email={user?.email}
        />
      </div>

      <FooterCopyright />
    </div>
  );
};

export default AccountSettings;
