import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { IUser, IUserAttributes } from '../../types/models/User';
import {
  getCurrentUser,
  registerProgramStart,
  updateProfile,
} from '../../api/user';
import { YYYY_MM_DD } from '../../constants/global';
import { withToastForError } from '../../utils/thunk.utilts';
import { IOrder } from '../../types/models/Order';
import { getMyOrders } from '../../api/payment';

export const getCurrentUserAction = createAsyncThunk<IUser, void>(
  'user/me',
  withToastForError(async () => {
    const response = await getCurrentUser();
    return response.data;
  }),
);

export const registerProgramStartAction = createAsyncThunk<
  IUserAttributes,
  void
>(
  'user/register-program-start',
  withToastForError(async () => {
    const response = await registerProgramStart({
      programStartDate: moment().format(YYYY_MM_DD),
    });
    return response.data.userAttributes;
  }),
);

export const getMyOrdersAction = createAsyncThunk<IOrder[], void>(
  'user/my-orders',
  withToastForError(async () => {
    const response = await getMyOrders();
    return response.data;
  }),
);

export const updateProfileAction = createAsyncThunk<
  IUser,
  { user: Partial<IUser> }
>(
  'user/profile/update',
  withToastForError(async (params) => {
    const response = await updateProfile(params);
    return response.data.user;
  }),
);
