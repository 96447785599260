import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/femmar.css';

import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import BigPinkBtns from '../components/BigPinkBtns';
import MyPlanComp from '../components/MyPlanComp';
import LessonPlanModuleComp from '../components/LessonPlanModuleComp';

import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';

import myContent from '../jsonContent/trans_SixWk4.json';
import SixWk4Image from '../images/pictures/weekly/week4.jpg';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';

import {
  changeCurrentStep,
  completeWeekStep,
  selectCurrentStep,
} from '../redux/slices/visitedscreens';

const SixWk4 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(selectCurrentStep);

  const gotoScreen = (screen: string) => {
    dispatch(completeWeekStep({ step: currentStep }));
    dispatch(changeCurrentStep(screen));
    navigate(`/${screen}`);
  };
  const langNum = useAppSelector(selectLanguageIndex);
  const {
    pageTitle,
    menuItem1,
    menuItem2,
    menuItem3,
    menuItem4,
    menuItem5,
    menuItem6,
    menuItem7,
  } = myContent.trans[langNum];

  return (
    <div>
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-between gap1">
        <PageTitleComp pageTitle={pageTitle} />
        <BigPinkBtns />
        <MyPlanComp />
        <img className="theBigImage" src={SixWk4Image} alt="" />
        <div className="flex flex-col flex-item-center flex-space-around gap1">
          <LessonPlanModuleComp screen="SixWk4L1MLRBox" menuItem={menuItem1} />

          <LessonPlanModuleComp
            screen="SixWk4L2Supplements"
            menuItem={menuItem2}
          />

          <LessonPlanModuleComp screen="SixWk4L3Joylux" menuItem={menuItem3} />

          <LessonPlanModuleComp
            screen="SixWk4L4Exercise"
            menuItem={menuItem4}
          />

          <LessonPlanModuleComp
            screen="SixWk4L5Relaxation"
            menuItem={menuItem5}
          />

          <LessonPlanModuleComp
            screen="SixWk4L6Research"
            menuItem={menuItem6}
          />

          <LessonPlanModuleComp screen="SixWk4L7Quiz" menuItem={menuItem7} />
        </div>
        <FooterBtns />
        <FooterCopyright />
      </div>
    </div>
  );
};

export default SixWk4;
