import { AxiosPromise } from 'axios';
import { client } from './client';
import {
  PhytoFoodItem,
  PhytoFoodItemsPerDate,
  PhytoTrackerLogGetParams,
  PhytoTrackerLogPostParams,
} from '../types/models/PhytoTracker';

export const getPhytoFoodItems = (): AxiosPromise<{ items: PhytoFoodItem[] }> =>
  client.get('/phyto-tracker/items');

export const logPhytoFoodByDate = (
  params: PhytoTrackerLogPostParams,
): AxiosPromise<{ items: PhytoFoodItem[] }> =>
  client.post('/phyto-tracker/log', params);

export const getPhytoFoodByMonth = (
  params: PhytoTrackerLogGetParams,
): AxiosPromise<{ logs: PhytoFoodItemsPerDate[] }> =>
  client.get('/phyto-tracker/log', { params });
