import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import MultipleChoice from '../MultipleChoice/MultipleChoice';
import FieldError from '../FieldError/FieldError';
import { IInputOption } from '../../../types/FormFields';
import CheckboxInput from '../CheckboxInputGroup/CheckboxInputGroup';

export interface IFieldCheckboxInputGroup
  extends FieldRenderProps<(number | string)[]> {
  options: IInputOption[];
}

const FieldCheckboxInputGroup: React.FC<IFieldCheckboxInputGroup> = ({
  input,
  meta,
  compact,
  options,
}) => {
  const onChange = (value: number | string) => {
    let newValue = [];
    if (input.value.includes(value)) {
      newValue = input.value.filter((inputValue) => inputValue !== value);
    } else {
      newValue = [...input.value, value];
    }
    input.onChange(newValue);
  };
  return (
    <div>
      <CheckboxInput
        onChange={onChange}
        value={input.value}
        options={options}
      />
      <FieldError
        compact={compact}
        visible={!!(meta.touched && meta.error)}
        errorId={meta.error}
      />
    </div>
  );
};

export default FieldCheckboxInputGroup;
