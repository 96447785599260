import { AxiosPromise } from 'axios';
import { WeekSymptomAnswer } from '../types/models/QuestionModel';
import { client } from './client';
import { SaveResultDto } from '../types/models/ApiResult';

export const saveAnswers = (params: { result: SaveResultDto[] }) =>
  client.post('/result', params);

export const fetchAnswers = (params: {
  weekNumber?: number;
}): AxiosPromise<{ result: WeekSymptomAnswer[] }> =>
  client.get('/result', { params });
