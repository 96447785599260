import React from 'react';
import TopComp from '../components/TopComp';
import myContent from '../jsonContent/trans_nutrional.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import FooterCopyright from '../components/FooterCopyright';
import PageTitleComp from '../components/PageTitleComp';

const NutrionalFeedBackSucess = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <hr className="width9060" />

      <div className="mt-5 mb-5">
        <div className="mt-3 mb-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="150"
            fill="green"
            className="bi bi-check-circle-fill"
            viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
        </div>

        <p className="nutrional-feedback-sucess mt-5 ms-2"> Congratulations !! Your Feedback has been successfully submited. <br />Thank You</p>
      </div>
      <FooterCopyright />
    </div>
  );
};

export default NutrionalFeedBackSucess;
