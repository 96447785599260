import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/femmar.css';

import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import BigPinkBtns from '../components/BigPinkBtns';
import MyPlanComp from '../components/MyPlanComp';

import FooterBtns from '../components/FooterBtns';
import LessonPlanModuleComp from '../components/LessonPlanModuleComp';
import myContent from '../jsonContent/trans_SixWk1.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  changeCurrentStep,
  completeWeekStep,
  
} from '../redux/slices/visitedscreens';
import SixWk1Image from '../images/pictures/weekly/week1.jpg';

const SixWk1 = () => {


  const langNum = useAppSelector(selectLanguageIndex);
  const {
    pageTitle,
    menuItem1,
    menuItem2,
    menuItem3,
    menuItem4,
    menuItem5,
    menuItem6,
    menuItem7,
  } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <BigPinkBtns />
      <MyPlanComp />

      <img className="theBigImage" src={SixWk1Image} alt="" />

      <div className="flex flex-col flex-item-center flex-space-around gap1">
        <LessonPlanModuleComp
          screen="SixWk1L1MeetMaryon"
          menuItem={menuItem1}
/*           completed={completedSteps.includes('SixWk1L1MeetMaryon')} */
        />
        <LessonPlanModuleComp
          screen="SixWk1L2ProgressQuestionnaire"
          menuItem={menuItem2}
        />
        <LessonPlanModuleComp
          screen="SixWk1L3Supplements"
          menuItem={menuItem3}
        />
        <LessonPlanModuleComp screen="SixWk1L4Body" menuItem={menuItem4} />
        <LessonPlanModuleComp screen="SixWk1L5Symptoms" menuItem={menuItem5} />
        <LessonPlanModuleComp screen="SixWk1L6Research" menuItem={menuItem6} />
        <LessonPlanModuleComp screen="SixWk1L7Quiz" menuItem={menuItem7} />
      </div>

      <FooterBtns />
    </div>
  );
};

export default SixWk1;
