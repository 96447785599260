import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { logout } from '../redux/slices/auth';
import { AppRoutes } from '../constants/routes';
import { getCurrentUserAction } from '../redux/actions/user';
import { useSecureWeekProgress } from '../hooks/useSecureWeekProgress';
import { useCheckPayment } from '../hooks/useCheckPayment';
import { getProgramsAction } from '../redux/actions/sixweekprograms';

const ProtectedLayout: React.FC = () => {
  const user = useAppSelector(({ auth }) => auth.user);
  const dispatch = useAppDispatch();
  // useSecureWeekProgress();
  useCheckPayment();

  useEffect(() => {
    dispatch(getCurrentUserAction());
  }, []);

  useEffect(() => {
    dispatch(getProgramsAction());
  }, []);

  if (!user) {
    dispatch(logout());
    return <Navigate to={AppRoutes.LoginB2C} replace />;
  }

  return <Outlet />;
};

export default ProtectedLayout;
