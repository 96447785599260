import React from 'react';
import { Navigate, Outlet } from 'react-router';
import { useAppSelector } from '../redux/store';
import { UserRoles } from '../types/models/User';
import { AppRoutes } from '../constants/routes';
import Header from '../components/admin/layout/Header';
import Sidebar from '../components/admin/layout/Sidebar';
import Content from '../components/admin/layout/Content';
import Footer from '../components/admin/layout/Footer';
import '../assets/admin/admin.scss';
import '../assets/admin/style.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simple-datatables/dist/style.css';

const AdminLayout = () => {
  const user = useAppSelector((state) => state.auth.user);

  if (user?.userAttributes?.userRole !== UserRoles.Admin) {
    return <Navigate to={AppRoutes.DashboardMain} replace />;
  }

  return (
    <>
      <Header />
      <Sidebar />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </>
  );
};

export default AdminLayout;
