import React from 'react';

const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer">
        <div className="copyright">
          &copy; Copyright{' '}
          <strong>
            <span>Femmar</span>
          </strong>
          . All Rights Reserved
        </div>
      </footer>

      <a
        href="#"
        aria-label="icon"
        className="back-to-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short" />
      </a>
    </>
  );
};

export default Footer;
