import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { WeightUnits, WeightValue } from '../../../types/types';
import FieldError from '../FieldError/FieldError';
import ToggleButtons from '../ToggleButtons/ToggleButtons';

const FieldWeight: React.FC<FieldRenderProps<WeightValue>> = ({
  input,
  meta,
  compact,
  ...rest
}) => {
  const onChange = (value: string) => {
    if (Number.isNaN(value)) {
      return;
    }
    input.onChange({ ...input.value, weight: value });
  };
  return (
    <div className="flex-item-center flex flex-col">
      <div className="flex flex-row">
        <div className="marginR1">
          <div className="flex flex-row">
            <input
              type="number"
              value={input.value?.weight || ''}
              placeholder="Weight"
              onChange={(event) => onChange(event.target.value)}
              {...rest}
              className="border-thin border-round padding02 width5R text-center"
            />
          </div>
        </div>
        <ToggleButtons
          buttons={[
            { title: 'KG', id: WeightUnits.KG },
            { title: 'LBS', id: WeightUnits.LBS },
          ]}
          onChange={(btn) => input.onChange({ ...input.value, unit: btn.id })}
          selected={input.value.unit}
        />
      </div>
      <FieldError
        compact={compact}
        visible={!!(meta.touched && meta.error)}
        errorId={meta.error}
      />
    </div>
  );
};

export default FieldWeight;
