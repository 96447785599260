import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';
import myContent from '../jsonContent/trans_CourseCurriculum.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';

const CourseCurriculum = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const {
    pageTitle,
    pageText,

    W0,
    W1,
    W2,
    W3,
    W4,
    W5,
    W6,

    W0A,
    W0B,
    W0C,
    W0D,
    W0E,
    W0F,
    W0G,
    W0H,
    W0I,
    W0J,
    W0K,
    W0L,
    W0M,
    W0N,
    W0O,
    W0P,

    W1A,
    W1B,
    W1C,
    W1D,
    W1E,
    W1F,
    W1G,

    W1E1,
    W1E2,
    W1E3,
    W1E4,
    W1E5,
    W1E6,
    W1E7,
    W1E8,

    W2A,
    W2B,
    W2C,
    W2D,
    W2E,

    W3A,
    W3B,
    W3C,
    W3D,
    W3E,
    W3F,
    W3G,
    W3H,

    W4A,
    W4B,
    W4C,
    W4D,
    W4E,
    W4F,
    W4G,

    W5A,
    W5B,
    W5C,
    W5D,
    W5E,
    W5F,
    W5G,
    W5H,

    W6A,
    W6B,
    W6C,
    W6D,
    W6E,
    W6F,
    W6G,
    W6H,
    W6I,
  } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-betWeen gap1">
      <TopComp />

      <p className="h1">{pageTitle}</p>
      <div className="page-text">{parse(pageText)}</div>

      <ul>
        <li>{W0}</li>
        <ul>
          <li>{W0A}</li>
          <li>{W0B}</li>
          <li>{W0C}</li>
          <li>{W0D}</li>
          <li>{W0E}</li>
          <li>{W0F}</li>
          <li>{W0G}</li>
          <li>{W0H}</li>
          <li>{W0I}</li>
          <li>{W0J}</li>
          <li>{W0K}</li>
          <li>{W0L}</li>
          <li>{W0M}</li>
          <li>{W0N}</li>
          <li>{W0O}</li>
          <li>{W0P}</li>
        </ul>

        <li>{W1}</li>
        <ul>
          <li>{W1A}</li>
          <li>{W1B}</li>
          <li>{W1C}</li>
          <li>{W1D}</li>
          <li>{W1E}</li>

          <ul>
            <li>{W1E1}</li>
            <li>{W1E2}</li>
            <li>{W1E3}</li>
            <li>{W1E4}</li>
            <li>{W1E5}</li>
            <li>{W1E6}</li>
            <li>{W1E7}</li>
            <li>{W1E8}</li>
          </ul>

          <li>{W1F}</li>
          <li>{W1G}</li>
        </ul>

        <li>{W2}</li>
        <ul>
          <li>{W2A}</li>
          <li>{W2B}</li>
          <li>{W2C}</li>
          <li>{W2D}</li>
          <li>{W2E}</li>
        </ul>

        <li>{W3}</li>
        <ul>
          <li>{W3A}</li>
          <li>{W3B}</li>
          <li>{W3C}</li>
          <li>{W3D}</li>
          <li>{W3E}</li>
          <li>{W3F}</li>
          <li>{W3G}</li>
          <li>{W3H}</li>
        </ul>

        <li>{W4}</li>
        <ul>
          <li>{W4A}</li>
          <li>{W4B}</li>
          <li>{W4C}</li>
          <li>{W4D}</li>
          <li>{W4E}</li>
          <li>{W4F}</li>
          <li>{W4G}</li>
        </ul>

        <li>{W5}</li>
        <ul>
          <li>{W5A}</li>
          <li>{W5B}</li>
          <li>{W5C}</li>
          <li>{W5D}</li>
          <li>{W5E}</li>
          <li>{W5F}</li>
          <li>{W5G}</li>
          <li>{W5H}</li>
        </ul>

        <li>{W6}</li>
        <ul>
          <li>{W6A}</li>
          <li>{W6B}</li>
          <li>{W6C}</li>
          <li>{W6D}</li>
          <li>{W6E}</li>
          <li>{W6F}</li>
          <li>{W6G}</li>
          <li>{W6H}</li>
          <li>{W6I}</li>
        </ul>
      </ul>

      <FooterCopyright />
    </div>
  );
};

export default CourseCurriculum;
