import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.min.css';
/* import './App.css'; */

import { fromPairs } from 'lodash';
/* import Home from './screensPortal/Home'; */

// login/registration
import LoginB2C from './screensPortal/LoginB2C';
import Register0 from './screensPortal/Register0';
import Register1 from './screensPortal/Register1';
import Register2 from './screensPortal/Register2';

// portal screens - these come between login and dashboard
import Welcome from './screensPortal/Welcome';
import HistoryQuestionnaireIntro from './screensPortal/HistoryQuestionnaireIntro';
import HistoryQuestionnaire from './screensPortal/HistoryQuestionnaire';
import DashboardTour from './screensPortal/DashboardTour';
import DashboardMain from './screensPortal/DashboardMain';
import SupplementInfo from './screensOptionals/SupplementInfo';

// optional screens - these are accessible any time from the topcomp or hamburger menu or bigpinkbtns or dashboard or myPlanComp
import SixWeekMenu from './screensSixWk/SixWeekMenu';

import Faqs from './screensFaqs/Faqs';
import Faqs01 from './screensFaqs/Faqs01';
import Faqs02 from './screensFaqs/Faqs02';
import Faqs03 from './screensFaqs/Faqs03';
import Faqs04 from './screensFaqs/Faqs04';
import Faqs05 from './screensFaqs/Faqs05';
import Faqs06 from './screensFaqs/Faqs06';
import Faqs07 from './screensFaqs/Faqs07';
import Faqs08 from './screensFaqs/Faqs08';
import Faqs09 from './screensFaqs/Faqs09';
import Faqs10 from './screensFaqs/Faqs10';
import Faqs11 from './screensFaqs/Faqs11';
import Faqs12 from './screensFaqs/Faqs12';
import Faqs13 from './screensFaqs/Faqs13';
import Faqs14 from './screensFaqs/Faqs14';
import Faqs15 from './screensFaqs/Faqs15';
import Faqs16 from './screensFaqs/Faqs16';
import Faqs17 from './screensFaqs/Faqs17';

/* optionals */
import AccountSettings from './screensOptionals/AccountSettings';
import Consult from './screensOptionals/Consult';
import Contact from './screensOptionals/Contact';
import ContactConfirm from './screensOptionals/ContactConfirm';
import CourseCurriculum from './screensOptionals/CourseCurriculum';
import CourseProgress from './screensOptionals/CourseProgress';
import MealPlans from './screensOptionals/MealPlans';
import NutritionalAssessment from './screensOptionals/NutritionalAssessment';
import PhytoTracker from './screensOptionals/PhytoTracker';
import Privacy from './screensOptionals/Privacy';
import Recipes from './screensOptionals/Recipes';
import Resources from './screensOptionals/Resources';
import Search from './screensOptionals/Search';
import Shop from './screensOptionals/Shop';
import SymptomReport from './screensOptionals/SymptomReport';
import Terms from './screensOptionals/Terms';

// course curriculum
import SixWk0 from './screensSixWk/SixWk0';
import SixWk0L01Expectations from './screensSixWk/SixWk0L01Expectations';
import SixWk0L02Behavior from './screensSixWk/SixWk0L02Behavior';
import SixWk0L03Preparation from './screensSixWk/SixWk0L03Preparation';
import SixWk0L04Fruit from './screensSixWk/SixWk0L04Fruit';
import SixWk0L05Fatty from './screensSixWk/SixWk0L05Fatty';
import SixWk0L06Wheat from './screensSixWk/SixWk0L06Wheat';
import SixWk0L07Caffeine from './screensSixWk/SixWk0L07Caffeine';
import SixWk0L08Curb from './screensSixWk/SixWk0L08Curb';
import SixWk0L09Hydrated from './screensSixWk/SixWk0L09Hydrated';
import SixWk0L10Alcohol from './screensSixWk/SixWk0L10Alcohol';
import SixWk0L11Moving from './screensSixWk/SixWk0L11Moving';
import SixWk0L12Rest from './screensSixWk/SixWk0L12Rest';
import SixWk0L13Phyto from './screensSixWk/SixWk0L13Phyto';
import SixWk0L14Flaxseeds from './screensSixWk/SixWk0L14Flaxseeds';
import SixWk0L15Meals from './screensSixWk/SixWk0L15Meals';
import SixWk0L16Portion from './screensSixWk/SixWk0L16Portion';

import SixWk1 from './screensSixWk/SixWk1';
import SixWk1L1MeetMaryon from './screensSixWk/SixWk1L1MeetMaryon';
import SixWk1L2ProgressQuestionnaire from './screensSixWk/SixWk1L2ProgressQuestionnaire';
import SixWk1L3Supplements from './screensSixWk/SixWk1L3Supplements';
import SixWk1L4Body from './screensSixWk/SixWk1L4Body';
import SixWk1L5Symptoms from './screensSixWk/SixWk1L5Symptoms';
import SixWk1L5SympAching from './screensSixWk/SixWk1L5SympAching';
import SixWk1L5SympDry from './screensSixWk/SixWk1L5SympDry';
import SixWk1L5SympHeadaches from './screensSixWk/SixWk1L5SympHeadaches';
import SixWk1L5SympHot from './screensSixWk/SixWk1L5SympHot';
import SixWk1L5SympInsomnia from './screensSixWk/SixWk1L5SympInsomnia';
import SixWk1L5SympMood from './screensSixWk/SixWk1L5SympMood';
import SixWk1L5SympPainful from './screensSixWk/SixWk1L5SympPainful';
import SixWk1L5SympPanic from './screensSixWk/SixWk1L5SympPanic';
import SixWk1L6Research from './screensSixWk/SixWk1L6Research';
import SixWk1L7Quiz from './screensSixWk/SixWk1L7Quiz';

import SixWk2 from './screensSixWk/SixWk2';
import SixWk2L1GoodShape from './screensSixWk/SixWk2L1GoodShape';
import SixWk2L2Robbers from './screensSixWk/SixWk2L2Robbers';
import SixWk2L3RightFoods from './screensSixWk/SixWk2L3RightFoods';
import SixWk2L4Research from './screensSixWk/SixWk2L4Research';
import SixWk2L5Quiz from './screensSixWk/SixWk2L5Quiz';

import SixWk3 from './screensSixWk/SixWk3';
import SixWk3L1ProgressQuestionnaire from './screensSixWk/SixWk3L1ProgressQuestionnaire';
import SixWk3L2WhatPhyto from './screensSixWk/SixWk3L2WhatPhyto';
import SixWk3L3PhytoDiet from './screensSixWk/SixWk3L3PhytoDiet';
import SixWk3L4Sugar from './screensSixWk/SixWk3L4Sugar';
import SixWk3L5Bloat from './screensSixWk/SixWk3L5Bloat';
import SixWk3L6Constipation from './screensSixWk/SixWk3L6Constipation';
import SixWk3L7Research from './screensSixWk/SixWk3L7Research';
import SixWk3L8Quiz from './screensSixWk/SixWk3L8Quiz';

import SixWk4 from './screensSixWk/SixWk4';
import SixWk4L1MLRBox from './screensSixWk/SixWk4L1MLRBox';
import SixWk4L2Supplements from './screensSixWk/SixWk4L2Supplements';
import SixWk4L3Joylux from './screensSixWk/SixWk4L3Joylux';
import SixWk4L4Exercise from './screensSixWk/SixWk4L4Exercise';
import SixWk4L5Relaxation from './screensSixWk/SixWk4L5Relaxation';
import SixWk4L6Research from './screensSixWk/SixWk4L6Research';
import SixWk4L7Quiz from './screensSixWk/SixWk4L7Quiz';

import SixWk5 from './screensSixWk/SixWk5';
import SixWk5L1ProgressQuestionnaire from './screensSixWk/SixWk5L1ProgressQuestionnaire';
import SixWk5L2SexDry from './screensSixWk/SixWk5L2SexDry';
import SixWk5L3SexLack from './screensSixWk/SixWk5L3SexLack';
import SixWk5L4Stress from './screensSixWk/SixWk5L4Stress';
import SixWk5L5Anxiety from './screensSixWk/SixWk5L5Anxiety';
import SixWk5L6Sleep from './screensSixWk/SixWk5L6Sleep';
import SixWk5L7Research from './screensSixWk/SixWk5L7Research';
import SixWk5L8Quiz from './screensSixWk/SixWk5L8Quiz';

import SixWk6 from './screensSixWk/SixWk6';
import SixWk6L1Sharp from './screensSixWk/SixWk6L1Sharp';
import SixWk6L2Hair from './screensSixWk/SixWk6L2Hair';
import SixWk6L3Positivity from './screensSixWk/SixWk6L3Positivity';
import SixWk6L4BoneHealth from './screensSixWk/SixWk6L4BoneHealth';
import SixWk6L5Plan from './screensSixWk/SixWk6L5Plan';
import SixWk6L6Next from './screensSixWk/SixWk6L6Next';
import SixWk6L7Research from './screensSixWk/SixWk6L7Research';
import SixWk6L8Quiz from './screensSixWk/SixWk6L8Quiz';
import SixWk6L9ProgressQuestionnaire from './screensSixWk/SixWk6L9ProgressQuestionnaire';
import SixWk6L10Congrats from './screensSixWk/SixWk6L10Congrats';

import ManagerMenu from './manager/ManagerMenu';
import ProtectedLayout from './Container/ProtectedLayout';
import { AdminRoutes, AppRoutes } from './constants/routes';
import { useAppSelector } from './redux/store';
import { setAuthenticationToken } from './api/client';
import UnprotectedLayout from './Container/UnprotectedLayout';
import EmailVerify from './screensPortal/EmailVerify';
import AdminLayout from './Container/AdminLayout';
import Users from './Screens/admin/Users';
import Dashboard from './Screens/admin/Dashboard';
import { ADMIN_URL_PREFIX } from './constants/global';
import Payment from './screensPortal/Payment';
import PaymentComplete from './screensPortal/PaymentComplete';
import ForgetPassword from './screensPortal/ForgetPassword';
import ResetPassword from './screensPortal/ResetPassword';
import ChangePassword from './screensOptionals/ChangePassword';
import DayOneForm from './screensOptionals/DayOneForm';
import DayTwoForm from './screensOptionals/DayTwoForm';
import DayThreeForm from './screensOptionals/DayThreeForm';
import BookConsultant from './screensPortal/BookConsutant';
import BookingCompleted from './screensPortal/BookConsultantSuccess';
import NutrionalDashTable from './screensOptionals/NutrionalDashTable';
import NutrionalDashInfo from './screensOptionals/NutrionalDashInfo';
import NutrionalDashConsultation from './screensOptionals/NutrionalDashConsultation';
import Nutritionprogrecom from './screensOptionals/Nutritionprogrecom';
import RecommendedPlan from './screensOptionals/RecommendedPlan';
import Nutriotionalfeedbackform from './screensOptionals/Nutriotionalfeedbackform';
import NutrionalFeedBackSucess from './screensOptionals/NutrionalFeedBackSucess';
import FaqDetail from './screensFaqs/FaqDetail';
import MyAppointments from './screensOptionals/MyAppointments';
import ActionDetails from './screensOptionals/ActionDetails';
import Actiondetaildesc from './screensOptionals/Actiondetaildesc';

const Navigator: React.FC = () => {
  const [settled, setSettled] = useState(false);
  const user = useAppSelector(({ auth }) => auth.user);
  useEffect(() => {
    setAuthenticationToken(user?.token);
    setSettled(true);
  }, [user]);

  if (!settled) {
    return null;
  }
  return (
    <Router>
      <Routes>
        <Route element={<UnprotectedLayout />}>
          <Route index path={AppRoutes.LoginB2C} element={<LoginB2C />} />

          {/*        <Route path={AppRoutes.LoginB2C} element={<LoginB2C />} /> */}
          <Route path={AppRoutes.Register0} element={<Register0 />} />
          <Route path={AppRoutes.Register1} element={<Register1 />} />
          <Route path={AppRoutes.Register2} element={<Register2 />} />
          <Route path={AppRoutes.EmailVerify} element={<EmailVerify />} />
          <Route path={AppRoutes.ForgotPassword} element={<ForgetPassword />} />
          <Route path={AppRoutes.ResetPassword} element={<ResetPassword />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path={AppRoutes.Payment} element={<Payment />} />
          <Route
            path={AppRoutes.PaymentComplete}
            element={<PaymentComplete />}
          />
          <Route path={`${ADMIN_URL_PREFIX}/*`} element={<AdminLayout />}>
            <Route path={AdminRoutes.Dashboard} element={<Dashboard />} />
            <Route path={AdminRoutes.Users} element={<Users />} />
          </Route>
          {/*       <Route path={AppRoutes.Home} element={<SixWeekMenu />} /> */}
          <Route path={AppRoutes.SixWeekMenu} element={<SixWeekMenu />} />
          <Route path={AppRoutes.SixWk0} element={<SixWk0 />} />
          <Route
            path={AppRoutes.SixWk0L01Expectations}
            element={<SixWk0L01Expectations />}
          />
          <Route
            path={AppRoutes.SixWk0L02Behavior}
            element={<SixWk0L02Behavior />}
          />
          <Route
            path={AppRoutes.SixWk0L03Preparation}
            element={<SixWk0L03Preparation />}
          />
          <Route path={AppRoutes.SixWk0L04Fruit} element={<SixWk0L04Fruit />} />
          <Route path={AppRoutes.SixWk0L05Fatty} element={<SixWk0L05Fatty />} />
          <Route path={AppRoutes.SixWk0L06Wheat} element={<SixWk0L06Wheat />} />
          <Route
            path={AppRoutes.SixWk0L07Caffeine}
            element={<SixWk0L07Caffeine />}
          />
          <Route path={AppRoutes.SixWk0L08Curb} element={<SixWk0L08Curb />} />
          <Route
            path={AppRoutes.SixWk0L09Hydrated}
            element={<SixWk0L09Hydrated />}
          />
          <Route
            path={AppRoutes.SixWk0L10Alcohol}
            element={<SixWk0L10Alcohol />}
          />
          <Route
            path={AppRoutes.SixWk0L11Moving}
            element={<SixWk0L11Moving />}
          />
          <Route path={AppRoutes.SixWk0L12Rest} element={<SixWk0L12Rest />} />
          <Route path={AppRoutes.SixWk0L13Phyto} element={<SixWk0L13Phyto />} />
          <Route
            path={AppRoutes.SixWk0L14Flaxseeds}
            element={<SixWk0L14Flaxseeds />}
          />
          <Route path={AppRoutes.SixWk0L15Meals} element={<SixWk0L15Meals />} />
          <Route
            path={AppRoutes.SixWk0L16Portion}
            element={<SixWk0L16Portion />}
          />
          <Route path={AppRoutes.SixWk1} element={<SixWk1 />} />
          <Route
            path={AppRoutes.SixWk1L1MeetMaryon}
            element={<SixWk1L1MeetMaryon />}
          />
          <Route
            path={AppRoutes.SixWk1L2ProgressQuestionnaire}
            element={<SixWk1L2ProgressQuestionnaire />}
          />
          <Route
            path={AppRoutes.SixWk1L3Supplements}
            element={<SixWk1L3Supplements />}
          />
          <Route path={AppRoutes.SixWk1L4Body} element={<SixWk1L4Body />} />
          <Route
            path={AppRoutes.SixWk1L5Symptoms}
            element={<SixWk1L5Symptoms />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympAching}
            element={<SixWk1L5SympAching />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympDry}
            element={<SixWk1L5SympDry />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympHeadaches}
            element={<SixWk1L5SympHeadaches />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympHot}
            element={<SixWk1L5SympHot />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympInsomnia}
            element={<SixWk1L5SympInsomnia />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympMood}
            element={<SixWk1L5SympMood />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympPainful}
            element={<SixWk1L5SympPainful />}
          />
          <Route
            path={AppRoutes.SixWk1L2ProgressQuestionnaire}
            element={<SixWk1L2ProgressQuestionnaire />}
          />
          <Route
            path={AppRoutes.SixWk1L6Research}
            element={<SixWk1L6Research />}
          />
          <Route path={AppRoutes.SixWk1L7Quiz} element={<SixWk1L7Quiz />} />
          <Route
            path={AppRoutes.SixWk1L5Symptoms}
            element={<SixWk1L5Symptoms />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympAching}
            element={<SixWk1L5SympAching />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympDry}
            element={<SixWk1L5SympDry />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympHeadaches}
            element={<SixWk1L5SympHeadaches />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympHot}
            element={<SixWk1L5SympHot />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympInsomnia}
            element={<SixWk1L5SympInsomnia />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympMood}
            element={<SixWk1L5SympMood />}
          />
          <Route
            path={AppRoutes.SixWk1L5SympPanic}
            element={<SixWk1L5SympPanic />}
          />
          <Route path={AppRoutes.SixWk2} element={<SixWk2 />} />
          <Route
            path={AppRoutes.SixWk2L1GoodShape}
            element={<SixWk2L1GoodShape />}
          />
          <Route
            path={AppRoutes.SixWk2L2Robbers}
            element={<SixWk2L2Robbers />}
          />
          <Route
            path={AppRoutes.SixWk2L3RightFoods}
            element={<SixWk2L3RightFoods />}
          />
          <Route
            path={AppRoutes.SixWk2L4Research}
            element={<SixWk2L4Research />}
          />
          <Route path={AppRoutes.SixWk2L5Quiz} element={<SixWk2L5Quiz />} />
          <Route path={AppRoutes.SixWk3} element={<SixWk3 />} />
          <Route
            path={AppRoutes.SixWk3L1ProgressQuestionnaire}
            element={<SixWk3L1ProgressQuestionnaire />}
          />
          <Route
            path={AppRoutes.SixWk3L2WhatPhyto}
            element={<SixWk3L2WhatPhyto />}
          />
          <Route
            path={AppRoutes.SixWk3L3PhytoDiet}
            element={<SixWk3L3PhytoDiet />}
          />
          <Route path={AppRoutes.SixWk3L4Sugar} element={<SixWk3L4Sugar />} />
          <Route path={AppRoutes.SixWk3L5Bloat} element={<SixWk3L5Bloat />} />
          <Route
            path={AppRoutes.SixWk3L6Constipation}
            element={<SixWk3L6Constipation />}
          />
          <Route
            path={AppRoutes.SixWk3L7Research}
            element={<SixWk3L7Research />}
          />
          <Route path={AppRoutes.SixWk3L8Quiz} element={<SixWk3L8Quiz />} />
          <Route path={AppRoutes.SixWk4} element={<SixWk4 />} />
          <Route path={AppRoutes.SixWk4L1MLRBox} element={<SixWk4L1MLRBox />} />
          <Route
            path={AppRoutes.SixWk4L2Supplements}
            element={<SixWk4L2Supplements />}
          />
          <Route path={AppRoutes.SixWk4L3Joylux} element={<SixWk4L3Joylux />} />
          <Route
            path={AppRoutes.SixWk4L4Exercise}
            element={<SixWk4L4Exercise />}
          />
          <Route
            path={AppRoutes.SixWk4L5Relaxation}
            element={<SixWk4L5Relaxation />}
          />
          <Route
            path={AppRoutes.SixWk4L6Research}
            element={<SixWk4L6Research />}
          />
          <Route path={AppRoutes.SixWk4L7Quiz} element={<SixWk4L7Quiz />} />
          <Route path={AppRoutes.SixWk5} element={<SixWk5 />} />
          <Route
            path={AppRoutes.SixWk5L1ProgressQuestionnaire}
            element={<SixWk5L1ProgressQuestionnaire />}
          />
          <Route path={AppRoutes.SixWk5L2SexDry} element={<SixWk5L2SexDry />} />
          <Route
            path={AppRoutes.SixWk5L3SexLack}
            element={<SixWk5L3SexLack />}
          />
          <Route path={AppRoutes.SixWk5L4Stress} element={<SixWk5L4Stress />} />
          <Route
            path={AppRoutes.SixWk5L5Anxiety}
            element={<SixWk5L5Anxiety />}
          />
          <Route path={AppRoutes.SixWk5L6Sleep} element={<SixWk5L6Sleep />} />
          <Route
            path={AppRoutes.SixWk5L7Research}
            element={<SixWk5L7Research />}
          />
          <Route path={AppRoutes.SixWk5L8Quiz} element={<SixWk5L8Quiz />} />
          <Route path={AppRoutes.SixWk6} element={<SixWk6 />} />
          <Route path={AppRoutes.SixWk6L1Sharp} element={<SixWk6L1Sharp />} />
          <Route path={AppRoutes.SixWk6L2Hair} element={<SixWk6L2Hair />} />
          <Route
            path={AppRoutes.SixWk6L3Positivity}
            element={<SixWk6L3Positivity />}
          />
          <Route
            path={AppRoutes.SixWk6L4BoneHealth}
            element={<SixWk6L4BoneHealth />}
          />
          <Route path={AppRoutes.SixWk6L5Plan} element={<SixWk6L5Plan />} />
          <Route path={AppRoutes.SixWk6L6Next} element={<SixWk6L6Next />} />
          <Route
            path={AppRoutes.SixWk6L7Research}
            element={<SixWk6L7Research />}
          />
          <Route path={AppRoutes.SixWk6L8Quiz} element={<SixWk6L8Quiz />} />
          <Route
            path={AppRoutes.SixWk6L9ProgressQuestionnaire}
            element={<SixWk6L9ProgressQuestionnaire />}
          />
          <Route
            path={AppRoutes.SixWk6L10Congrats}
            element={<SixWk6L10Congrats />}
          />
          <Route path={AppRoutes.ManagerMenu} element={<ManagerMenu />} />
          <Route path={AppRoutes.Welcome} element={<Welcome />} />
          <Route
            path={AppRoutes.HistoryQuestionnaireIntro}
            element={<HistoryQuestionnaireIntro />}
          />
          <Route
            path={AppRoutes.HistoryQuestionnaire}
            element={<HistoryQuestionnaire />}
          />
          <Route path={AppRoutes.DashboardTour} element={<DashboardTour />} />
          <Route path={AppRoutes.DashboardMain} element={<DashboardMain />} />
          <Route path={AppRoutes.SupplementInfo} element={<SupplementInfo />} />
          <Route
            path={AppRoutes.NutritionalAssessment}
            element={<NutritionalAssessment />}
          />
          <Route
            path={AppRoutes.CourseCurriculum}
            element={<CourseCurriculum />}
          />
          <Route path={AppRoutes.Actiondetail} element={<ActionDetails/>} />
          <Route path={AppRoutes.Actiondetaildesc} element={<Actiondetaildesc />} />
          <Route path={AppRoutes.Faqs} element={<Faqs />} />
          <Route path={AppRoutes.Faqdetail} element={<FaqDetail />} />
          <Route path={AppRoutes.Faqs01} element={<Faqs01 />} />
          <Route path={AppRoutes.Faqs02} element={<Faqs02 />} />
          <Route path={AppRoutes.Faqs03} element={<Faqs03 />} />
          <Route path={AppRoutes.Faqs04} element={<Faqs04 />} />
          <Route path={AppRoutes.Faqs05} element={<Faqs05 />} />
          <Route path={AppRoutes.Faqs06} element={<Faqs06 />} />
          <Route path={AppRoutes.Faqs07} element={<Faqs07 />} />
          <Route path={AppRoutes.Faqs08} element={<Faqs08 />} />
          <Route path={AppRoutes.Faqs09} element={<Faqs09 />} />
          <Route path={AppRoutes.Faqs10} element={<Faqs10 />} />
          <Route path={AppRoutes.Faqs11} element={<Faqs11 />} />
          <Route path={AppRoutes.Faqs12} element={<Faqs12 />} />
          <Route path={AppRoutes.Faqs13} element={<Faqs13 />} />
          <Route path={AppRoutes.Faqs14} element={<Faqs14 />} />
          <Route path={AppRoutes.Faqs15} element={<Faqs15 />} />
          <Route path={AppRoutes.Faqs16} element={<Faqs16 />} />
          <Route path={AppRoutes.Faqs17} element={<Faqs17 />} />
          <Route
            path={AppRoutes.AccountSettings}
            element={<AccountSettings />}
          />
          <Route path={AppRoutes.Consult} element={<Consult />} />
          <Route path={AppRoutes.DayOneForm} element={<DayOneForm />} />
          <Route path={AppRoutes.DayTwoForm} element={<DayTwoForm />} />
          <Route path={AppRoutes.DayThreeForm} element={<DayThreeForm />} />
          <Route path={AppRoutes.MyAppointments} element={<MyAppointments />} />
          {/* <Route path={AppRoutes. NutrionalDashTable} element={<NutrionalDashTable />} /> */}
          <Route path={AppRoutes.NutrionalDashInfo} element={<NutrionalDashInfo />} />
          <Route path={AppRoutes.NutrionalDashConsultation} element={<NutrionalDashConsultation />} />
          <Route path={AppRoutes.Nutritionprogrecom} element={<Nutritionprogrecom />} />

          <Route path={AppRoutes.Contact} element={<Contact />} />
          <Route path={AppRoutes.ContactConfirm} element={<ContactConfirm />} />
          <Route path={AppRoutes.CourseProgress} element={<CourseProgress />} />
          <Route path={AppRoutes.MealPlans} element={<MealPlans />} />
          <Route path={AppRoutes.PhytoTracker} element={<PhytoTracker />} />
          <Route path={AppRoutes.Recipes} element={<Recipes />} />
          <Route path={AppRoutes.Resources} element={<Resources />} />
          <Route path={AppRoutes.Search} element={<Search />} />
          <Route path={AppRoutes.Shop} element={<Shop />} />
          <Route path={AppRoutes.SymptomReport} element={<SymptomReport />} />
          <Route path={AppRoutes.ChangePassword} element={<ChangePassword />} />
        </Route>

        <Route path={AppRoutes.Privacy} element={<Privacy />} />
        <Route path={AppRoutes.Terms} element={<Terms />} />
        <Route path={AppRoutes.AppointmentPool} element={<BookConsultant />} />
        <Route path={AppRoutes.AppointmentSuccess} element={<BookingCompleted />} />
        <Route path={AppRoutes.Recommendations} element={<RecommendedPlan />} />
        <Route path={AppRoutes.Nutriotionalfeedbackform} element={<Nutriotionalfeedbackform />} />
        <Route path={AppRoutes.NutrionalFeedBackSucess} element={<NutrionalFeedBackSucess />} />
        <Route path="*" element={<p>There is nothing here: 404!</p>} />
      </Routes>
    </Router>
  );
};

export default Navigator;
