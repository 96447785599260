import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';

import TopComp from '../components/TopComp';



import myContent from '../jsonContent/trans_Recipes.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';

const Recipes = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText, btnDownload } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />

      <p className="h1">{pageTitle}</p>
      <div className="page-text">{parse(pageText)}</div>

      <a href="../pdfs/recipes.pdf" className="fmButton">
        {btnDownload}
      </a>

   
    </div>
  );
};

export default Recipes;
