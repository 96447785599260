import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk3L5Bloat.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import VideoComp from '../components/VideoComp';

const SixWk3L5Bloat = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText, text2, text3, text4, text5 } = myContent.trans[langNum];

  return (
    <div>
      <LessonPageHeaderComp pageTitle={pageTitle} />

      <div className="flex flex-col flex-item-center flex-space-around gap0">
        <p>&nbsp;</p>
      <VideoComp url="https://vimeo.com/855098330/0c75a58120?share=copy" />
      <div className="page-text">{parse(pageText)}</div>
      <div className="page-text">{parse(text2)}</div>
      <div className="page-text">{parse(text3)}</div>
      <div className="page-text">{parse(text4)}</div>     
      <div className="page-text">{parse(text5)}</div>
      <TickComp />
      <FooterBtns />
      <FooterCopyright />
      </div></div>
  );
};

export default SixWk3L5Bloat;
