import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import FieldError from '../FieldError/FieldError';
import { OnChangeEvent } from '../../../types/types';

export interface IFieldCheckbox extends FieldRenderProps<boolean> {
  className?: string;
  text?: string;
}

const FieldCheckbox: React.FC<IFieldCheckbox> = ({
  input,
  meta,
  compact,
  className,
  text,
}) => {
  const onChange: OnChangeEvent = () => {
    input.onChange(!input.value);
  };
  return (
    <div>
      <div className="flex flex-row gap1">
        <input
          type="checkbox"
          className={className}
          checked={input.value}
          onChange={onChange}
        />
        {!!text && <label className="text-left"> {text}</label>}
      </div>
      <FieldError
        compact={compact}
        visible={!!(meta.touched && meta.error)}
        errorId={meta.error}
      />
    </div>
  );
};

export default FieldCheckbox;
