import { showErrorMessage } from './banner.utils';

export function withToastForError<Args, Returned>(
  payloadCreator: (args: Args) => Promise<Returned>,
) {
  return async (args: Args) => {
    try {
      return await payloadCreator(args);
    } catch (err) {
      showErrorMessage(err);
      throw err; // throw error so createAsyncThunk will dispatch '/rejected'-action
    }
  };
}
