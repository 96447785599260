export interface IUser {
  user: any;
  PaymentStatus: boolean;
  courseStatus: boolean;
  role: string;
  id: number;
  createdAt?: Date;
  email: string;
  langNum: number;
  firstName: string;
  lastName: string;
  token?: string;
  isFirstTimeUser: boolean;
  updatedAt: Date;
  phoneNumber?: string;
  userAttributes?: IUserAttributes;
  userPreferences?: IUserPreferences[];
  
}

export interface IToken{
  token:string;
}

export interface IUserAttributes {
  programStartDate: string | null;
  userRole: UserRoles;
  id?: number;
  userId: number;
  companyName?: string;
  jobTitle?: string;
}

export interface IUserPreferences {
  id?: number;
  key: string;
  value: string;
}

export enum UserRoles {
  Admin = 'admin',
  Staff = 'staff',
  Freebies = 'freebies',
  General = 'general',
  Nutritionist='nutritionist',
  Vip='vip'
}

export interface IUserFormParams {
  firstName: string;
  lastName: string;
  email: string;
  userRole: UserRoles;
  id?: number;
}

export interface SaveUserParams extends Omit<IUserFormParams, 'userRole'> {
  userAttributes: Partial<IUserAttributes>;
}

export interface ChangePasswordParams {
  currentPassword: string;
  newPassword: string;
}

export interface ChangePasswordFormValues extends ChangePasswordParams {
  confirmNewPassword: string;
}

export enum UserPreferenceKeys {
  PromotionalMessage = 'PromotionalMessage',
  Notification = 'Notification',
}

export enum NotificationPreference {
  EMAIL = 'email',
  SMS = 'sms',
  BOTH = 'both',
  NONE = 'none',
}

export enum PromotionalMessage {
  FEMMAR = 'femmar',
  NEWSLETTER = 'newsletter',
  PARTNER = 'partner',
}

export interface UpdateUserFormValues {
  Notification: string;
  PromotionalMessage: string[];
  companyName: string;
  firstName: string;
  jobTitle: string;
  lastName: string;
  phoneNumber: string;
}
