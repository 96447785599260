import React, { useState, useEffect } from 'react';
import '../css/femmar.css';

import { useNavigate,useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import myContent from '../jsonContent/trans_nutrional.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import FooterCopyright from '../components/FooterCopyright';
import FormProgressBar from '../components/FormProgressBar';
import DayOneFormSchema from '../schema/dayOneSchema';
import { Question } from '../types/models/QuestionTypes';
import { client, directusClient } from '../api/client';

type InitialValues = Record<string, string>;

const DayOneForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const user = useAppSelector((state) => state.auth.user);

  const totalSteps = 3;

  const navigate = useNavigate();
  const langNum = useAppSelector(selectLanguageIndex);
  const pageTitle = "Pre Consultation";

  const [questions, setQuestions] = React.useState<Question[]>([]);
  const [questionone, setQuestionone] = React.useState<Question[]>([]);

  const [schema, setSchema] = useState(DayOneFormSchema as any);
  const [initialValues, setInitialValues] = useState<InitialValues>({});
  
  const [quizId, setQuizId] = useState(0);

  const [loading, setLoading] = useState(true);

  const [isCompleted, setIsCompleted] = useState(false);
  const [answerValues, setAnswerValues] = useState<{ [key: number]: string }>({});

  const { appointmentId, orderId } = useParams();
  
  const appointmentIdAsNumber = Number(appointmentId);
  const orderIdAsNumber = orderId === 'null' || orderId === 'undefined' || orderId === undefined || orderId === null ? 0 : Number(orderId);
  
  useEffect(() => {
    const fetchAppointmentDetails = async () => {
      try {
        // const currentAppointmentId = localStorage.getItem('currentAppointmentId');
        
        if (!appointmentId) {
          setInitialValues({});
          setLoading(false);
          // console.log('Error: appointmentId not found');
          return;
        }
        
        const response = await client.get(`nutritionist/appointment-details?id=${appointmentId}`);
        // console.log('resp',response.data.data);
        const {dietDiaryData} = response.data.data;
        const Iscompleted = response.data.data.appointment.Is_completed;
        // console.log('is',Iscompleted);


        
        // Create a map of question IDs to answer values
        const answerMap: InitialValues = {};
        dietDiaryData.forEach((item: any) => {
          answerMap[item.questionId] = item.answer;
        });
        
        // Update the answerValues state
        setAnswerValues(answerMap);
        setIsCompleted(Iscompleted);
      } catch (error) {
        console.error('Error fetching appointment details:', error);
      }
    };
  
    fetchAppointmentDetails();
  }, []);
  

 
  
  useEffect(() => {

    const fetQuiz = async () => {
      return directusClient.get(
        '/items/Quizzes?filter[type][_eq]=Diet Diary Day 1',
      );
    }

    const fetchData = async (id: number) => {
      try {
        const response = await directusClient.get(
          `/items/Questions?filter[quizId][_eq]=${id}`,
        );
        setQuestions(response.data.data);
        const responseone = await directusClient.get(
          `/items/Questions?filter[quizId][_eq]=17 `,
        );
        setQuestionone(responseone.data.data);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetQuiz().then(res => {
      setQuizId(res.data.data[0].id);
      fetchData(res.data.data[0].id);
    });
  }, []);

  
  useEffect(() => {
    if ((questions && questions.length > 0) && (questionone && questionone.length > 0)) {
      const allQuestions = [...questions, ...questionone];
      const initialValueData: any = {};
      const schemaData: any = {};
      allQuestions.forEach((question) => {
        const questionId = question.id;
        const preFilledValue = answerValues[questionId] || '';
        initialValueData[`question_${questionId}`] = preFilledValue;
        // Set the schema as required for all fields
        schemaData[`question_${questionId}`] = Yup.string().required("This field is required");
      });
  
      const combinedSchema = Yup.object().shape(schemaData);
  
      setSchema(combinedSchema);
      setInitialValues(initialValueData);
      setLoading(false);
    }
  }, [questionone, questions, answerValues]);
 

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <FormProgressBar steps={totalSteps} currentStep={currentStep} />
      <p className="day-one-heading">{user?.firstName}’s Pre-consultation questions</p>

      

      {
        loading ? <p>Please wait ...</p>
          :
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize
            onSubmit={async (values: any, { setSubmitting }: any) => {
              try {
                if (isCompleted) {
                  navigate(`/DayTwoForm/${appointmentId}`);
                } else {
                  // const currentAppointmentId = localStorage.getItem('currentAppointmentId');
            
                  // Handle the case when currentAppointmentId is null
                  // eslint-disable-next-line no-restricted-globals
                  if (!appointmentIdAsNumber) {
                    // console.log('Error: appointmentId not found');
            
                    // First API request without appointmentId
                    const firstPayload = {
                      quizId: 17,
                      answers: questionone.map(question => ({
                        questionId: question.id,
                        value: values[`question_${question.id}`],
                      })),
                    };
            
                    await client.post('/result/save-diet-diary', firstPayload)
                      .then(async (res) => {
                        if (res.status === 200) {
                          // Second API request without appointmentId
                          const secondPayload = {
                            quizId,
                            answers: questions.map(question => ({
                              questionId: question.id,
                              value: values[`question_${question.id}`],
                            })),
                          };
            
                          await client.post('/result/save-diet-diary', secondPayload);
                        }
                      });
                      navigate(`/DayTwoForm`);
                  } else {
                    
                    // Existing code for when appointmentId is not null
                    const firstPayload = {
                      quizId: 17,
                      appointmentId,
                      orderId: orderIdAsNumber ,
                      answers: questionone.map(question => ({
                        questionId: question.id,
                        value: values[`question_${question.id}`],
                      })),
                    };
                    console.log('first Payload:', firstPayload);
                    await client.post('/result/save-diet-diary', firstPayload)
                      .then(async (res) => {
                        if (res.status === 200) {
                          const secondPayload = {
                            quizId,
                            appointmentId,
                            orderId: orderIdAsNumber ,
                            answers: questions.map(question => ({
                              questionId: question.id,
                              value: values[`question_${question.id}`],
                            })),
                          };
                          console.log('Second Payload:', secondPayload);
                          await client.post('/result/save-diet-diary', secondPayload);
                        }
                      });
                      navigate(`/DayTwoForm/${appointmentId}/${orderId}`);
                  }
            
                  setCurrentStep(currentStep + 1);
                  
                }
              } catch (error) {
                console.error('Error submitting form:', error);
              } finally {
                setSubmitting(false);
              }
            }} 
          >
           {({ values, handleChange }) => (
    <Form >
      {questionone.map((question) => {
        return (
          <div key={question.id} className="flex flex-col items-start">
            <label
              htmlFor={`question_${question.id}`}
              className="day-one-ques mb-3 align-items-start  "
            >
              {question.text}
            </label>
            <Field
              as="textarea"
              id={`question_${question.id}`}
              name={`question_${question.id}`}
              rows={4}
              cols={50}
              value={values[`question_${question.id}`] || ''}
              onChange={handleChange}
              className="day-one-input mb-4 p-3"
              disabled={isCompleted}
            />
            <ErrorMessage
              name={`question_${question.id}`}
              component="div"
              className="text-danger mb-3 nutrional-form-error"
            />
          </div>
        );
      })}

      <p className="day-one-head ms-3">Dietary Information</p> <br />
      <p className="day-one-para align-items-start ms-4 ms-md-2">
        Please add information about what food and drink you
        <br />
        have consumed for three days to allow the consultant <br />
        to assess changes you might need to make.
      </p>
      <h4 className="day-one-form-head">Day 1</h4>

      {questions.map((question) => {
        return (
          <div key={question.id} className="flex flex-col items-start">
            <label
              htmlFor={`question_${question.id}`}
              className="day-one-label mb-2 align-items-start "
            >
              {question.text}
            </label>
            <Field
              as="textarea"
              id={`question_${question.id}`}
              name={`question_${question.id}`}
              rows={4}
              cols={50}
              value={values[`question_${question.id}`] || ''}
              onChange={handleChange}
              className="day-one-input mb-3 p-3"
              disabled={isCompleted}
            />
            <ErrorMessage
              name={`question_${question.id}`}
              component="div"
              className="text-danger mb-1 nutrional-form-error"
            />
          </div>
        );
      })}

      <button type="submit" className="day-one-btn text-dark">
        Next
      </button>
    </Form>
  )}
</Formik>
      }


      <FooterCopyright />
    </div>
  );
};

export default DayOneForm;
