import React, { FormEvent, useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import last from 'lodash/last';
import TopComp from '../components/TopComp';
import { OnChangeEvent } from '../types/types';
import theBigImage from '../images/weeks/week0.jpg';
import { useAppDispatch } from '../redux/store';
import { AppRoutes, adminRoutes } from '../constants/routes';
import { showErrorMessage } from '../utils/banner.utils';
import { getCompletedStepsAction } from '../redux/actions/visitedscreens';
import { getAnswersAction } from '../redux/actions/questionAction';
import FooterCopyright from '../components/FooterCopyright';
import { changeCurrentStep } from '../redux/slices/visitedscreens';
import { loginAction } from '../redux/actions/auth';
import '../css/femmar.css';
import { UserRoles } from '../types/models/User';
import { getMyOrdersAction } from '../redux/actions/user';
import { checkIfUserPaid, isFreeUser } from '../utils/auth.utils';

const LoginB2C = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange: OnChangeEvent = (event) => {
    setEmail(event.target.value);
  };

  const handleForgot = async (event: FormEvent) => {
    event.preventDefault();
    navigate(AppRoutes.ForgotPassword);
  };

  const handlePasswordChange: OnChangeEvent = (event) => {
    setPassword(event.target.value);
  };

  const handleContinue = async (event: FormEvent) => {
    event.preventDefault();
    try {
      event.preventDefault();

      const response = await dispatch(
        loginAction({ email: email.toLowerCase(), password }),
      ).unwrap();
          

      if (!isFreeUser(response.userAttributes?.userRole as UserRoles)) {
        const orderResponse = await dispatch(getMyOrdersAction()).unwrap();
        if (!checkIfUserPaid(orderResponse)) {
          navigate(AppRoutes.Payment);
          return;
        }
      }
     
      localStorage.setItem('isFirstTimeUser',response.isFirstTimeUser.toString())
      await dispatch(getAnswersAction({ weekNumber: undefined })).unwrap();

      // go to last completed step
      const completedSteps = await dispatch(getCompletedStepsAction()).unwrap();
      const lastStep = last(completedSteps || []);
      const newCurrentStep = lastStep ? `/${lastStep}` : AppRoutes.Welcome;
      dispatch(changeCurrentStep(newCurrentStep.replace('/', '')));
      let route = newCurrentStep;

      if (response?.userAttributes?.userRole === UserRoles.Admin) {
        route = adminRoutes.Dashboard;
      }
      else if(response?.userAttributes?.userRole === UserRoles.Nutritionist){
         route= AppRoutes.NutrionalDashInfo;
      } else if (
        completedSteps?.includes(
          AppRoutes.HistoryQuestionnaire.replace('/', ''),
        ) && response?.isFirstTimeUser === false
      ) {
        route = AppRoutes.DashboardMain;
      }

      // go to new current step
      navigate(route);
    } catch (e: any) {
      console.log(e);
      showErrorMessage(e);
      setEmail('');
    setPassword('');
    }
  };

  /*   SHOW HIDE PASSWORD */

  const [pwdShow, setPwdShow] = useState(false);

  const handleBtnPwdShow = () => {
    setPwdShow(true);
  };
  const handleBtnPwdHide = () => {
    setPwdShow(false);
  };

  const handleBtnRegister = () => {
    navigate('/Register0');
    
  };
  

  return (
    <div>
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-between gap-half padding-bottom5">
        <img className="height-third" src={theBigImage} alt="" />

        <p className="h1 ml-ms-2">Login</p>

        <form onSubmit={handleContinue}>
          <div className="flex flex-col flex-item-start gap-1">
            <div className="login-container">
              <p className="login-label">Username (Email address)</p>
              <input
                type="text"
                value={email}
                onChange={handleEmailChange}
                title="Username (Email address)"
                className="login-input"
                placeholder="Input"
              />
            </div>

            <div className="login-container">
              <p className="login-label">Enter your Password</p>
              <div className="password-input-container">
              <input
                type={!pwdShow ? 'password' : 'text'}
                value={password}
                onChange={handlePasswordChange}
                title="Password"
                className="login-pwd"
                placeholder="Input"
              />
              &nbsp;
              {pwdShow && (
                <button
                  type="button"
                  onClick={handleBtnPwdHide}
                  className="btn-pwd-show-hide"
                  aria-label="Toggle password visibility">

               <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 16 16">
  <rect width="16" height="16" fill="white"/>
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" fill="#a639e8"/>
  <circle cx="8" cy="8" r="2.7" stroke="#a639e8" strokeWidth="1" fill="none"/>
  <circle cx="8" cy="8" r="2.7" stroke="#a639e8" strokeWidth="0.2" fill="none"/>
</svg>

                </button>
              )}
              {!pwdShow && (
                <button
                  type="button"
                  onClick={handleBtnPwdShow}
                  className="btn-pwd-show-hide"
                  aria-label="Toggle password visibility">

                 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 16 16">
  <rect width="16" height="16" fill="white"/>
  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486z" fill="#a639e8"/>
  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" fill="#a639e8"/>
  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708" fill="#a639e8"/>
</svg>

                </button>
              )}
              </div>
            </div>

            <button
              type="button"
              className="login-forgot margin-half"
              onClick={handleForgot}>
              Forgot Password?
            </button>

            <button
              type="submit"
              className="purple-btn"
              onClick={handleContinue}>
              Log In
            </button>

            {/* <button
              type="button"
              className="login-forgot"
              onClick={handleBtnRegister}>
              New users register here
            </button> */}
          </div>
        </form>
      </div>

      <FooterCopyright />
    </div>
  );
};

export default LoginB2C;
