import React, { useEffect, useState } from 'react';
import '../css/femmar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { logout } from '../redux/slices/auth';
import logoFemmar from '../images/logos/logoFemmar.png';
import { setAuthenticationToken } from '../api/client';
import HamburgerComp from './HamburgerComp';
import { clearUserData } from '../redux/slices/appointmentpool';

import iconHamburger from '../images/icons/iconHamburger.png';
import iconHamburgerClose from '../images/icons/iconHamburgerClose.png';

import iconSearch from '../images/icons/iconSearch.png';
import { AppRoutes } from '../constants/routes';


const TopComp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const [hamburgerOn, setHamburgerOn] = useState(false);
  const isLoggedIn = !!user;

  const location = useLocation();
  const currentLocation = location.pathname;

  const handleHamburgerOn = () => {
    const x =
      hamburgerOn === false ? setHamburgerOn(true) : setHamburgerOn(false);
  };
  const [logoutClicked, setLogoutClicked] = useState(false);


const isFirstTimeUser= localStorage.getItem('isFirstTimeUser');
useEffect(() => {
  if (isFirstTimeUser === "true" && !logoutClicked && currentLocation !== '/' && currentLocation !== '/HistoryQuestionnaire' && currentLocation !== '/forgot-password' && currentLocation !== '/HistoryQuestionnaireIntro') {
    
    navigate('/Welcome');
  }
}, [isFirstTimeUser,navigate, logoutClicked]);
// const handleLogout = () => {
//   onLogout();
//   setLogoutClicked(true);
// };

  
// console.log('ptt',currentLocation)

  const onLogout = () => {
    dispatch(logout());
    dispatch(clearUserData());
    setAuthenticationToken(undefined);
    navigate('/');
    localStorage.removeItem('appointmentUser');
    localStorage.removeItem('appointmentId');
    setLogoutClicked(true);
  };

  // go to top of screen after navigating to the page
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  /*   console.log(currentLocation); */

  return (
    <div className="flex flex-col flex-item-center gap-half">
      {/*  ------------------- ROW 1 logo search hamburger ---------------------- */}
      <div className="flex flex-row flex-item-center flex-space-between width9060">
        <button
          type="button"
          onClick={() => navigate(AppRoutes.DashboardMain)}
          className="text-left bg-white me-md-4">
          <img className="header-logo" src={logoFemmar} alt="Femmar" />
        </button>

        {/* {currentLocation !== '/Search' ? (
          <button
            id="open-chatbot"
            type="button"
            className="dashboard-row1-btn bg-white">
            <img src={iconSearch} className="dashboard-row1-btn" alt="Search" />
          </button>
        ) : (
          <p>&nbsp;</p>
        )} */}
       <div >
        {hamburgerOn === false ? (
          <button
            type="button"
            onClick={handleHamburgerOn}
            className="dashboard-row1-btn bg-white">
            <div className="flex flex-row flex-item-center gap0 nav-menu">
              <img
                src={iconHamburger}
                className="dashboard-row1-btn ib"
                alt=""
              />
              <span className="color-black smaller menu-text ">MENU&nbsp;</span>
            </div>
          </button>
        ) : (
          <button
            type="button"
            onClick={handleHamburgerOn}
            className="dashboard-row1-btn flex flex-item-center bg-white">
            {/* <img src={iconHamburgerClose} className="dashboard-row1-btn ib" alt="" /> */}
            <div style={{ 'display': 'inline-flex', 'alignItems': 'center' }} className="nav-menu" >
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" className=" dashboard-row1-btn ib bi bi-x-lg" viewBox="0 0 16 16"  >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" strokeWidth="0." stroke="black" />
              </svg>

              <span className="close-text ">CLOSE&nbsp;</span>
            </div>
          </button>
        )}
        </div>
      </div>

      {/*  ------------------- ROW 2 help logout ---------------------- */}
      <div className="flex flex-row flex-item-center flex-space-between gap-half width9060">
        {isLoggedIn && currentLocation !== '/Welcome' && currentLocation !== '/HistoryQuestionnaireIntro'  && currentLocation !== '/DashboardMain'?   (
          <button
            type="button"
            className="font-xxl color-black flex1 text-left"
            onClick={() => navigate(-1)}
            style={{ backgroundColor: 'transparent', border: 'none' }}>
            &lt;
          </button>
        ) : (
          <p>&nbsp;</p>
        )}
      
        {currentLocation !== '/DashboardMain' && isLoggedIn && isFirstTimeUser!== "true" ? (
         
          <button
            type="button"
            onClick={() => navigate('/DashboardMain')}
            className="dashboard-row2-btn flex1 ">
            Dashboard
          </button>
        ) : (
          <p>&nbsp;</p>
        )}

        {/* // it says help on the screen but it goes straight to FAQs */}
        {currentLocation !== '/Faqs' && isLoggedIn ? (
          <button
            type="button"
            onClick={() => navigate('/Faqs')}
            className="dashboard-row2-btn flex1">
            Help
          </button>
        ) : (
          <p>&nbsp;</p>
        )}

        {isLoggedIn ? (
          <button
            type="button"
            onClick={() => onLogout()}
            className="dashboard-row2-btn flex1 logout-margin ">
            Log Out
          </button>
        ) : (
          <p>&nbsp;</p>
        )}
     

        {/*         {currentLocation !== '/Welcome' && (
          <button
            type="button"
            className="font-xxl color-black"
            onClick={() => navigate(-2)}
            style={{ backgroundColor: 'transparent', border: 'none' }}>
            &gt;
          </button>
        )} */}
      </div>

      {hamburgerOn === true && <HamburgerComp isLoggedIn={isLoggedIn} />}
    </div>
  );
};

export default TopComp;
