import React from 'react';
import { FieldErrorProps } from './FieldError.props';

const FieldError = ({
  visible,
  errorId,
  text,
  compact,
  ...rest
}: FieldErrorProps) => {
  if (compact || !visible) {
    return null;
  }

  return (
    <div style={{ color: 'red' }} {...rest}>
      {errorId || text}
    </div>
  );
};

FieldError.defaultProps = {
  visible: false,
  compact: false,
};

export default FieldError;
