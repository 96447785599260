import { AxiosPromise } from 'axios';
import { client } from './client';
import { RegistrationValues } from '../types/models/Register';
import { LoginApiParams, LoginApiResponse } from '../types/models/AuthApi';
import { ForgotPasswordValues } from '../types/models/ForgotPassword';
import { ResetPasswordParams } from '../types/models/ResetPassword';

export const login = (params: LoginApiParams): AxiosPromise<LoginApiResponse> =>
  client.post('/auth/signin', params);

export const register = (
  params: RegistrationValues & { langNum: number },
): AxiosPromise<LoginApiResponse> => client.post('/auth/signup', params);

export const verifyEmail = (params: {
  email: string;
  token: string;
}): AxiosPromise<{ verified: boolean }> => client.post('/auth/verify', params);

export const forgotPassword = (
  params: ForgotPasswordValues,
): AxiosPromise<{ success: boolean }> =>
  client.post('/auth/forgot-password', params);

export const resetPassword = (
  params: ResetPasswordParams,
): AxiosPromise<{ success: boolean }> =>
  client.post('/auth/reset-password', params);

export const resendVerificationEmail = (params: {
  email: string;
}): AxiosPromise<{ success: boolean }> =>
  client.post('/auth/resend-verification', params);
