import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';

import FooterCopyright from '../components/FooterCopyright';
import myContent from '../jsonContent/trans_Terms.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';

const Terms = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const {
    pageTitle,
    heading0,
    pageText0,
    heading1,
    pageText1,
    heading2,
    pageText2,
    heading3,
    pageText3,
    heading4,
    pageText4,
    heading5,
    pageText5,
    heading6,
    pageText6,
    heading7,
    pageText7,
    heading8,
    pageText8,
    heading9,
    pageText9,
  } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center gap-half fill-page">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />

      <p>
        <b>{heading0}</b>
      </p>
      <div className="page-text">{parse(pageText0)}</div>

      <p>
        <b>{heading1}</b>
      </p>
      <div className="page-text">{parse(pageText1)}</div>
      <p>
        <b>{heading2}</b>
      </p>
      <div className="page-text">{parse(pageText2)}</div>
      <p>
        <b>{heading3}</b>
      </p>
      <div className="page-text">{parse(pageText3)}</div>
      <p>
        <b>{heading4}</b>
      </p>
      <div className="page-text">{parse(pageText4)}</div>
      <p>
        <b>{heading5}</b>
      </p>
      <div className="page-text">{parse(pageText5)}</div>
      <p>
        <b>{heading6}</b>
      </p>
      <div className="page-text">{parse(pageText6)}</div>
      <p>
        <b>{heading7}</b>
      </p>
      <div className="page-text">{parse(pageText7)}</div>
      <p>
        <b>{heading8}</b>
      </p>
      <div className="page-text">{parse(pageText8)}</div>
      <p>
        <b>{heading9}</b>
      </p>
      <div className="page-text">{parse(pageText9)}</div>

      <FooterCopyright />
    </div>
  );
};

export default Terms;
