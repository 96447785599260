import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { logout } from '../../../redux/slices/auth';

const Header = () => {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const className = showMenu ? 'show' : '';

  const logoutUser = () => {
    dispatch(logout());
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    document.body.className = showSidebar ? 'toggle-sidebar' : '';
  };

  useEffect(() => {
    toggleSidebar();
  }, []);

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <a href="#" className="logo d-flex align-items-center">
          <span className="d-none d-lg-block">Femmar Admin</span>
        </a>
        <a href="#" aria-label="icon" onClick={toggleSidebar}>
          <i className="bi bi-list toggle-sidebar-btn" />
        </a>
        <a href="/DashboardMain">Go to Web</a>
      </div>

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item dropdown pe-3">
            <a
              className={`nav-link nav-profile d-flex align-items-center pe-0 ${className}`}
              onClick={(e) => {
                e.preventDefault();
                setShowMenu(!showMenu);
              }}
              href="#"
              data-bs-toggle="dropdown">
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {user?.firstName}
              </span>
            </a>

            <ul
              className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${className}`}>
              <li className="dropdown-header">
                <h6>{user?.email}</h6>
                <span>{user?.userAttributes?.userRole}</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  onClick={logoutUser}
                  href="#">
                  <i className="bi bi-box-arrow-right" />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
