import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

interface ITablePaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const TablePagination: React.FC<ITablePaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const items: React.ReactNode[] = [];
  // eslint-disable-next-line no-plusplus
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        onClick={() => onPageChange(number)}
        active={number === currentPage}>
        {number}
      </Pagination.Item>,
    );
  }
  return <Pagination>{items}</Pagination>;
};

export default TablePagination;
