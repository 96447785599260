import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginApiParams, LoginApiResponse } from '../../types/models/AuthApi';
import { login } from '../../api/auth';
import { withToastForError } from '../../utils/thunk.utilts';

export const loginAction = createAsyncThunk<
  LoginApiResponse['user'],
  LoginApiParams
>(
  'auth/login',
  withToastForError(async (params) => {
    const response = await login(params);
    localStorage.setItem('token',response.data.user.token)
    const userId = response.data.user.id?.toString() ?? '';
    localStorage.setItem('userId', userId);

    return response.data.user;
  }),
);
