import React from 'react';
import parse from 'html-react-parser';
import QuestionComp from '../components/QuestionComp';
import { QuizTypes } from '../types/models/QuizTypes';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import myContent from '../jsonContent/trans_SixWk6L9ProgressQuestionnaire.json';
import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';

const SixWk6L8SympTracker = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText } = myContent.trans[langNum];

  return (
    <div>
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />

      <div className="flex flex-col flex-item-center flex-space-between">
        <p className="page-text">{parse(pageText)}</p>
      </div>

      <QuestionComp
        weekNumber={6}
        className="flex-item-center"
        type={QuizTypes.PROGRESS}
      />
    </div>
  );
};

export default SixWk6L8SympTracker;
