import React, { useEffect, useMemo, useState } from 'react';
import { unionBy } from 'lodash';
import parse from 'html-react-parser';
import Select, { SingleValue } from 'react-select';
import moment from 'moment';
import '../css/femmar.css';

import myContent from '../jsonContent/trans_SymptomReport.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { fetchQuizByType } from '../api/quiz';
import { QuizTypes } from '../types/models/QuizTypes';
import { QuestionTypes } from '../types/models/QuestionTypes';
import { IQuestion, NestedQuestion } from '../types/models/Quiz';
import { selectWeekSymptomAnswers } from '../redux/slices/visitedscreens';
import { WeekSymptomAnswer } from '../types/models/QuestionModel';
import { SelectOption } from '../types/common';
import SymptomIcon from '../components/atoms/SymptomIcon/SymptomIcon';
import { getAnswersAction } from '../redux/actions/questionAction';

interface SymptomAttr {
    userId: number;
}

const Symptoms: React.FC<SymptomAttr> = ({ userId }) => {
    const langNum = useAppSelector(selectLanguageIndex);
    const answers = useAppSelector(selectWeekSymptomAnswers);
    const dispatch = useAppDispatch()
    const [selectedWeek, setSelectedWeek] = useState<number>(1);
    const [nestedQuestion, setNestedQuestion] = useState<IQuestion>();

    const { pageTitle, pageText } = myContent.trans[langNum];


    useEffect(() => {
        if (userId) {
            dispatch(getAnswersAction({ userId }))
        }

    }, [userId])

    const weeks = useMemo(() => {
        // console.log(" answers", answers);
        const uniqAnswers = unionBy(answers, ({ weekNumber }) => weekNumber).filter(
            ({ weekNumber }) => weekNumber,
        );
        setSelectedWeek(uniqAnswers?.[0]?.weekNumber);
        return uniqAnswers;
    }, [answers]);

    const weekAnswers = useMemo(() => {
        return (
            answers
                .filter(({ weekNumber }) => weekNumber === selectedWeek)
                .find(({ questionId }) => questionId === nestedQuestion?.id)?.answers ||
            []
        );
    }, [selectedWeek, answers, nestedQuestion]);

    const initialize = async () => {
        const response = await fetchQuizByType(QuizTypes.PROGRESS, undefined);
        const symptomQuestion = response.data.quiz.questions.find(
            (question) => question.type === QuestionTypes.NESTED_MULTIPLE_CHOICE,
        );

        if (symptomQuestion?.nestedQuestions?.length) {
            setNestedQuestion(symptomQuestion);
        }
    };

    const handleSelect = (option: SingleValue<SelectOption>) => {
        if (option) {
            setSelectedWeek(option.value);
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    const getReportDate = () => {
        const date = weeks.find(
            (week) => week.weekNumber === selectedWeek,
        )?.createdAt;
        if (date) {
            return moment(date).format('DD, MMM YYYY');
        }

        return '';
    };

    const getSelectedWeek = () => {
        const week = weeks.find(({ weekNumber }) => weekNumber === selectedWeek);
        
        if (week) {
            return { label: `Report ${weeks.indexOf(week) + 1}`, value: week.weekNumber };
        }
        return undefined;
    };

    const renderSymptomRow = (symptom: NestedQuestion) => {
        const optionId = weekAnswers.find(
            (answer) => answer.questionId === symptom.id,
        )?.selectedOptionId;
        const text = nestedQuestion?.options?.find(({ id }) => id === optionId)
            ?.text as string;
        const isMild = text === 'Mild';
        const isLot = text === 'Moderate';
        const isSevere = text === 'Severe';

        return (
            <tr key={symptom.text}>
                <td>{symptom.text}</td>
                <th aria-label="icon">
                    <SymptomIcon active />
                </th>
                <th>
                    {isMild || isLot || isSevere ? (
                        <SymptomIcon active />
                    ) : (
                        <SymptomIcon />
                    )}
                </th>
                <th>{isLot || isSevere ? <SymptomIcon active /> : <SymptomIcon />}</th>
                <th>{isSevere ? <SymptomIcon active /> : <SymptomIcon />}</th>
            </tr>
        );
    };

    return (
        <div className="flex flex-col flex-item-center flex-space-between gap1">


            <div className="page-text">{parse(pageText)}</div>

            {!weeks.length ? (
                <div>
                    User has not submitted weekly quesionnaire
                </div>
            ) : (
                <div>
                    <Select<SelectOption>
                        onChange={handleSelect}
                        placeholder="Select"
                        options={weeks.map((week) => ({
                            value: week.weekNumber,
                            label: `Report ${weeks.indexOf(week) + 1}`,
                            ...week,
                        }))}
                        value={getSelectedWeek()}
                        className="width9030 border-thin border-round margin-half"
                    />
                    <p>
                        <b>Symptom Report:</b> {getReportDate()}
                    </p>

                    <table className="symptom-report-table width9050 text-center">
                        <thead>
                            <tr>
                                <th className="text-left">Symptom</th>
                                <th>None</th>
                                <th>Some</th>
                                <th>A lot</th>
                                <th>Severe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nestedQuestion?.nestedQuestions.map(renderSymptomRow)}
                        </tbody>
                    </table>
                </div>
            )}


        </div>
    );
};

export default Symptoms;
