import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Alert, Spinner } from 'react-bootstrap';
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';
import { getMyActionDetail } from '../api/actiondetail';

interface MyActionDetailResponse {
  id: number;
  question: string;
  left_side_ans: string;
  right_side_ans: string;
  updatedAt: string;
}

const ActionDetails: React.FC = () => {
  const [actionDetail, setActionDetail] = useState<MyActionDetailResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActionDetail = async () => {
      try {
        const response = await getMyActionDetail();
        setActionDetail(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log('error getting action detail', error);
      } 
    };
    fetchActionDetail();
  }, []);

  const handleActionDetail = (id: number) => {
    navigate(`/ActionDetaildesc/${id}`);
  };

  const formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    return { formattedDate, formattedTime };
  };

  return (
    <div className="flex flex-col flex-item-center gap-half fill-page">
      <TopComp />
      <hr className="width9060" />
      <div className="flex flex-col flex-item-center flex-space-around gap1">
        <p className="h2 flex flex-start text-dark">My Action Plan</p>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" />
          </div>
        ) : actionDetail.length > 0 ? (
          actionDetail.map((item, index) => {
            const isFirstItem = index === 0;
            const { formattedDate, formattedTime } = isFirstItem ? formatDateTime(item.updatedAt) : { formattedDate: '', formattedTime: '' };
            return (
              <div key={item.id} className="actiondetail-container">
                {isFirstItem && (
                  <>
                  <p className="flex flex-start text-dark"><b>UPDATED ON: &nbsp; </b> {formattedDate} <b>&nbsp;AT&nbsp;</b> {formattedTime}</p>
                  <p className="flex flex-start text-dark width9050">This program is tried and tested to work to improve your symptoms and leave you feeling better than ever. To achieve great results and reach your goals, you will need to make some adjustments to your diet and lifestyle. </p>
                  </>
                )}
                
                <div className="actiondetail" >
                  <button
                    type="button"
                    className="actiondetail-btn"
                    onClick={() => handleActionDetail(item.id)}
                  >
                    {item.question}
                  </button>
                  <button
                  type="button"
                    className="bi bi-arrow-right-circle-fill flex1 arrow-more-less fw-bold bg-white"
                    style={{ fontSize: '22px', color: 'black', cursor: 'pointer' }}
                    onClick={() => handleActionDetail(item.id)}
                    aria-label={`View details for ${item.question}`}

                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="actiondetail">
            <Alert variant="info">No Action Plan Details exist currently.</Alert>
          </div>
        )}
      </div>
      <FooterCopyright />
    </div>
  );
};

export default ActionDetails;
