import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { getAppointmentAction, getAppointmentPoolAction, setAppointmentUserAction } from '../actions/appointmentpool';
import { AppointmentPool } from '../../types/models/AppointmentPool';
import { Appointment } from '../../types/models/AppointmentModel';
import { AppointmentUser } from '../../types/models/AppointmentUser';

const appointmentUser = localStorage.getItem('appointmentUser');

export interface AppointmentPoolState {
    appointmentPool: AppointmentPool | null;
    appointment: Appointment | null,
    user: AppointmentUser | null,
    userId: AppointmentUser | null
}

const initialState: AppointmentPoolState = {
    appointmentPool: null,
    appointment: null,
    user: appointmentUser ? JSON.parse(appointmentUser) : null,
    userId: null
};

export const AppointmentPoolSlice = createSlice({
    name: 'appointmentpool',
    initialState,
    reducers: {
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        clearUserData: (state) => {
            state.appointmentPool = null;
            state.appointment = null;
            state.user = null;
            state.userId = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAppointmentPoolAction.fulfilled, (state, action) => {
            state.appointmentPool = action.payload;
        });
        builder.addCase(getAppointmentAction.fulfilled, (state, action) => {
            state.appointment = action.payload;
        });
        builder.addCase(setAppointmentUserAction.fulfilled, (state, action) => {
          
            state.user = action.payload;
            state.userId = action.payload?.user?.id;
        });

    },
});

export const selectAppointmentPool = (state: RootState) =>
    state.appointmentpool.appointmentPool || null;

export const selectAppointment = (state: RootState) =>
    state.appointmentpool.appointment || null;

export const selectAppointmentUser = (state: RootState) =>
    state.appointmentpool.user || null;
    
export const { setUserId, clearUserData } = AppointmentPoolSlice.actions;    

export default AppointmentPoolSlice.reducer;
