import { AxiosPromise } from 'axios';
import { client } from './client';

interface MyActionDetailResponse {
  id: number;
  question: string;
  left_side_ans: string;
  right_side_ans: string;
  updatedAt: string;
  
}

export const getMyActionDetail = (): AxiosPromise<{ data: MyActionDetailResponse[] }> =>
  client.get('/users/my-action-details');
