import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import FormTemplate from '../components/template/FormTemplate';
import FooterCopyright from '../components/FooterCopyright';
import { ContactInquiryParams } from '../types/models/ContactInquiry';
import { sendInquiry } from '../api/contact';
import { showErrorMessage, showSuccsesMessage } from '../utils/banner.utils';
import ContactInquiryForm from '../components/Organisms/ContactInquiryForm/ContactInquiryForm';
import PageTitleComp from '../components/PageTitleComp';
import Big3PinkBtnsComp from '../components/Big3PinkBtnsComp';
import { AppRoutes } from '../constants/routes';

const Contact = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: ContactInquiryParams) => {
    try {
      setLoading(true);
      const response = await sendInquiry(values);
      showSuccsesMessage(
        'We have received your message. We will contact you soon.',
      );
      navigate(
        `${AppRoutes.ContactConfirm}?ticket=${response.data.inquiry.ticketNumber}`,
      );
    } catch (e) {
      showErrorMessage(e);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap-1 fill-page ">
      <TopComp />
      <PageTitleComp pageTitle="Contact Us" />
      <Big3PinkBtnsComp />

      <p className="text-center">We will get back to you within 24 hours.</p>
      <FormTemplate
        Component={ContactInquiryForm}
        onSubmit={onSubmit}
        loading={loading}
      />

      <FooterCopyright />
    </div>
  );
};

export default Contact;
