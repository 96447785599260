import React from 'react';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import myContent from '../jsonContent/trans_Register0.json';
import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';
import FooterCopyright from '../components/FooterCopyright';

const Register0 = () => {
  const navigate = useNavigate();
  const langNum = useAppSelector(selectLanguageIndex);
 
  const { pageTitle, pageText } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center gap-half fill-page">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <div className="page-text">{parse(pageText)}</div>
      <button
        type="button"
        className="purple-btn"
        onClick={() => navigate('/Register1')}>
        Sign Up
      </button>

      <div className="bottom">
        <FooterCopyright />
      </div>
    </div>
  );
};

export default Register0;
