import React from 'react';
import { useLocation } from 'react-router';
import MenuItem from './MenuItem';
import { adminRoutes } from '../../../constants/routes';

const Sidebar = () => {
  const location = useLocation();
  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <MenuItem
          title="Dashboard"
          link={adminRoutes.Dashboard}
          icon="grid"
          active={location.pathname === adminRoutes.Dashboard}
        />
        <MenuItem
          title="Users"
          link={adminRoutes.Users}
          icon="people"
          active={location.pathname === adminRoutes.Users}
        />
      </ul>
    </aside>
  );
};

export default Sidebar;
