import React from 'react';
import parse from 'html-react-parser';
import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import '../css/femmar.css';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk4L3Joylux.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import joylux_image from '../images/pictures/joylux.jpg';
import VideoComp from '../components/VideoComp';

const SixWk4L3Joylux = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText1, heading, pageText2, citation } =
    myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap0">
      <LessonPageHeaderComp pageTitle={pageTitle} />

      <VideoComp url="https://vimeo.com/857920437/80ceefbd80?share=copy" />

      <p className="page-text">{parse(pageText1)}</p>
      <p className="h3">{heading}</p>
      <img src={joylux_image} alt="Joylux" />

      <p className="page-text">{parse(pageText2)}</p>
      <p className="citation">{citation}</p>
      <TickComp />
      <FooterBtns />
      <FooterCopyright />
    </div>
  );
};

export default SixWk4L3Joylux;
