import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import FormTemplate from '../components/template/FormTemplate';
import FooterCopyright from '../components/FooterCopyright';
import ResetPasswordForm from '../components/Organisms/ResetPasswordForm/ResetPasswordForm';
import { ResetPasswordValues } from '../types/models/ResetPassword';
import { resetPassword } from '../api/auth';
import { showErrorMessage, showSuccsesMessage } from '../utils/banner.utils';
import { useQuery } from '../hooks/useQuery';
import { AppRoutes } from '../constants/routes';

const ResetPassword = () => {
  const navigate = useNavigate();
  const params = useQuery();
  const [loading, setLoading] = useState(false);
  const onReset = async ({ password }: ResetPasswordValues) => {
    try {
      setLoading(true);
      await resetPassword({
        password,
        email: params.get('email') as string,
        token: params.get('token') as string,
      });
      showSuccsesMessage('Password successfully reset.');
      setTimeout(navigate, 1000, AppRoutes.LoginB2C);
    } catch (e) {
      showErrorMessage(e);
    }
    setLoading(false);
  };
  return (
    <div>
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-around">
        <p className="portal-h1">Reset Password</p>
        <FormTemplate
          Component={ResetPasswordForm}
          onSubmit={onReset}
          loading={loading}
        />
        <p>&nbsp;</p>
      </div>
      <FooterCopyright />
    </div>
  );
};

export default ResetPassword;
