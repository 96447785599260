import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk1L1MeetMaryon.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import VideoComp from '../components/VideoComp';

import imageMaryon from '../images/pictures/Maryon.jpg';

const SixWk1L1MeetMaryon = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText, pageSubtitle } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <LessonPageHeaderComp pageTitle={pageTitle} />

      <img src={imageMaryon} className="photo-half" alt="Maryon Stewart" />

      <p className="h2">{pageSubtitle}</p>

      <VideoComp url="https://vimeo.com/850992072/5b4119b104?share=copy" />

      <div className="page-text ">{parse(pageText)}</div>

      <TickComp />
      <FooterBtns />
      <FooterCopyright />
    </div>
  );
};

export default SixWk1L1MeetMaryon;
