import React from 'react';
import radioIcon from '../../../images/icons/Radio.svg';
import inactiveRadioIcon from '../../../images/icons/inactiveRadio.svg';

interface SymptomIconProps {
  active?: boolean;
}
const SymptomIcon: React.FC<SymptomIconProps> = ({ active }) => {
  return <img src={active ? radioIcon : inactiveRadioIcon} alt="symptom" />;
};

export default SymptomIcon;
