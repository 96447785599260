import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../redux/store';
import { AppRoutes } from '../constants/routes';

const UnprotectedLayout: React.FC = () => {
  const user = useAppSelector(({ auth }) => auth.user);
  const completedSteps = useAppSelector(
    ({ visitedscreens }) => visitedscreens.completedSteps,
  );

  if (
    user &&
    completedSteps.length &&
    completedSteps.includes(AppRoutes.HistoryQuestionnaire.replace('/', ''))
  ) {
    return <Navigate to={AppRoutes.DashboardMain} replace />;
  }

  return <Outlet />;
};

export default UnprotectedLayout;
