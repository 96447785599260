import React from 'react';
import { Link } from 'react-router-dom';

interface IMenuItemProps {
  title: string;
  icon: string;
  active?: boolean;
  link: string;
}

const MenuItem: React.FC<IMenuItemProps> = ({ title, icon, active, link }) => {
  return (
    <li className="nav-item">
      <Link className={`nav-link ${active ? '' : 'collapsed'}`} to={link}>
        <i className={`bi bi-${icon}`} />
        <span>{title}</span>
      </Link>
    </li>
  );
};

export default MenuItem;
