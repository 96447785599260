import React, { useMemo } from 'react';
import FormTemplate from '../../template/FormTemplate';
import UserAddEditForm from '../UserAddEditForm/UserAddEditForm';
import { IUser, IUserFormParams } from '../../../types/models/User';

interface IUserAddEditModalProps {
  showForm: boolean;
  handleClose: () => void;
  onSubmit: (data: IUserFormParams) => void;
  loading: boolean;
  selectedUser?: IUser;
}

const UserAddEditModal: React.FC<IUserAddEditModalProps> = ({
  showForm,
  handleClose,
  onSubmit,
  loading,
  selectedUser,
}) => {
  const initialValues = useMemo(() => {
    if (!selectedUser) {
      return {};
    }
    return {
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      email: selectedUser.email,
      userRole: selectedUser.userAttributes?.userRole,
    };
  }, [selectedUser]);
  return (
    <FormTemplate<IUserFormParams>
      Component={UserAddEditForm}
      visible={showForm}
      handleClose={handleClose}
      onSubmit={onSubmit}
      loading={loading}
      initialValues={initialValues}
      isEdit={!!selectedUser}
    />
  );
};

export default UserAddEditModal;
