import axios from 'axios';

export const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // it automatically takes from .env.development.local
});

export const directusClient = axios.create({
  baseURL: process.env.REACT_APP_DIRECTUS_URL,
  // it automatically takes from .env.development.local
});
directusClient.defaults.params = {};
directusClient.defaults.params.access_token = process.env.REACT_APP_DIRECTUS_TOKEN

export const setAuthenticationToken = (token?: string) => {
  client.defaults.headers.common.Authorization = token
    ? `Bearer ${token}`
    : undefined;
};