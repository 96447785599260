import React from 'react';
import parse from 'html-react-parser';
import QuestionComp from '../components/QuestionComp';
import { QuizTypes } from '../types/models/QuizTypes';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import FooterCopyright from '../components/FooterCopyright';
import myContent from '../jsonContent/trans_SixWk6L8Quiz.json';
import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';

const SixWk6L7Quiz = () => {

  const langNum = useAppSelector(selectLanguageIndex);
  const {pageTitle, pageText} = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap-half page-full">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <p className="page-text">{parse(pageText)}</p>
    
      <QuestionComp
        weekNumber={6}
        className="flex flex-col flex-item-center flex-space-between gap1"
        type={QuizTypes.Quiz}
      />
      <FooterCopyright />
    </div>
  );
};

export default SixWk6L7Quiz;
