import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import { useNavigate } from 'react-router-dom';

import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';

import myContent from '../jsonContent/trans_Shop.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import image from '../images/pictures/supplements3.png';

const Shop = () => {
  const navigate = useNavigate();
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />

      <img src={image} className="theBigImage" alt="" />

      <div className="page-text">{parse(pageText)}</div>
    </div>
  );
};

export default Shop;
