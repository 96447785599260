import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Calendar from 'react-calendar';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-calendar/dist/Calendar.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { View } from 'react-calendar/dist/cjs/shared/types';
import moment from 'moment';
import Select, { SingleValue } from 'react-select';
import '../css/femmar.css';
import '../css/grid.css';
import '../css/phytotracker.scss';
import parse from 'html-react-parser';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import Big3PinkBtnsComp from '../components/Big3PinkBtnsComp';
import FooterCopyright from '../components/FooterCopyright';
import myContent from '../jsonContent/trans_PhytoTracker.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  PhytoFoodItem,
  SelectedPhytoFoodItem,
} from '../types/models/PhytoTracker';
import {
  getLogsForMonthActions,
  getPhytoFoodItemsActions,
} from '../redux/actions/phytoracker';
import { showErrorMessage, showSuccsesMessage } from '../utils/banner.utils';
import { PhytoFoodImageKey, phytoFoodImages } from '../jsonContent/phytoFoods';
import { getPhytoFoodByMonth, logPhytoFoodByDate } from '../api/phytotracker';
import { MIN_PHYTO_MG, YYYY_MM_DD } from '../constants/global';
import removeIcon from '../images/icons/remove.svg';
import {  directusClient } from '../api/client';


interface PageContent {
  id: number;
  key: string;
  content: string;
}

// interface LogEntry {
//   date: string;
//   totalMg: number;
//   items: Array<any>;
// }

// const unitOptions = [
//   { value: 'tbsp', label: 'tbsp' },
//   { value: 'tsp', label: 'tsp' },
//   { value: 'cup', label: 'cup' },
//   { value: 'g', label: 'g' },
//   { value: 'mg', label: 'mg' },
//   { value: 'oz', label: 'oz' },
//   { value: 'tablet', label: 'tablet' },
//   { value: 'slice', label: 'slice' },
//   { value: 'bar', label: 'bar' },
// ];

// const unitToGram: { [key: string]: number } = {
//   'g': 1.0,
//   'ml': 1.0,
//   'cup': 250.0,
//   'oz': 28.3495,
//   'tsp': 5.69,
//   'tbsp': 14.175,
//   'slice': 30.0,
//   'bar': 113.0,
//   'tablet': 100.0
// };

const PhytoTracker = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { items, monthWideItems } = useAppSelector(
    (state) => state.phytoTracker,
  );
  const dispatch = useAppDispatch();
  const { pageText1, pageText2, pageText3, pageText4, pageText5, pageText6 } =
    myContent.trans[langNum];

  const [loading, setLoading] = useState(false);
  const [selectedPhytoFoodItems, setSelectedPhytoFoodItems] = useState<
    SelectedPhytoFoodItem[]
  >([]);
  const [logType, setLogType] = useState<'today' | 'yesterday' | 'choosedate'>(
    'today',
  );

  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const currentMonth = moment(currentDate).format('MMMM');
  const isCurrentMonth = moment().format('MMMM') !== currentMonth;

  const user = useAppSelector((state) => state.auth.user);

  const initialValues = useMemo(() => {
    return {
      firstName: user?.firstName,
    };
  }, [user]);

  const total = useMemo(() => {
    return selectedPhytoFoodItems.reduce((acc, item) => {
      acc += (item.qty || 1) * item.phytoAmountGrams;

      return acc;
    }, 0);
  }, [selectedPhytoFoodItems]);

  const missedDays = useMemo(() => {
    const totalDays = isCurrentMonth
      ? moment(currentMonth, 'MMMM').daysInMonth()
      : moment().diff(moment().startOf('month'), 'days') + 1; // include today as well
    const missingDays = Array(totalDays)
      .fill(0)
      .reduce((acc, _, index) => {
        const date = moment(currentMonth, 'MMMM')
          .startOf('month')
          .add(index, 'days')
          .format(YYYY_MM_DD);

        acc +=
          monthWideItems.findIndex((item) => item.date === date) > -1 ? 0 : 1;
        return acc;
      }, 0);
    return missingDays;
  }, [monthWideItems, currentMonth, isCurrentMonth]);

  const greenDays = useMemo(() => {
    const totalDays = isCurrentMonth
      ? moment(currentMonth, 'MMMM').daysInMonth()
      : moment().diff(moment().startOf('month'), 'days') + 1;

    const goodDays = monthWideItems.filter(
      (item) =>
        item.totalMg >= MIN_PHYTO_MG &&
        moment(item.date).format('MMMM') === currentMonth,
    ).length;
    return (goodDays * 100) / totalDays;
  }, [monthWideItems, currentMonth, isCurrentMonth]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await logPhytoFoodByDate({
        date: moment(currentDate).format(YYYY_MM_DD),
        phytoItems: selectedPhytoFoodItems.map((item) => ({
          qty: item.qty as number,
          phytoTrackerItemId: item.id,
        })),
      });
      await getLogs();
    } catch (e) {
      showErrorMessage(e);
      return;
    }
    setLoading(false);
    showSuccsesMessage('Logged successfully');
  };

  const getPhytoFoodItems = async () => {
    try {
      await dispatch(getPhytoFoodItemsActions()).unwrap();
    } catch (e) {
      showErrorMessage(e);
    }
  };

  const getLogs = async () => {
    try {
      await dispatch(
        getLogsForMonthActions({
          date: moment(currentMonth, 'MMMM').format(YYYY_MM_DD),
        }),
      ).unwrap();
    } catch (e) {
      showErrorMessage(e);
    }
  };

  const onRemoveItem = (id: number) => {
    setSelectedPhytoFoodItems((prev) => prev.filter((item) => item.id !== id));
  };

  const onChangeQty = (qty: string, id: number) => {
    setSelectedPhytoFoodItems((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, qty: qty ? parseInt(qty, 10) : 1 } : item,
      ),
    );
  };

  const handleSelect = (selectedPhytoFoodItem: SingleValue<PhytoFoodItem>) => {
    if (
      !selectedPhytoFoodItem ||
      selectedPhytoFoodItems.find(
        (item) => item.id === selectedPhytoFoodItem?.id,
      )
    ) {
      return;
    }
    setSelectedPhytoFoodItems((prev) => [
      ...prev,
      { ...selectedPhytoFoodItem, qty: 1 },
    ]);
  };

  const getDateClassName = ({ date, view }: { date: Date; view: View }) => {
    if (
      view !== 'month' ||
      moment(date).isAfter(moment()) ||
      moment(date).get('month') !== moment(currentDate).get('month')
    ) {
      return 'day';
    }
    const totalMg =
      monthWideItems.find(
        (item) => item.date === moment(date).format(YYYY_MM_DD),
      )?.totalMg || 0;
    // if (totalMg > MIN_PHYTO_MG) {
    //   return 'success';
    // }

    // return 'danger';
    if (totalMg ===0) {
              return 'no-data'; 
          }


    if (totalMg < 50) {
              return 'danger'; 
          }
          if (totalMg >= 50 && totalMg < 100) {
              return 'warning'; 
          }
          return 'success'; 
  };
  const[pagecontent,setPageContent]=useState<PageContent[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await directusClient.get(
          '/items/page_contents?filter[key][_eq]=phytotracker'
        );
        setPageContent(response.data.data);
      } catch (error) {
        console.error('Error fetching FAQ data:', error);
      }
    };

    fetchData();
  }, [])
  

  useEffect(() => {
    getPhytoFoodItems();
  }, []);

  useEffect(() => {
    getLogs();
  }, [currentMonth]);

  useEffect(() => {
    const dateSpecificItem = monthWideItems.find(
      (item) => item.date === moment(currentDate).format(YYYY_MM_DD),
    );
    setSelectedPhytoFoodItems(dateSpecificItem?.items || []);
  }, [currentDate, monthWideItems]);

  if (items.length <= 0) {
    return 'loading...';
  }

  const user_title = `${user?.firstName}'s Phyto Tracker`;
  

//   const langNum = useAppSelector(selectLanguageIndex);
//   const { items, monthWideItems } = useAppSelector(
//     (state) => state.phytoTracker,
//   );
//   const dispatch = useAppDispatch();
//   const { pageText1, pageText2, pageText3, pageText4, pageText5, pageText6 } =
//     myContent.trans[langNum];

//   const [loading, setLoading] = useState(false);
//   const [selectedPhytoFoodItems, setSelectedPhytoFoodItems] = useState<
//     SelectedPhytoFoodItem[]
//   >([]);
//   const [logType, setLogType] = useState<'today' | 'yesterday' | 'choosedate'>(
//     'today',
//   );

//   const [currentDate, setCurrentDate] = useState<Date>(new Date());
//   const currentMonth = moment(currentDate).format('MMMM');
//   const isCurrentMonth = moment().format('MMMM') !== currentMonth;

//   const user = useAppSelector((state) => state.auth.user);

//   const initialValues = useMemo(() => {
//     return {
//       firstName: user?.firstName,
//     };
//   }, [user]);

//   const convertToMg = (item: SelectedPhytoFoodItem): number => {
//     const selectedUnits = item.selectedUnit || item.unit || '';
//     const unitToGramValue = unitToGram[selectedUnits] || 1.0;
//     const quantityInGrams = item.qty * unitToGramValue;
//     const originalPhytoAmountGrams = item.originalPhytoAmountGrams || item.phytoAmountGrams || 0;
//     return quantityInGrams * originalPhytoAmountGrams;
//   };

//   // const total = useMemo(() => {
//   //   return selectedPhytoFoodItems.reduce((acc, item) => {
//   //     acc += (item.qty || 1) * item.phytoAmountGrams;

//   //     return acc;
//   //   }, 0);
//   // }, [selectedPhytoFoodItems]);
//   const total = useMemo(() => {
//     return selectedPhytoFoodItems.reduce((acc, item) => {
//       acc += convertToMg(item);
//       return acc;
//     }, 0);
//   }, [selectedPhytoFoodItems])
 

//   // const onChangeUnit = (event: React.ChangeEvent<HTMLSelectElement>, id: number) => {
//   //   const selectedValue = event.target.value;
//   //   setSelectedPhytoFoodItems((prev) =>
//   //     prev.map((item) =>
//   //       item.id === id ? { ...item, selectedUnit: selectedValue } : item
//   //     )
//   //   );
//   // };
//   const onChangeUnit = (event: React.ChangeEvent<HTMLSelectElement>, id: number) => {
//     const selectedValue = event.target.value;
//     setSelectedPhytoFoodItems((prev) =>
//       prev.map((item) => {
//         if (item.id === id) {
//           const newItem = { ...item, selectedUnit: selectedValue };
//           newItem.phytoAmountGrams = convertToMg(newItem) / newItem.qty;
//           return newItem;
//         }
//         return item;
//       })
//     );
//   };

//   const missedDays = useMemo(() => {
//     const totalDays = isCurrentMonth
//       ? moment(currentMonth, 'MMMM').daysInMonth()
//       : moment().diff(moment().startOf('month'), 'days') + 1; // include today as well
//     const missingDays = Array(totalDays)
//       .fill(0)
//       .reduce((acc, _, index) => {
//         const date = moment(currentMonth, 'MMMM')
//           .startOf('month')
//           .add(index, 'days')
//           .format(YYYY_MM_DD);

//         acc +=
//           monthWideItems.findIndex((item) => item.date === date) > -1 ? 0 : 1;
//         return acc;
//       }, 0);
//     return missingDays;
//   }, [monthWideItems, currentMonth, isCurrentMonth]);

//   const greenDays = useMemo(() => {
//     const totalDays = isCurrentMonth
//       ? moment(currentMonth, 'MMMM').daysInMonth()
//       : moment().diff(moment().startOf('month'), 'days') + 1;

//     const goodDays = monthWideItems.filter(
//       (item) =>
//         item.totalMg >= MIN_PHYTO_MG &&
//         moment(item.date).format('MMMM') === currentMonth,
//     ).length;
//     return (goodDays * 100) / totalDays;
//   }, [monthWideItems, currentMonth, isCurrentMonth]);

//   // const onSubmit = async () => {
//   //   try {
//   //     setLoading(true);
//   //     await logPhytoFoodByDate({
//   //       date: moment(currentDate).format(YYYY_MM_DD),
//   //       phytoItems: selectedPhytoFoodItems.map((item) => ({
//   //         qty: item.qty as number,
//   //         phytoTrackerItemId: item.id,
//   //       })),
//   //     });
//   //     await getLogs();
//   //   } catch (e) {
//   //     showErrorMessage(e);
//   //     return;
//   //   }
//   //   setLoading(false);
//   //   showSuccsesMessage('Logged successfully');
//   // };

//   const onSubmit = async () => {
//     try {
//       setLoading(true);
//       await logPhytoFoodByDate({
//         date: moment(currentDate).format(YYYY_MM_DD),
//         phytoItems: selectedPhytoFoodItems.map((item) => ({
//           qty: item.qty as number,
//           phytoTrackerItemId: item.id,
//           // phytoAmountGrams: item.phytoAmountGrams,
//           unit: item.selectedUnit,
//         })),
//       });
//       await getLogs();
//     } catch (e) {
//       showErrorMessage(e);
//       return;
//     }
//     setLoading(false);
//     showSuccsesMessage('Logged successfully');
//   };

//   const getPhytoFoodItems = async () => {
//     try {
//       await dispatch(getPhytoFoodItemsActions()).unwrap();
//     } catch (e) {
//       showErrorMessage(e);
//     }
//   };
  

//   const getLogs = async () => {
//     try {
//       const response =await dispatch(
//         getLogsForMonthActions({
//           date: moment(currentMonth, 'MMMM').format(YYYY_MM_DD),
//         }),
//       ).unwrap();
//       setLogs(response.map((log: { date: string; totalMg: number }) => ({
//         date: log.date,
//         totalMg: log.totalMg
//       })));
//     } catch (e) {
//       showErrorMessage(e);
//     }
//   };

//   const onRemoveItem = (id: number) => {
//     setSelectedPhytoFoodItems((prev) => prev.filter((item) => item.id !== id));
//   };

//   const onChangeQty = (qty: string, id: number) => {
//     setSelectedPhytoFoodItems((prev) =>
//       prev.map((item) =>
//         item.id === id ? { ...item, qty: qty ? parseInt(qty, 10) : 1 } : item,
//       ),
//     );
//   };

//   // const handleSelect = (selectedPhytoFoodItem: SingleValue<PhytoFoodItem>) => {
//   //   if (
//   //     !selectedPhytoFoodItem ||
//   //     selectedPhytoFoodItems.find(
//   //       (item) => item.id === selectedPhytoFoodItem?.id,
//   //     )
//   //   ) {
//   //     return;
//   //   }
//   //   setSelectedPhytoFoodItems((prev) => [
//   //     ...prev,
//   //     { ...selectedPhytoFoodItem, qty: 1 },
//   //   ]);
    
//   // };


//   // const handleSelect = (selectedPhytoFoodItem: SingleValue<PhytoFoodItem>) => {
//   //   if (
//   //     !selectedPhytoFoodItem ||
//   //     selectedPhytoFoodItems.find(
//   //       (item) => item.id === selectedPhytoFoodItem.id,
//   //     )
//   //   ) {
//   //     return;
//   //   }
//   //   setSelectedPhytoFoodItems((prev) => [
//   //     ...prev,
//   //     {
//   //       ...selectedPhytoFoodItem,
//   //       qty: 1,
//   //       selectedUnit: selectedPhytoFoodItem.unit || 'g',
//   //       originalPhytoAmountGrams: selectedPhytoFoodItem.originalPhytoAmountGrams || selectedPhytoFoodItem.phytoAmountGrams,
//   //     },
//   //   ]);
//   // };
//   const handleSelect = (selectedPhytoFoodItem: SingleValue<PhytoFoodItem>) => {
//     if (
//       !selectedPhytoFoodItem ||
//       selectedPhytoFoodItems.find(
//         (item) => item.id === selectedPhytoFoodItem.id,
//       )
//     ) {
//       return;
//     }
  
//     const newItem: SelectedPhytoFoodItem = {
//       ...selectedPhytoFoodItem,
//       qty: 1,
//       selectedUnit: selectedPhytoFoodItem.unit || 'g',
//       originalPhytoAmountGrams: selectedPhytoFoodItem.phytoAmountGrams || 0,
//     };
  
//     const unitToGramValue = unitToGram[newItem.selectedUnit] || 1.0;
//     newItem.phytoAmountGrams = newItem.originalPhytoAmountGrams * unitToGramValue;
  
//     setSelectedPhytoFoodItems((prev) => [...prev, newItem]);
//   };

//   const [logs, setLogs] = useState<{ date: string; totalMg: number }[]>([]);

//   const getDateClassName = ({ date, view }: { date: Date; view: View }) => {
//     // Format the date from the argument and the current date
//     const formattedDate = moment(date).format('YYYY-MM-DD');
//   const formattedCurrentDate = moment().format('YYYY-MM-DD');

//   // Check if the view is not 'month', or the date is in the future, or not in the current month
//   if (
//     view !== 'month' ||
//     moment(date).isAfter(moment()) ||
//     moment(date).format('YYYY-MM') !== moment(formattedCurrentDate).format('YYYY-MM')
//   ) {
//     return 'day';
//   }

//   // Find the log entry for the given date
//   const logEntry = logs.find(
//     (entry) => entry.date === formattedDate
//   );

//     console.log('Looking for log entry for date:', formattedDate);
//     console.log('Found log entry:', logEntry);

//     if (!logEntry) {
//         return 'no-data'; 
//     }

//     const { totalMg } = logEntry;
//     console.log('TotalMg for date', formattedDate, ':', totalMg);

//     if (totalMg < 50) {
//         return 'danger'; 
//     }
//     if (totalMg >= 50 && totalMg < 100) {
//         return 'warning'; 
//     }
//     return 'success'; 
// };

//   const[pagecontent,setPageContent]=useState<PageContent[]>([]);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await directusClient.get(
//           '/items/page_contents?filter[key][_eq]=phytotracker'
//         );
//         setPageContent(response.data.data);
//       } catch (error) {
//         console.error('Error fetching FAQ data:', error);
//       }
//     };

//     fetchData();
//   }, [])
  

//   useEffect(() => {
//     getPhytoFoodItems();
//   }, []);

//   useEffect(() => {
//     getLogs();
//   }, [currentMonth]);

//   useEffect(() => {
//     const dateSpecificItem = monthWideItems.find(
//       (item) => item.date === moment(currentDate).format(YYYY_MM_DD),
//     );
//     setSelectedPhytoFoodItems(dateSpecificItem?.items || []);
//   }, [currentDate, monthWideItems]);

//   if (items.length <= 0) {
//     return 'loading...';
//   }

//   const user_title = `${user?.firstName}'s Phyto Tracker`;

  return (
    <div className=" flex flex-col flex-item-center flex-space-between gap-half page-full phytotracker-container">
      <TopComp />
      <PageTitleComp pageTitle={user_title} />
      <Big3PinkBtnsComp />

      <br />

      <div className="flex flex-col flex-item-center flex-space-around gap-half">
    
 
     <div className="container ">
     <span className="text-dark width9030 mb-1">Color key of daily Phytoestrogen intake</span>
  <div className="row justify-content-center align-items-center">
    <div className="col-auto d-flex align-items-center">
      <div className="border border-white rounded" style={{ backgroundColor: '#CC3F0C', width: '30px', height: '30px' }} />
      <span className="ms-2">{`<`} 50 mg</span>
    </div>
    <div className="col-auto d-flex align-items-center">
      <div className="border border-white rounded" style={{ backgroundColor: '#F58A07' , width: '30px', height: '30px'}} />
      <span className="ms-2">50 - 100 mg</span>
    </div>
    <div className="col-auto d-flex align-items-center">
      <div className="border border-white rounded" style={{ backgroundColor: '#569150', width: '30px', height: '30px' }} />
      <span className="ms-2">100+ mg</span>
    </div>
  </div>
</div>


        {/* <p className="page-text">{parse(pageText1)}</p>
        <p className="page-text">{parse(pageText2)}</p>
        <p className="page-text">{parse(pageText3)}</p>
        <p className="page-text">{parse(pageText4)}</p>
        <p className="page-text">{parse(pageText5)}</p>
        <p className="page-text">{parse(pageText6)}</p> */}

        <Select<PhytoFoodItem>
          onChange={handleSelect}
          placeholder="Select"
          options={items.map((food) => ({
            value: food.id,
            label: food.name,
            ...food,
          }))}
          className="width9030 border-thin border-round margin-half"
        />

        {!selectedPhytoFoodItems.length && (
          <b>No items found. Please add some.</b>
        )}
        <table className="table-phyto-tracker width9060 table-grid">
          <tbody>
            {selectedPhytoFoodItems.map((phytoFood) => (
              <tr key={phytoFood.id}>
                <td>
                  <img
                    className="food-image"
                    src={phytoFoodImages[phytoFood.image as PhytoFoodImageKey]}
                    alt={phytoFood.image}
                  />
                </td>
                <td className="text-left">
                  <input
                    type="number"
                    min={1}
                    value={phytoFood.qty}
                    className="quantity border border-dark"
                    onChange={(e) => onChangeQty(e.target.value, phytoFood.id)}
                  />
                  <div>{phytoFood.name}</div>
                </td>
                {/* <td className="text-left">
                <select
  value={phytoFood.selectedUnit}
  onChange={(e) => onChangeUnit(e, phytoFood.id)}
  className="border border-dark p-2 mb-3 w-50 rounded "
>
  {unitOptions.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ))}
</select>
                </td> */}
                {/* <td>{(phytoFood.qty || 1) * phytoFood.phytoAmountGrams} mg</td>
                <td>{convertToMg(phytoFood).toFixed(2)} mg</td> */}
                <td className="text-left">{phytoFood.unit}</td>
                <td>{(phytoFood.qty || 1) * phytoFood.phytoAmountGrams} mg</td>
                <td>
                  <button
                    type="button"
                    onClick={() => onRemoveItem(phytoFood.id)}>
                    <img alt="remove" src={removeIcon} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="total-mgs flex flex-row flex-item-center flex-space-around width9060">
          <b>Total Phytoestrogens</b> {total} mg
        </div>

        <br />

        <div className="flex-item-left margin-left-5-30">
          <p className="phyto-text-mar">
            <b>When: </b> {moment(currentDate).format('DD MMM YYYY')}
          </p>
        </div>

        <div>
          <button
            disabled={loading}
            type="button"
            className="fmButton"
            onClick={onSubmit}>
            {loading ? 'logging...' : 'Save'}
          </button>
        </div>

        <br />

        <div className="flex-item-left margin-left-5-30">
          <p className="phyto-text-mar">
            <b>Select Date</b>
          </p>
        </div>

        <div>
          <button
            type="button"
            className={`border-thin padding-half phyto-date-btn ${
              logType === 'today' && 'bg-femmar-purple color-white'
            }`}
            onClick={() => {
              setCurrentDate(new Date());
              setLogType('today');
            }}>
            Today
          </button>
          <button
            type="button"
            className={`border-thin padding-half phyto-date-btn ${
              logType === 'yesterday' && 'bg-femmar-purple color-white'
            }`}
            onClick={() => {
              setLogType('yesterday');
              setCurrentDate(moment().subtract(1, 'day').toDate());
            }}>
            Yesterday
          </button>
          <button
            type="button"
            className={`border-thin padding-half phyto-date-btn ${
              logType === 'choosedate' && 'bg-femmar-purple color-white'
            }`}
            onClick={() => setLogType('choosedate')}>
            Choose date
          </button>
        </div>

        {/*        {logType === 'choosedate' && ( */}
        <div className="padding3">
          <Calendar
            onChange={(date) => setCurrentDate(date as Date)}
            value={currentDate}
            // eslint-disable-next-line react/no-unstable-nested-components
            tileClassName={getDateClassName}
            tileDisabled={({ date }) => moment(date).isAfter(moment())}
          />
        </div>

        <div>
          {/* <span className="border-thin padding1 ib">
            Days missed
            <br />
            <b>{missedDays} Days</b>
          </span> */}
          <span className="border-thin padding1 ib">
            % Days of green
            <br />
            <b>{Math.ceil(greenDays)}% </b>
          </span>
        </div>
        <div>
          <button
            disabled={loading}
            type="button"
            className="fmButton"
            onClick={onSubmit}>
            {loading ? 'logging...' : 'Save'}
          </button>
        </div>
      </div>

      <FooterCopyright />
    </div>
  );
};

export default PhytoTracker;
