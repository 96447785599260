import React from 'react';
import '../css/femmar.css';

import { useNavigate } from 'react-router-dom';

import TopComp from './TopComp';
import PageTitleComp from './PageTitleComp';
import iconModulesMenu from '../images/icons/iconModules.png';
import iconPhytoTracker from '../images/icons/iconPhytoTracker.png';
import iconSymptomReport from '../images/icons/iconMenoMeter.png';

interface LessonPageHeaderCompProps {
  pageTitle: string;
  weekNum?: number
}

const LessonPageHeaderComp: React.FC<LessonPageHeaderCompProps> = (props) => {
  const { pageTitle, weekNum } = props;

  const navigate = useNavigate();

  const handleSymptomReport = () => {
    navigate('/SymptomReport');
  };
  const handlePhytoTracker = () => {
    navigate('/PhytoTracker');
  };

  const handleModulesMenu = () => {
    navigate('/SixWeekMenu');
  };

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap-half width100">
      <TopComp />
      <PageTitleComp weekNum={weekNum} pageTitle={pageTitle} />

      <div className="flex flex-row flex-item-center flex-space-around gap1">
        <button
          type="button"
          onClick={handleSymptomReport}
          className="headerBtn-big">
          <div className="flex flex-col flex-item-center flex-space-around gap-half">
            <img src={iconSymptomReport} className="menu-icon" alt="" />
            <p className="font14px text-center">Symptom Report</p>
          </div>
        </button>

        <button
          type="button"
          onClick={handlePhytoTracker}
          className="headerBtn-big">
          <div className="flex flex-col flex-item-center flex-space-around gap-half">
            <img src={iconPhytoTracker} className="menu-icon" alt="" />
            <p className="font14px text-center">Phytoestrogen Tracker</p>
          </div>
        </button>

        <button
          type="button"
          onClick={handleModulesMenu}
          className="headerBtn-big">
          <div className="flex flex-col flex-item-center flex-space-around gap-half">
            <img src={iconModulesMenu} className="menu-icon" alt="" />
            <p className="font14px text-center">Modules Menu</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default LessonPageHeaderComp;
