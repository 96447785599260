import { directusClient } from './client';

export const getPrograms = () =>
    directusClient.get('/items/SixWeekProgram');

export const getProgramsDetails = (params: any) =>
    directusClient.get(`/items/SixWeekProgram/${params.id}`);

export const getProgramsLessons = (params: any) =>
    directusClient.get('/items/SixWeekProgramDetails', { params });

export const getLessonDetail = (params: any) =>
    directusClient.get(`/items/SixWeekProgramDetails/${params.id}`);

export const getLessonChildren = (params: any) =>
    directusClient.get(`/items/SixWeekProgramDetails`, {
        params
    });