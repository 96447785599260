import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import {
  composeValidators,
  emailValidator,
  requiredValidator,
} from '../../../utils/validations.utils';
import FieldInput from '../../molecules/FieldInput/FieldInput';
import FieldTextarea from '../../molecules/FieldTextarea/FieldTextarea';
import { ContactInquiryParams } from '../../../types/models/ContactInquiry';
import FieldSelect from '../../molecules/FieldSelect/FieldSelect';

interface ContactInquiryFormProps
  extends FormRenderProps<ContactInquiryParams> {
  loading: boolean;
}

const ContactInquiryForm: React.FC<ContactInquiryFormProps> = ({
  form,
  loading,
}) => {
  return (
    <div className="flex flex-col flex-item-left flex-space-around gap1 contact-inquiry ">
      <p className="form-label">Name</p>
      <Field
        component={FieldInput}
        name="name"
        placeholder="Input"
        validate={requiredValidator}
        className="contact-input"
      />

      <p className="form-label">Email</p>
      <Field
        component={FieldInput}
        name="email"
        placeholder="Input"
        validate={composeValidators(requiredValidator, emailValidator)}
        className="contact-input"
      />
      {/* TODO: add FieldSelect option here */}
      <p className="form-label">Topic</p>
      <Field
        component={FieldSelect}
        name="topic"
        options={[
          { title: 'Technical', value: 'Technical' },
          { title: 'Program', value: 'Program' },
          { title: 'Billing', value: 'Billing' },
          { title: 'Other', value: 'Other' },
        ]}
        placeholder="Select topic"
        validate={requiredValidator}
        className="padding-half border-thin border-round width9030 contact-input"
      />
      <p className="form-label">Message</p>
      <Field
        component={FieldTextarea}
        name="message"
        validate={requiredValidator}
        className="contact-msg width9030 border-thin border-round padding1 minHeight10 contact-input"
      />
      <button
        disabled={loading}
        type="button"
        className="purple-btn align-item-center"
        onClick={form.submit}>
        {loading ? 'Submitting...' : 'Submit'}
      </button>
    </div>
  );
};

export default ContactInquiryForm;
