import React, { useEffect, useState } from 'react';
import '../css/femmar.css';
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';
import { createCheckoutSession, getProgramInfo } from '../api/payment';
import PageTitleComp from '../components/PageTitleComp';
import { showErrorMessage } from '../utils/banner.utils';
import { PaymentProduct } from '../types/models/PaymentProduct';
import { useAppSelector } from '../redux/store';

const currencies = {
  usd: '$',
};

type CurrencyKey = keyof typeof currencies;

const Payment = () => {
  const [product, setProduct] = useState<PaymentProduct>();

  const user = useAppSelector((state) => state.auth.user);

  const initialize = async () => {
    try {
      const response = await getProgramInfo();
      setProduct(response.data);
    } catch (err) {
      showErrorMessage(err);
    }
  };

  const initiateCheckoutSession = async (type?:number) => {
    try {
      const response = await createCheckoutSession(user?.email as string,type !== undefined ? type : 0 );
      window.location.href = response.data.url;
    } catch (err) {
      showErrorMessage(err);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <PageTitleComp pageTitle="Stripe Payment" />
      {product && (
        <div>
          <b>
            {product.name} - {currencies[product.currency as CurrencyKey]}
            {product.amount}
          </b>
          <p>{product.description}</p>
          <ul>
            {product.features.map((feature) => (
              <li key={feature.name}>{feature.name}</li>
            ))}
          </ul>
        </div>
      )}
      <button
        className="fmButton"
        onClick={()=>initiateCheckoutSession(0)}
        disabled={!product}
        type="button">
        Pay now
      </button>
      <FooterCopyright />
    </div>
  );
};

export default Payment;
