import React, { useEffect, useState } from 'react';
import '../css/femmar.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router';
import myContent from '../jsonContent/trans_nutrional_dashboard.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';
import PageTitleComp from '../components/PageTitleComp';
import { client } from '../api/client';

import { setAppointmentUserAction } from '../redux/actions/appointmentpool';
import { AppointmentUser } from '../types/models/AppointmentUser';
import { selectAppointmentUser } from '../redux/slices/appointmentpool';
import Symptoms from './Symptoms';

interface User {
  id: number;
  name: string;
}

interface TransContent {
  lang: string;
  pageTitle: string;
  usernames?: User[];
  // ... other properties
}

const groupBy = function (xs: any, key: any) {
  return xs.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const NutrionalDashInfo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const langNum = useAppSelector(selectLanguageIndex);

  const appointmentUser = useAppSelector(selectAppointmentUser);

  const { pageTitle } = myContent.trans[langNum] as TransContent;;

  const [userDetail, setUserDetail] = useState(null as any);

  const [dietDiary, setDietDiary] = useState(null as any);

  const loadOptions = (
    inputValue: string,
    callback: (options: any[]) => void
  ) => {
    setTimeout(() => {

      if (!inputValue) {
        return callback([]);
      }

      return client.get('/nutritionist/userList', {
        params: {
          search: inputValue
        }
      }).then((res) => {

        return callback(res.data.users.users.map((item: any) => {
          return {
            value: item.id,
            label: item.email,
            name: item.firstName
          }
        }))
      })

    }, 1000);
  };

  const onSelect = (data: any) => {

    if (!data) {
      
      return;
    }

    client.get('/nutritionist/userDetails', {
      params: {
        userId: data.value
      }
    }).then((response) => {
      dispatch(setAppointmentUserAction(response.data.userData));
      localStorage.setItem('userId',response.data.userData.user.id);
    }).catch(err => { })

  }

  useEffect(() => {
    if (appointmentUser) {
      setUserDetail(appointmentUser);
      const diary = groupBy(appointmentUser.dietDiaryAns.result, 'quizzId');
      setDietDiary(diary);
    } else {
      setUserDetail(null);
      
    }
    localStorage.removeItem('appointmentId');
  }, [appointmentUser])

  return (
    <>
      <div className="flex flex-col flex-item-center flex-space-between gap1">
        <TopComp />
        <PageTitleComp pageTitle={pageTitle} />

        <AsyncSelect
          loadOptions={loadOptions}
          className="nutrional-dash-select "
          placeholder="Search user by email"
          defaultOptions
          isClearable
          isSearchable
          onChange={onSelect}
        />

        <div className="row">
          <div className="col-12 text-left">
            {userDetail && <p className="nutrional-dash-heading">{userDetail.user.firstName}&apos;s Information:</p>}
            {userDetail && < Tab.Container id="left-tabs-example" defaultActiveKey="first" >
              <Row className="nutrional-dash-sidebar p-2">
                <Col sm={12} className="mb-3">
                  <Nav variant="tabs" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Questionnaire</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Pre Consultation</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="fourth">Symptom Tracker</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {
                        userDetail.questionnaire.result.map((item: any) => {
                          return <div key={item.id} className="mb-2">
                            <h4>Question: {item.question?.text}</h4>
                            {
                              item.answers.map((answer: any, index: number) => {

                                if (answer?.option?.text) {
                                  return <p key={answer.id}>{index + 1}) {answer?.option?.text}</p>
                                }
                                if (answer?.value && index === 1) {
                                  return <p key={answer.id}>{index}) {answer?.value} {item.answers[0].value}</p>
                                }
                                return null;
                              })
                            }
                          </div>
                        })
                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">{
                      Object.keys(dietDiary).map((key: string) => {
                        return <div key={key} className="mb-4">
                          <h3 className="mb-4">{dietDiary[key][0]?.quiz?.type}</h3>
                          <hr />
                          {
                            dietDiary[key].map((item: any) => {

                              if (item.question?.type === 1) {
                                return <div className="mb-2">
                                  <h4>Medications</h4>
                                  <p>{item.answer}</p>
                                </div>
                              }

                              return <div className="mb-2">
                                <h4>{item.question?.text}</h4>
                                <p>{item.answer}</p>
                              </div>
                            })
                          }
                        </div>
                      })
                    }</Tab.Pane>

                    <Tab.Pane eventKey="fourth">
                      <Symptoms userId={userDetail.user.id} />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>}
          </div>
        </div>


      </div>

      {userDetail && <div className="flex flex-col flex-item-center flex-space-between gap1 mt-3">
        <button type="submit" className="nutrional-dash-btn text-dark" onClick={() => navigate('/NutrionalDashConsultation')}>
          Next
        </button>
        <FooterCopyright />
      </div>}
    </>
  );
};

export default NutrionalDashInfo;
