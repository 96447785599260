import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk6L5Plan.json';

import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import VideoComp from '../components/VideoComp';

const SixWk6L4Plan = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle } = myContent.trans[langNum];
  const pageText = parse(myContent.trans[langNum].pageText);
  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <LessonPageHeaderComp pageTitle={pageTitle} />
  

<VideoComp url="https://vimeo.com/857197529/30ec089b0b?share=copy" />

      <div className="page-text">{pageText}</div>
      <TickComp />
      <FooterBtns />
      <FooterCopyright />
    </div>
  );
};

export default SixWk6L4Plan;
