import React from 'react';
import { Field } from 'react-final-form';
import { noop } from 'lodash';
import { IQuestion } from '../../../types/models/Quiz';
import FieldWeight from '../FieldWeight/FieldWeight';
import { getQuestionFieldName } from '../../../utils/question.utils';
import { weightValidator } from '../../../utils/validations.utils';

interface IWeightQuestionProps {
  question: IQuestion;
}

const WeightQuestion: React.FC<IWeightQuestionProps> = ({ question }) => {
  return (
    <div className="padding1 bg-white border-round flex-item-center">
      <p className="h3">{question.text}</p>
      <Field
        component={FieldWeight}
        name={getQuestionFieldName(question.id)}
        validate={question.optional ? noop : weightValidator}
      />
    </div>
  );
};

export default WeightQuestion;
