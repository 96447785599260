import React from 'react';
import '../css/femmar.css';
import { useNavigate, useLocation } from 'react-router-dom';

const HamburgerComp = ({ isLoggedIn }: { isLoggedIn?: boolean }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const currentLocation = location.pathname;

  const handleNavigate = (path: string) => {
    if (isLoggedIn) {
      navigate(path);
    } else {
      window.open(path, '_blank');
    }
  };

  return (
    <div className="flex flex-col flex-item-center gap0 width9030">
      <div className="flex flex-col flex-start">
        {isLoggedIn && currentLocation !== '/Resources' && (
          <button
            type="button"
            onClick={() => navigate('/Resources')}
            className="hamburger-btn">
            Resources
          </button>
        )}

        {isLoggedIn && currentLocation !== '/AccountSettings' && (
          <button
            type="button"
            onClick={() => navigate('/AccountSettings')}
            className="hamburger-btn">
            Account Settings
          </button>
        )}

        {/* {isLoggedIn && currentLocation !== '/Recommendations' && (
          <button
            type="button"
            onClick={() => navigate('/Recommendations')}
            className="hamburger-btn">
            My Recommendations
          </button>
        )} */}
        {isLoggedIn && currentLocation !== '/MyAppointments' && (
          <button
            type="button"
            onClick={() => navigate('/MyAppointments')}
            className="hamburger-btn">
            My Appointments
          </button>
        )}

        {/* {isLoggedIn && currentLocation !== '/DayOneForm' && (
          <button
            type="button"
            onClick={() => navigate('/DayOneForm')}
            className="hamburger-btn">
           Pre Consultation
          </button>
        )} */}

        {isLoggedIn && currentLocation !== '/Contact' && (
          <button
            type="button"
            // onClick={() => navigate('/Contact')}
            onClick={() => {
              window.open('https://femmar.atlassian.net/servicedesk/customer/portal/2', '_blank');
            }}
            className="hamburger-btn">
            Contact Us
          </button>
        )}

        {currentLocation !== '/Terms' && (
          <button
            type="button"
            onClick={() => handleNavigate('/Terms')}
            className="hamburger-btn">
            Terms & Conditions
          </button>
        )}

        {currentLocation !== '/Privacy' && (
          <button
            type="button"
            onClick={() => handleNavigate('/Privacy')}
            className="hamburger-btn">
            Privacy
          </button>
        )}
      </div>
    </div>
  );
};

export default HamburgerComp;
