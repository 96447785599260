import {
  IUser,
  IUserAttributes,
  UpdateUserFormValues,
  UserPreferenceKeys,
} from '../types/models/User';

export const formatUpdateUserParams = (
  params: UpdateUserFormValues,
  user: IUser,
): Partial<IUser> => {
  const {
    firstName,
    lastName,
    phoneNumber,
    companyName,
    jobTitle,
    Notification,
    PromotionalMessage,
  } = params;
  return {
    firstName,
    lastName,
    phoneNumber,
    userAttributes: (companyName || jobTitle || user.userAttributes?.id
      ? {
          id: user.userAttributes?.id,
          companyName,
          jobTitle,
        }
      : undefined) as IUserAttributes,
    userPreferences: [
      ...(Notification
        ? [
            {
              id: user.userPreferences?.find(
                (preference) =>
                  preference.key === UserPreferenceKeys.Notification,
              )?.id,
              key: UserPreferenceKeys.Notification,
              value: Notification,
            },
          ]
        : []),
      ...(PromotionalMessage || []).map((value) => ({
        id: user.userPreferences?.find(
          (preference) =>
            preference.key === UserPreferenceKeys.PromotionalMessage &&
            preference.value === value,
        )?.id,
        key: UserPreferenceKeys.PromotionalMessage,
        value,
      })),
    ],
  };
};
