import React from 'react';
import '../css/femmar.css';
import { useNavigate,useLocation } from 'react-router-dom';
import iconCourseProgress from '../images/icons/iconCourseProgress.png';
import iconPhytoTracker from '../images/icons/iconPhytoTracker.png';
import iconMenoMeter from '../images/icons/iconMenoMeter.png';
import iconMealPlans from '../images/icons/iconMealPlans.png';
import iconShop from '../images/icons/iconShop.png';
import iconConsult from '../images/icons/iconConsult.png';


// const currentLocation = window.location.pathname;

const BigPinkBtns = () => {
  const navigate = useNavigate();

  const handlePhytoTracker = () => {
    navigate('/PhytoTracker');
  };

  const handleCourseProgress = () => {
    navigate('/SixWeekMenu');
  };
  const handleConsult = () => {
    navigate('/Consult');
  };

  const handleSymptomReport = () => {
    navigate('/SymptomReport');
  };

  const handleMealPlans = () => {
    navigate('/MealPlans');
  };

  const handleShop = () => {
    // navigate('/Shop');
    window.open("https://www.femmar.com/shop", '_blank');
  };
  const location = useLocation();
  const currentLocation = location.pathname;
  // console.log('dc',currentLocation)
  return (
    <div className="">
      {/*  ------------------- ROW 5 big buttons ---------------------- */}

      <div className="flex flex-row flex-item-center flex-space-around flex-wrap gap1  width9060">
        {currentLocation !== '/SymptomReport' && (
          <button
            type="button"
            onClick={handleSymptomReport}
            className="headerBtn-big">
            <div className="flex flex-col flex-item-center flex-space-around">
              <img src={iconMenoMeter} className="menu-icon" alt="" />
              <p className="font14px text-center">Symptom Report</p>
            </div>
          </button>
        )}

        {currentLocation !== '/PhytoTracker' && (
          <button
            type="button"
            onClick={handlePhytoTracker}
            className="headerBtn-big">
            <div className="flex flex-col flex-item-center flex-space-around">
              <img src={iconPhytoTracker} className="menu-icon" alt="" />
              <p className="font14px text-center">Phyto Tracker</p>
            </div>
          </button>
        )}

        {currentLocation !== '/CourseProgress' && (
          <button
            type="button"
            onClick={handleCourseProgress}
            className="headerBtn-big">
            <div className="flex flex-col flex-item-center flex-space-around">
              <img src={iconCourseProgress} className="menu-icon" alt="" />
              <p className="font14px text-center">Program Progress</p>
            </div>
          </button>
        )}

        {currentLocation !== '/MealPlans' && (
          <button
            type="button"
            onClick={handleMealPlans}
            className="headerBtn-big">
            <div className="flex flex-col flex-item-center flex-space-around">
              <img src={iconMealPlans} className="menu-icon" alt="" />
              <p className="font14px text-center">Meal Plan</p>
            </div>
          </button>
        )}

        {currentLocation !== '/Shop' && (
          <button type="button" onClick={handleShop} className="headerBtn-big">
            <div className="flex flex-col flex-item-center flex-space-around">
              <img src={iconShop} className="menu-icon" alt="" />
              <p className="font14px text-center">Shop</p>
            </div>
          </button>
        )}

        {currentLocation !== '/Consult' && (
          <button
            type="button"
            onClick={handleConsult}
            className="headerBtn-big">
            <div className="flex flex-col flex-item-center flex-space-around">
              <img src={iconConsult} className="menu-icon" alt="" />
              <p className="font14px text-center">Book a Consult</p>
            </div>
          </button>
        )}
        
      </div>
    </div>
  );
};

export default BigPinkBtns;
