import React from 'react';
import ReactPlayer from 'react-player/vimeo';

import '../css/femmar.css';

interface VideoCompProps {
  url: string;
}

// const getWidth = () => {  window.innerHeight > window.innerWidth ? "800px" : "370px";

const VideoComp: React.FC<VideoCompProps> = ({ url }) => {
  return (
    <div className="margin-vertical-minus4">
      <ReactPlayer
        url={url}
        controls
        width="370px"
      />
    </div>
  );
};

export default VideoComp;
