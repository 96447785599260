import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/femmar.css';

import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import BigPinkBtns from '../components/BigPinkBtns';
import MyPlanComp from '../components/MyPlanComp';
import LessonPlanModuleComp from '../components/LessonPlanModuleComp';

import FooterBtns from '../components/FooterBtns';
import myContent from '../jsonContent/trans_SixWk2.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';
import SixWk2Image from '../images/pictures/weekly/week2.jpg';

import {
  changeCurrentStep,
  completeWeekStep,
  selectCurrentStep,
} from '../redux/slices/visitedscreens';

const SixWk2 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(selectCurrentStep);

  const gotoScreen = (screen: string) => {
    dispatch(completeWeekStep({ step: currentStep }));
    dispatch(changeCurrentStep(screen));
    navigate(`/${screen}`);
  };
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, menuItem1, menuItem2, menuItem3, menuItem4, menuItem5 } =
    myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <BigPinkBtns />
      <MyPlanComp />

      <img className="theBigImage" src={SixWk2Image} alt="" />

      <div className="flex flex-col flex-item-center flex-space-around gap1">
        <LessonPlanModuleComp screen="SixWk2L1GoodShape" menuItem={menuItem1} />
        <LessonPlanModuleComp screen="SixWk2L2Robbers" menuItem={menuItem2} />

        <LessonPlanModuleComp
          screen="SixWk2L3RightFoods"
          menuItem={menuItem3}
        />
        <LessonPlanModuleComp screen="SixWk2L4Research" menuItem={menuItem4} />
        <LessonPlanModuleComp screen="SixWk2L5Quiz" menuItem={menuItem5} />
      </div>

      <FooterBtns />
    </div>
  );
};

export default SixWk2;
