import React, { useState } from 'react';
import { omit } from 'lodash';
import '../css/femmar.css';
import { useNavigate } from 'react-router';
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';
import FormTemplate from '../components/template/FormTemplate';
import { showErrorMessage, showSuccsesMessage } from '../utils/banner.utils';
import { changePassword } from '../api/user';
import { ChangePasswordFormValues } from '../types/models/User';
import ChangePasswordForm from '../components/Organisms/ChangePasswordFrom/ChangePasswordFrom';
import { AppRoutes } from '../constants/routes';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (values: ChangePasswordFormValues) => {
    try {
      console.log(values);
      setLoading(true);
      await changePassword(omit(values, 'confirmNewPassword'));
      showSuccsesMessage('Password changed successfully');
      navigate(AppRoutes.AccountSettings);
    } catch (err) {
      showErrorMessage(err);
    }
    setLoading(false);
  };
  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <p className="h1">Change Password</p>

      <div className="page-text page-text-portal">
        <FormTemplate
          Component={ChangePasswordForm}
          onSubmit={onSubmit}
          loading={loading}
        />
      </div>

      <FooterCopyright />
    </div>
  );
};

export default ChangePassword;
