import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/femmar.css';

import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import BigPinkBtns from '../components/BigPinkBtns';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getAllProgramLessonsAction, getProgramsAction } from '../redux/actions/sixweekprograms';
import { getCompletedStepsAction } from '../redux/actions/visitedscreens';

const SixWeekMenu = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const completedSteps = useAppSelector(
    ({ visitedscreens }) => visitedscreens.completedSteps,
  );

  const programs = useAppSelector(
    ({ sixweekprograms }) => sixweekprograms.programs,
  );

  const arrayScreens = useAppSelector(
    ({ sixweekprograms }) => sixweekprograms.allProgramLessons,
  );

  useEffect(() => {
    dispatch(getProgramsAction());
    dispatch(getCompletedStepsAction())
    dispatch(getAllProgramLessonsAction())
  }, []);

  const getWeekStatistic = (weekNum: number, id: number) => {
    const screensLength =
      arrayScreens.filter((screen) => screen.week_id === id)
        .length; // remove the default scree count

    const completedStepsLength = completedSteps.filter((screen) =>
      screen.endsWith(`/${weekNum}`),
    ).length;


    const percentage = Math.round((completedStepsLength / screensLength) * 100);
    return percentage === 0 ? '' : `${percentage}%`;
  };

  const isWeekComplete = (weekNum: number, id: number) => {
    const screensLength =
      arrayScreens.filter((screen) => screen.week_id === id)
        .length; // remove the default scree count

    const completedStepsLength = completedSteps.filter((screen) =>
      screen.endsWith(`/${weekNum}`),
    ).length;


    const result =
      Math.round((completedStepsLength / screensLength) * 100) >= 100;
    /*    console.log(result); */

    return result;
  };

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />

      <PageTitleComp pageTitle="Six-Week Program Modules Menu" />

      <BigPinkBtns />

      <div className="flex flex-col flex-item-center flex-space-around gap-half">
        {
          programs.map(item => (
            <button
              key={item.week_number}
              type="button"
              onClick={() => navigate(`/SixWk/${item.id}`)}
              className="sixWeekMenu-btn">
              <div className="flex flex-row flex-item-center flex-space-around gap1 padding-half">
                <p
                  className={
                    getWeekStatistic(item.week_number, item.id) === '100%'
                      ? 'bg-femmar-purple sixWeekMenu-blob flex1'
                      : 'bg-femmar-violet sixWeekMenu-blob flex1'
                  }>
                  {item.label}
                </p>
                <p className="flex7 color-black">Week {item.week_number}: {item.week_name}</p>

                <p className="flex1 tickNoBorder">
                  {isWeekComplete(item.week_number, item.id) ? '\u2713' : '  '}
                </p>
              </div>
            </button>
          ))
        }


        {/* <button
          type="button"
          onClick={() => navigate('/SixWk1')}
          className="sixWeekMenu-btn">
          <div className="flex flex-row flex-item-center flex-space-around gap1 padding-half">
            <p
              className={
                getWeekStatistic(1) === '100%'
                  ? 'bg-femmar-purple sixWeekMenu-blob flex1'
                  : 'bg-femmar-violet sixWeekMenu-blob flex1'
              }>
              1
            </p>
            <p className="flex7 color-black">Week 1: Jump Start your Journey</p>
            <p className="flex1 tickNoBorder">
              {isWeekComplete(1) ? '\u2713' : '  '}
            </p>
          </div>
        </button> */}

        {/* <button
          type="button"
          onClick={() => navigate('/SixWk2')}
          className="sixWeekMenu-btn">
          <div className="flex flex-row flex-item-center flex-space-around gap1 padding-half">
            <p
              className={
                getWeekStatistic(2) === '100%'
                  ? 'bg-femmar-purple sixWeekMenu-blob flex1'
                  : 'bg-femmar-violet sixWeekMenu-blob flex1'
              }>
              2
            </p>

            <p className="flex7 color-black">Week 2: Hormones and Diet</p>
            <p className="flex1 tickNoBorder">
              {isWeekComplete(2) ? '\u2713' : '  '}
            </p>
          </div>
        </button>

        <button
          type="button"
          onClick={() => navigate('/SixWk3')}
          className="sixWeekMenu-btn">
          <div className="flex flex-row flex-item-center flex-space-around gap1 padding-half">
            <p
              className={
                getWeekStatistic(3) === '100%'
                  ? 'bg-femmar-purple sixWeekMenu-blob flex1'
                  : 'bg-femmar-violet sixWeekMenu-blob flex1'
              }>
              3
            </p>
            <p className="flex7 color-black">
              Week 3: Replacing Estrogen Naturally
            </p>
            <p className="flex1 tickNoBorder">
              {isWeekComplete(3) ? '\u2713' : '  '}
            </p>
          </div>
        </button>

        <button
          type="button"
          onClick={() => navigate('/SixWk4')}
          className="sixWeekMenu-btn">
          <div className="flex flex-row flex-item-center flex-space-around gap1 padding-half">
            <p
              className={
                getWeekStatistic(4) === '100%'
                  ? 'bg-femmar-purple sixWeekMenu-blob flex1'
                  : 'bg-femmar-violet sixWeekMenu-blob flex1'
              }>
              4
            </p>
            <p className="flex7 color-black">
              Week 4: Supplements, Exercise, Relaxation
            </p>
            <p className="flex1 tickNoBorder">
              {isWeekComplete(4) ? '\u2713' : '  '}
            </p>
          </div>
        </button>

        <button
          type="button"
          onClick={() => navigate('/SixWk5')}
          className="sixWeekMenu-btn">
          <div className="flex flex-row flex-item-center flex-space-around gap1 padding-half">
            <p
              className={
                getWeekStatistic(5) === '100%'
                  ? 'bg-femmar-purple sixWeekMenu-blob flex1'
                  : 'bg-femmar-violet sixWeekMenu-blob flex1'
              }>
              5
            </p>
            <p className="flex7 color-black">Week 5: Sex, Stress and Sleep</p>
            <p className="flex1 tickNoBorder">
              {isWeekComplete(5) ? '\u2713' : '  '}
            </p>
          </div>
        </button>

        <button
          type="button"
          onClick={() => navigate('/SixWk6')}
          className="sixWeekMenu-btn">
          <div className="flex flex-row flex-item-center flex-space-around gap1 padding-half">
            <p
              className={
                getWeekStatistic(6) === '100%'
                  ? 'bg-femmar-purple sixWeekMenu-blob flex1'
                  : 'bg-femmar-violet sixWeekMenu-blob flex1'
              }>
              6
            </p>
            <p className="flex7 color-black">
              Week 6: Memory, Hair, Positivity
            </p>
            <p className="flex1 tickNoBorder">
              {isWeekComplete(6) ? '\u2713' : '  '}
            </p>
          </div>
        </button> */}
      </div>
    </div>
  );
};

export default SixWeekMenu;
