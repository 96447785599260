import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk0L16Portion.json';
import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';
import image from '../images/lessons/W0L16Portion.jpg';

const SixWk0L16Portion = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <LessonPageHeaderComp pageTitle={pageTitle} />
      <img className="theBigImage" src={image} alt="" />

      <p className="page-text">{parse(pageText)}</p>
      <TickComp />
      <FooterBtns />
      <FooterCopyright />
    </div>
  );
};

export default SixWk0L16Portion;
