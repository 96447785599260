import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Table from 'react-bootstrap/Table';
import PageTitle from '../../components/admin/layout/PageTitle';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getAllUsersAction } from '../../redux/actions/admin/users';
import TablePagination from '../../components/admin/molecules/TablePagination/TablePagination';
import UserAddEditModal from '../../components/Organisms/UserAddEditModal/UserAddEditModal';
import { IUser, IUserFormParams } from '../../types/models/User';
import { deleteUser, saveUser } from '../../api/user';
import { showErrorMessage } from '../../utils/banner.utils';

const Users = () => {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector((state) => state.adminUsers);
  const [showForm, setShowForm] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>();
  const { list, currentPage, totalPages, total } = users;

  const handleClose = () => {
    setShowForm(false);
    setSelectedUser(undefined);
    setSaving(false);
  };

  const onAddUserClick = () => {
    setShowForm(true);
  };

  const onEditUserClick = (user: IUser) => {
    setShowForm(true);
    setSelectedUser(user);
  };

  const onDeleteuserClick = async (user: IUser) => {
    const result = window.confirm('Are you sure you want to delete this user');
    if (!result) {
      return;
    }
    try {
      await deleteUser(user.id);
      loadUsers(currentPage);
    } catch (e) {
      showErrorMessage(e);
    }
  };

  const loadUsers = (page: number) => {
    dispatch(getAllUsersAction({ page }));
  };

  const onPageChange = (newPage: number) => {
    if (newPage !== currentPage) {
      loadUsers(newPage);
    }
  };

  const onSubmit = async (values: IUserFormParams) => {
    try {
      await saveUser({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        id: selectedUser?.id,
        userAttributes: selectedUser
          ? {
              ...selectedUser.userAttributes,
              userRole: values.userRole,
              userId: selectedUser.id,
              programStartDate:
                selectedUser.userAttributes?.programStartDate || undefined,
            }
          : { userRole: values.userRole },
      });
      loadUsers(currentPage);
    } catch (e) {
      showErrorMessage(e);
    }
    handleClose();
  };

  useEffect(() => {
    loadUsers(1);
  }, []);

  return (
    <div>
      <PageTitle title="Users" />
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div>
                  <h5 className="card-title">List of Users ({total})</h5>
                  <button
                    type="button"
                    onClick={onAddUserClick}
                    className="btn btn-primary">
                    Add New User
                  </button>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Role</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((user) => (
                      <tr key={user.email}>
                        <th scope="row">{user.id}</th>
                        <td>
                          {user.firstName} {user.lastName}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.userAttributes?.userRole}</td>
                        <td>
                          <button
                            type="button"
                            onClick={() => onEditUserClick(user)}
                            className="btn btn-primary">
                            Edit
                          </button>
                          <button
                            type="button"
                            onClick={() => onDeleteuserClick(user)}
                            className="btn btn-danger ms-1">
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="float-end">
                  <TablePagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UserAddEditModal
        handleClose={handleClose}
        showForm={showForm}
        onSubmit={onSubmit}
        loading={saving}
        selectedUser={selectedUser}
      />
    </div>
  );
};

export default Users;
