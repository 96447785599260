import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { updateCompletedStep } from '../redux/actions/visitedscreens';


const TickComp: React.FC<{
  onMarkDone?: () => void;
  isValid?: boolean;
}> = ({ onMarkDone, isValid = true }) => {
  const [saving, setSaving] = useState(false);
  const { completedSteps } = useAppSelector(
    ({ visitedscreens }) => visitedscreens,
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentStep = location.pathname.replace('/', '');

  console.log(" **** currentStep **** ", currentStep);

  const tick = '\u2713';

  const handleBtnComplete = async () => {
    try {
      setSaving(true);
      if (onMarkDone) {
        await onMarkDone();
        if (!isValid) {
          setSaving(false);
          return;
        }
      }
      await dispatch(
        updateCompletedStep({ completedStep: currentStep }),
      ).unwrap();
    } catch (e: any) {
      // alert(e.message);
      console.log(e);
      toast.error(e.message);
    }
    setSaving(false);
  };

  return (
    <div className="flex flex-item-center">
      {completedSteps.includes(currentStep) ? (
        <p className="tick">{tick}</p>
      ) : (
        <button
          type="button"
          disabled={saving}
          onClick={handleBtnComplete}
          className="larger bg-femmar-violet padding1 border-round ">
          {saving ? 'saving...' : 'Mark as done'}
        </button>
      )}
      
    </div>
  );
};

export default TickComp;
