import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import FieldError from '../FieldError/FieldError';

export interface IFieldSelect extends FieldRenderProps<string> {
  className?: string;
  options: { title: string; value: string }[];
  placeholder?: string;
}

const FieldSelect: React.FC<IFieldSelect> = ({
  input,
  meta,
  compact,
  className,
  options,
  placeholder,
}) => {
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    input.onChange(event.target.value || '');
  };

  return (
    <div>
      <select className={className} onChange={onChange}>
        {!!placeholder && (
          <option selected={!input.value}>{placeholder}</option>
        )}
        {options.map((option) => (
          <option
            key={option.title}
            value={option.value}
            selected={option.value === input.value}>
            {option.title}
          </option>
        ))}
      </select>
      <FieldError
        compact={compact}
        visible={!!(meta.touched && meta.error)}
        errorId={meta.error}
      />
    </div>
  );
};

export default FieldSelect;
