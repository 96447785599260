import { createSlice } from '@reduxjs/toolkit';
import { IPaginationResponse } from '../../../types/common';
import { IUser } from '../../../types/models/User';
import { getAllUsersAction } from '../../actions/admin/users';
import { logout } from '../auth';

export interface AdminUsersState {
  users: IPaginationResponse<IUser>;
}

const initialState: AdminUsersState = {
  users: {
    list: [],
    total: 0,
    pageSize: 10,
    currentPage: 1,
    totalPages: 0,
  },
};

export const adminUsersSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.type, (state) => {
      state.users = initialState.users;
    });
    builder.addCase(getAllUsersAction.fulfilled, (state, action) => {
      state.users = action.payload.users;
    });
  },
});

export default adminUsersSlice.reducer;
