export const getErrorMessage = (e: any) => {
  if (!e) {
    return 'something went wrong';
  }

  if (typeof e === 'string') {
    return e;
  }

  if (e.error?.localizedDescription) {
    return e.error.localizedDescription;
  }

  if (e.response?.data?.errors?.message) {
    return e.response.data.errors.message;
  }

  if (e.errors?.message) {
    return e.errors.message;
  }

  return e.response?.data?.message || e.message;
};
