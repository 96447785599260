import React from 'react';
import { Field } from 'react-final-form';
import { noop } from 'lodash';
import FieldMultipleChoice from '../FieldMultipleChoice/FieldMultipleChoice';
import { IQuestion } from '../../../types/models/Quiz';
import { getQuestionFieldName } from '../../../utils/question.utils';
import { arrayRequiredValidator } from '../../../utils/validations.utils';

interface IMultipleChoiceQuestionProps {
  question: IQuestion;
  correctValue?: number[];
}

const MultipleChoiceQuestion: React.FC<IMultipleChoiceQuestionProps> = ({
  question,
  correctValue,
}) => {
  return (
    <div className="padding1 bg-white border-round" >
      <p className="h3">{question.text}</p>
      <Field
        component={FieldMultipleChoice}
        name={getQuestionFieldName(question.id)}
        options={question.options}
        validate={question.optional ? noop : arrayRequiredValidator}
        correctValue={correctValue}
      />
    </div>
  );
};

export default MultipleChoiceQuestion;
