import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchCompletedStep,
  saveCompletedStep,
} from '../../api/visitedscreens';
import { withToastForError } from '../../utils/thunk.utilts';

export const getCompletedStepsAction = createAsyncThunk<string[], void>(
  'visitedscreens/get',
  withToastForError(async () => {
    const response = await fetchCompletedStep();
    return response.data.steps.map(({ completedStep }) => completedStep);
  }),
);

interface UpdateCompletedStepParams {
  completedStep: string;
}
export const updateCompletedStep = createAsyncThunk<
  UpdateCompletedStepParams,
  UpdateCompletedStepParams
>(
  'visitedscreens/step/completed',
  withToastForError(async (params) => {
    await saveCompletedStep({ step: params.completedStep });
    return params;
  }),
);
