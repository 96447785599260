import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { arrayScreens } from '../../constants/global';
import { logout } from './auth';
import { RootState } from '../store';
import { WeekSymptomAnswer } from '../../types/models/QuestionModel';
import {
  getCompletedStepsAction,
  updateCompletedStep,
} from '../actions/visitedscreens';
import { getAnswersAction, saveAnswersAction } from '../actions/questionAction';

export interface VisitedScreensState {
  completedSteps: string[];
  currentStep: string;
  weekSymptomAnswers: WeekSymptomAnswer[];
}

const initialState: VisitedScreensState = {
  completedSteps: [],
  // currentStep: Seems like it is not necessary anymore but need to look into it. more testing needed before we remove this property
  currentStep: arrayScreens[0],
  weekSymptomAnswers: [],
};

export const VisitedScreensSlice = createSlice({
  name: 'visitedscreens',
  initialState,
  reducers: {
    completeWeekStep: (state, action: PayloadAction<{ step: string }>) => {
      const { step } = action.payload;
      if (!state.completedSteps.includes(step)) {
        state.completedSteps.push(step);
      }
    },
    changeCurrentStep: (state, action: PayloadAction<string>) => {
      state.currentStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.type, (state) => {
      state.completedSteps = initialState.completedSteps;
      state.currentStep = initialState.currentStep;
      state.weekSymptomAnswers = initialState.weekSymptomAnswers;
    });
    builder.addCase(getCompletedStepsAction.fulfilled, (state, action) => {
      state.completedSteps = action.payload;
    });
    builder.addCase(getAnswersAction.fulfilled, (state, action) => {
      state.weekSymptomAnswers = action.payload;
    });
    builder.addCase(saveAnswersAction.fulfilled, (state, action) => {
      if (state.weekSymptomAnswers?.length) {
        state.weekSymptomAnswers = state.weekSymptomAnswers.filter(
          (answer) => action.payload[0].weekNumber !== answer.weekNumber,
        );
      }
      state.weekSymptomAnswers = [
        ...(state.weekSymptomAnswers || []),
        ...action.payload,
      ];
    });
    builder.addCase(
      updateCompletedStep.fulfilled,
      (state, action: PayloadAction<{ completedStep: string }>) => {
        const { completedStep } = action.payload;
        if (!state.completedSteps.includes(completedStep)) {
          state.completedSteps.push(completedStep);
        }
      },
    );
  },
});

export const { completeWeekStep, changeCurrentStep } =
  VisitedScreensSlice.actions;

export const selectWeekSymptomAnswers = (state: RootState) =>
  state.visitedscreens.weekSymptomAnswers || [];

export const selectCurrentStep = (state: RootState) =>
  state.visitedscreens.currentStep;

export default VisitedScreensSlice.reducer;
