import {
  CorrectAnswer,
  SaveResultAnswerDto,
  SaveResultDto,
} from '../types/models/ApiResult';
import { QuestionTypes } from '../types/models/QuestionTypes';
import { IQuestion } from '../types/models/Quiz';
import { WeekSymptomAnswer } from '../types/models/QuestionModel';
import {
  HeightUnits,
  HeightValue,
  WeightUnits,
  WeightValue,
} from '../types/types';
import { getQuestionFieldName, getQuestionIdFieldName } from './question.utils';
import {
  convertCMToFeetInches,
  convertFeetInchesToCM,
  convertKilogramsToPounds,
  convertPoundsToKilograms,
} from './unit.utils';

type SubQuestionAnswerDto = { questionId: number; answer: number };

export const formatValuesToSave = (
  values: Record<
    string,
    SubQuestionAnswerDto[] | number | number[] | HeightValue | WeightValue
  >,
  weekNumber?: number,
) => {
  const result = Object.keys(values).reduce((partial, currentValue) => {
    let answers: SaveResultAnswerDto = [];
    if (typeof values[currentValue] === 'number') {
      // single choice answer
      answers.push({ selectedOptionId: values[currentValue] as number });
    } else if (
      Array.isArray(values[currentValue]) &&
      typeof (values[currentValue] as number[])[0] === 'number'
    ) {
      // multiple choice answer
      (values[currentValue] as number[]).forEach((element) => {
        answers.push({ selectedOptionId: element });
      });
    } else if (
      Object.values(HeightUnits).includes(
        (values[currentValue] as HeightValue)?.unit,
      )
    ) {
      // height answer
      const value = values[currentValue] as HeightValue;
      // const height =
      //   value.unit === HeightUnits.CM
      //     ? value.cm
      //     : convertFeetInchesToCM(value.feet as number, value.inches as number);
      const height =
        value.unit === HeightUnits.CM
          ? value.cm
          : `${value.feet }.${ value.inches}`;

      answers.push({
        value: `${height}`,
      });
      answers.push({ value: value.unit });
    } else if (
      Object.values(WeightUnits).includes(
        (values[currentValue] as WeightValue)?.unit,
      )
    ) {
      // height answer
      const value = values[currentValue] as WeightValue;
      // const height =
      //   value.unit === WeightUnits.KG
      //     ? value.weight
      //     : convertPoundsToKilograms(value.weight as number);
      const height =
        value.unit === WeightUnits.KG
          ? value.weight
          : value.weight;

      answers.push({
        value: `${height}`,
      });
      answers.push({ value: value.unit });
    } else {
      // nested choice answer
      // eslint-disable-next-line no-lonely-if
      if (Array.isArray(values[currentValue])) {
        answers = (values[currentValue] as SubQuestionAnswerDto[]).map(
          (value) => ({
            questionId: value.questionId,
            selectedOptionId: value.answer,
          }),
        );
      } else {
        // Handle non-array case here
      }
    }
    partial.push({
      questionId: getQuestionIdFieldName(currentValue),
      answers,
      weekNumber,
    });
    return partial;
  }, [] as SaveResultDto[]);
  return result;
};

export const formatValuesToForm = (
  values: WeekSymptomAnswer[],
  Questionaire: IQuestion[],
) => {
  const formValues = Questionaire.reduce((partial, currentValue) => {
    const fieldName = getQuestionFieldName(currentValue.id);
    const value = values.find(
      (existingValue) => existingValue.questionId === currentValue.id,
    );
    if (!value) {
      return partial;
    }
    switch (currentValue.type) {
      case QuestionTypes.SINGLE_CHOICE:
        partial[fieldName] = value.answers[0].selectedOptionId;
        break;
      case QuestionTypes.MULTIPLE_CHOICE:
        partial[fieldName] = value.answers.map(
          (existingValue) => existingValue.selectedOptionId,
        );
        break;
      case QuestionTypes.NESTED_MULTIPLE_CHOICE:
        partial[fieldName] = value.answers.map((existingValue) => ({
          ...existingValue,
          answer: existingValue.selectedOptionId,
        }));
        break;
      case QuestionTypes.HEIGHT:
        {
          const heightValues = value.answers.map(({ value: v }) => v);

          const { feet, inches } = convertCMToFeetInches(
            parseInt(heightValues[0] as string, 10),
          );
          partial[fieldName] = {
            unit: heightValues[1],
            cm: heightValues[1] === HeightUnits.CM ? heightValues[0] : '',
            feet: heightValues[1] === HeightUnits.FT ? feet : '',
            inches: heightValues[1] === HeightUnits.FT ? inches : '',
          } as HeightValue;
        }
        break;
      case QuestionTypes.WEIGHT:
        {
          const weightValues = value.answers.map(({ value: v }) => v);
          const weight = parseInt(weightValues[0] as string, 10);
          const lbs = Math.ceil(convertKilogramsToPounds(weight as number));
          partial[fieldName] = {
            unit: weightValues[1],
            weight: weightValues[1] === WeightUnits.LBS ? lbs : weight,
          } as WeightValue;
        }
        break;
      default:
        break;
    }
    return partial;
  }, {} as Record<string, any>);
  return formValues;
};

export const verifyIfAnswersAreCorrect = (
  userInput: SaveResultDto[],
  correctInput: CorrectAnswer[],
) => {
  let isCorrect = true;
  for (let i = 0; i < userInput.length; i += 1) {
    const userAnswerIds = userInput[i].answers.map(
      (answer) => answer.selectedOptionId,
    );
    const question = correctInput.find(
      ({ questionId }) => questionId === userInput[i].questionId,
    );
    if (!question) {
      isCorrect = false;
      break;
    }
    isCorrect = question.answers.length === userAnswerIds.length;
    if (isCorrect) {
      isCorrect = question.answers.every((id) => userAnswerIds.includes(id));
    }
    if (!isCorrect) {
      break;
    }
  }
  return isCorrect;
};
