import { createAsyncThunk } from '@reduxjs/toolkit';
import { QuizTypes } from '../../types/models/QuizTypes';
import { fetchQuizByType } from '../../api/quiz';
import { IQuiz } from '../../types/models/Quiz';
import { withToastForError } from '../../utils/thunk.utilts';

interface Params {
  type: QuizTypes;
  weekNumber?: number;
}

export const getQuizByTypeAction = createAsyncThunk<{ quiz: IQuiz }, Params>(
  'quiz/getByType',
  withToastForError(async ({ type, weekNumber }: Params) => {
    const response = await fetchQuizByType(type, weekNumber);
    return response.data;
  }),
);
