import React, { useState } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import '../css/femmar.css';

interface StepProgressBarProps {
  steps: number;
  currentStep: number;
}

const FormProgressBar: React.FC<StepProgressBarProps> = ({ steps, currentStep }) => {
  const [percentage, setPercentage] = useState((currentStep / steps) * 100);

  return (
    <div className="ms-3 ms-md-0">
      <ProgressBar
        completed={percentage}
        bgColor="#d16eff"
        labelSize="0px"
        height="20px"
        width="330px"

      />
      <div className="text-center mt-2 day-one-head">
        <b>Step {currentStep} of {steps} </b>
      </div>
    </div>
  );
};

export default FormProgressBar;
