import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { directusClient } from '../api/client';
import myContent from '../jsonContent/trans_Faqs01.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';

interface FAQItem {
  id: number;
  question: string;
  answer: string;
}

const FaqDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [faqItem, setFaqItem] = useState<FAQItem | null>(null);



  useEffect(() => {
    const fetchFaqItem = async () => {
      try {
        const response = await directusClient.get(`/items/TransFaqs/${id}`);
        setFaqItem(response.data.data);
      } catch (error) {
        console.error('Error fetching FAQ item:', error);
      }
    };

    fetchFaqItem();
  }, [id]);

  if (!faqItem) {
    return <div>Loading...</div>;
  }

  return (

    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <hr className="width9060" />
      <p className="h2 mt-3">{faqItem.question}</p>
      <div className="color-navy mb-3 p-5" dangerouslySetInnerHTML={{ __html: faqItem.answer || '' }} />
      <FooterCopyright />
    </div>
  );
};

export default FaqDetail;
