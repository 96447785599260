// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../redux/store';
import { useQuery } from '../hooks/useQuery';

import TopComp from '../components/TopComp';

import { client } from '../api/client';

const RecommendedPlan = () => {

    const [appointment, setAppointment] = useState(null as any);
    const [loading, setLoading] = useState(true);

    const Print = (e: any) => {
        e.preventDefault();
        const mywindow = window.open("", "PRINT");
        mywindow?.document.write(
            '<html><head></head><body>'
        );
        mywindow?.document.write((document.getElementById("printablediv")?.innerHTML as string));
        mywindow?.document.write("</body></html>");
        mywindow?.document.close();
        mywindow?.focus();
        setTimeout(() => {
            mywindow?.print();
            mywindow?.close();
        }, 100);

        return true;
    }

    useEffect(() => {

        client.get('/nutritionist/get-existing-appointment').then((response: any) => {
            const { data }: any = response.data
            setAppointment(data);
            setLoading(false);
        })

    }, []);

    if (loading) {
        return <div>Please wait...</div>;
    }
    return (
        <div className="flex flex-col flex-item-center flex-space-between gap1">
            <TopComp />
            {
                appointment ? <div className="container"><div id="printablediv" dangerouslySetInnerHTML={{ __html: appointment.recommendation_doc }} />
                    <div style={{ marginTop: "35px", marginBottom: "35px" }}><a className="nutrional-dash-btn text-dark mb-4" href="#" onClick={Print}>Print</a></div></div> : <p>No recommendations for you</p>
            }

        </div>
    );
};

export default RecommendedPlan;
