import React from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
/* import '../css/progressBar.css'; */
/* import '../css/progressBar.scss'; */
import { arrayScreens } from '../constants/global';
import { useAppSelector } from '../redux/store';

/* //////////////////////////////////////////////////////////
// https://github.com/KaterinaLupacheva/react-progress-bar //
///////////////////////////////////////////////////////////// */

const ProgressBarComp = () => {
  const completedSteps = useAppSelector(
    ({ visitedscreens }) => visitedscreens.completedSteps,
  );

  let percentage = Math.round(
    (completedSteps.length / arrayScreens.length) * 100,
  );

  
  percentage = percentage > 100 ? 100 : percentage;

  return (
    <div className="flex flex-row width9030 margin-top-02R">
  
      <ProgressBar className="flex5 width60" completed={percentage} bgColor="#d16eff" labelColor="black" />

    </div>
  );
};

export default ProgressBarComp;
