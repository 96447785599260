import React, { useEffect, useState ,useRef} from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { client } from '../../api/client';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getAppointmentAction } from '../../redux/actions/appointmentpool';
import { selectAppointment } from '../../redux/slices/appointmentpool';

const schema = Yup.object().shape({
  notes: Yup.string().required("Notes are required")
})

const Nutrionistnotes = () => {
  const appointmentData = useAppSelector(selectAppointment)

  const [initialValues, setInitialValues] = useState({ notes: '' });
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const lastTypingTimestamp = useRef<number>(0);
  const [isAutoSaveNotificationShown, setIsAutoSaveNotificationShown] = useState(false);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const appointmentId = localStorage.getItem('appointmentId');
// console.log('nikhil',appointmentId)
    if (appointmentId) {
      dispatch(getAppointmentAction({ id: parseInt(appointmentId, 10) }));
    }
  }, []);

  useEffect(() => {
    if (appointmentData) {
      setInitialValues({
        notes: appointmentData.during_appointment
      })
    }
  }, [appointmentData]);

  useEffect(() => {
    // when isAutoSaveNotificationShown becomes true ,display notication
    if (isAutoSaveNotificationShown) {
      notify();
      
    }
  }, [isAutoSaveNotificationShown]);

  const notify = () => toast('Notes Saved Successfully!');
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize
      onSubmit={(values) => {
        const appointmentId = localStorage.getItem('appointmentId');

        if (appointmentId && values.notes.trim().length > 0) {
          client.post('/nutritionist/update-appointment', {
            id: parseInt(appointmentId, 10),
            during_appointment: values.notes,
          }).then((response: any) => {
            if (!isAutoSaveNotificationShown) {
              notify();
              
            }
          })
        }
      }}
    >
      {({ values, handleChange, submitForm }) => (
      <Form>
        <div className="flex flex-col flex-item-center flex-space-between gap1 mt-3">
          <p className="nutrional-dash-heading">
            Nutritionist Notes During Consultation
          </p>
          
          <Alert variant="warning">
            <p className="text-dark">
            You can utilize below textbox for adding notes during consultation.
            </p>
          </Alert>
          <label className="text-left">
            <b>Notes</b>
          </label>
          <Field as="textarea" name="notes" rows="8" className="p-3 nutrional-dash-notes-input "
         onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          handleChange(e);
          lastTypingTimestamp.current = Date.now();
          setIsAutoSaving(true);

          setTimeout(() => {
            const currentTime = Date.now();
            const timeSinceLastTyping = currentTime - lastTypingTimestamp.current;

            if (timeSinceLastTyping >= 10000) {
              setIsAutoSaving(false);
              submitForm();
            } else {
              setIsAutoSaving(false);
            }
          }, 10000);
        }}
           />
          <ErrorMessage
            name="notes"
            component="div"
            className="text-danger mb-1 nutrional-form-error"
          />
          {isAutoSaving && values.notes.trim().length > 0 && <p className="">Auto-saving...</p>}
          <button type="submit" className="nutrional-dash-btn text-dark"
             onClick={() => {
                setIsAutoSaving(false);
                submitForm();
                if (values.notes.trim().length > 0 && !isAutoSaveNotificationShown) {
    
                  setIsAutoSaveNotificationShown(true);
                }
              }}>
            Save
          </button>
        </div>
        
      </Form>
      )}
    </Formik>
  );
};

export default Nutrionistnotes;
