/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
import React,{useState,useEffect} from 'react'
import '../css/femmar.css';
import { Alert, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import TopComp from '../components/TopComp'
import PageTitleComp from '../components/PageTitleComp';
import FooterCopyright from '../components/FooterCopyright';
import { client } from '../api/client';


const MyAppointments = () => {
  const pagetitle= "My Appointments";
  const [userData, setUserData] = useState<any[] | null>(null);
  // const [dietDiary, setDietDiary] = useState<any[] | null>(null);
  const navigate= useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [appointmentsPerPage] = useState(10);

  const handleEyeIconClick = (appointmentId: string, orderId: string | null) => {
    navigate(`/DayOneForm/${appointmentId}/${orderId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        const response = await client.get(
          `nutritionist/get-user-appointments?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        // console.log('his',response.data.data);
        setUserData(response.data.data);
      } catch (error) {
        console.error('Error fetching user appointments:', error);
      }
    };
   
    fetchData();
  }, []);

const indexOfLastAppointment = currentPage * appointmentsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
  const currentAppointments = userData?.slice(indexOfFirstAppointment, indexOfLastAppointment);
  const totalPages = Math.ceil((userData?.length || 0) / appointmentsPerPage);
  return (
    <div className="flex flex-col flex-item-center ">
    <TopComp/>
    <PageTitleComp pageTitle={pagetitle}/>
    
      {currentAppointments &&currentAppointments.length >0 ?(
        <div>
        <table >
      <thead>
        <tr>
          <th className="myapp-head text-light  ">Nutritionist</th>
          <th className="myapp-head text-light  ">Appointment Date</th>
          <th className="myapp-head text-light  ">Appointment Time </th>
          <th className="myapp-head text-light  ">Pre Consultation</th>
        </tr>
      </thead>
      <br />
      <tbody className="mt-2">
        {currentAppointments.map(appointment=>(
          <>
          <tr key={appointment.id}>
          <td className="myapp-data text-dark  ">{appointment.nutritionist.firstName || '-'}</td>
          <td className="myapp-data text-dark  ">{appointment.appointment_date || '-'}</td>
          <td className="myapp-data text-dark  ">{`${appointment.appointment_time_from} ${appointment.time_zone}` || '-'}</td>
          <td className="myapp-data text-dark  ">
            {appointment.Is_completed ?(
            <button className="myapp-icon-btn" 
            aria-label={`View appointment with ${appointment.nutritionist.firstName}`}
            onClick={() => handleEyeIconClick(appointment.id, appointment.preconsultation[0]?.orderId)}>
               <i className="myapp-icon bi bi-eye-fill "  />
            </button>
           
          ):(
            <button className="myapp-icon-btn" 
            aria-label={`Edit appointment with ${appointment.nutritionist.firstName}`}
            onClick={() => handleEyeIconClick(appointment.id, appointment.preconsultation[0]?.orderId)}>
              <i className="myapp-icon bi bi-pencil-square "/>
            </button>
            
          )}</td>
        </tr> 
        <br />
        </>
        ))}
        
        
      </tbody>
    </table>
     
    {totalPages > 1 && (
            <Pagination className="mt-3">
              <Pagination.First onClick={() => setCurrentPage(1)} />
              <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
              {Array.from({ length: totalPages }).map((_, index) => {
                const page = index + 1;
                return (
                  <Pagination.Item key={page} active={page === currentPage} onClick={() => setCurrentPage(page)}>
                    {page}
                  </Pagination.Item>
                );
              })}
              <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} />
              <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
            </Pagination>
          )}


  </div>
   
    
      ):(
        <Alert variant="info">
          <p className="text-dark">
         Once the nutritionist confirms the appointment schedule details will be displayed here automatically.
          </p>
        </Alert>
      )}
    
    
    
    <FooterCopyright/>
    </div>
  )
}

export default MyAppointments