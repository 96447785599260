import React from 'react';
import { IQuestionOptions } from '../../../types/models/Quiz';

interface ISingleChoiceProps {
  value: number;
  options: IQuestionOptions[];
  onChange: (value: number) => void;
  correctValue?: number;
}

const SingleChoice: React.FC<ISingleChoiceProps> = ({
  value,
  options,
  onChange,
  correctValue,
}) => {
  const getHiglightClass = (option: IQuestionOptions) => {
    if (!correctValue) return '';
    return correctValue === option.id ? 'highlight' : 'no-highlight';
  };
  return (
    <div className="flex flex-col flex-wrap">
      {options.map((option) => (
        <div
          key={`${option.id}-${option.text}`}
          className={`margin-left1 padding02 text-left ${getHiglightClass(
            option,
          )}`}>
          <input
            type="radio"
            id={`${option.id}-${option.text}`}
            checked={value === option.id}
            onChange={() => onChange(option.id)}
          />

          <label
            htmlFor={`${option.id}-${option.text}`}
            className="padding-half">
            {option.text}
          </label>
        </div>
      ))}
    </div>
  );
};

export default SingleChoice;
