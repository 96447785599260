import React from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import '../css/femmar.css';
import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk1L5Symptoms.json';
import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';

const SixWk1L5Symptoms = () => {
  const navigate = useNavigate();
  const langNum = useAppSelector(selectLanguageIndex);
  const {
    pageTitle,
    pageText,
    link1Caption,
    link2Caption,
    link3Caption,
    link4Caption,
    link5Caption,
    link6Caption,
    link7Caption,
    link8Caption,
  } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <LessonPageHeaderComp pageTitle={pageTitle} />

      <div className="page-text">{parse(pageText)}</div>

      <div className="flex flex-col flex-bunched gap-half">
        <button
          type="button"
          onClick={() => navigate('/SixWk1L5SympHot')}
          className="fmButton">
          {link1Caption}
        </button>

        <button
          type="button"
          onClick={() => navigate('/SixWk1L5SympHeadaches')}
          className="fmButton">
          {link2Caption}
        </button>

        <button
          type="button"
          onClick={() => navigate('/SixWk1L5SympPainful')}
          className="fmButton">
          {link3Caption}
        </button>
        <button
          type="button"
          onClick={() => navigate('/SixWk1L5SympMood')}
          className="fmButton">
          {link4Caption}
        </button>
        <button
          type="button"
          onClick={() => navigate('/SixWk1L5SympInsomnia')}
          className="fmButton">
          {link5Caption}
        </button>
        <button
          type="button"
          onClick={() => navigate('/SixWk1L5SympDry')}
          className="fmButton">
          {link6Caption}
        </button>
        <button
          type="button"
          onClick={() => navigate('/SixWk1L5SympPanic')}
          className="fmButton">
          {link7Caption}
        </button>
        <button
          type="button"
          onClick={() => navigate('/SixWk1L5SympAching')}
          className="fmButton">
          {link8Caption}
        </button>
      </div>
      <TickComp />
      <FooterBtns />
      <FooterCopyright />
    </div>
  );
};

export default SixWk1L5Symptoms;
