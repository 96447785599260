import React from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import '../css/femmar.css';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  changeCurrentStep,
  completeWeekStep,
  selectCurrentStep,
} from '../redux/slices/visitedscreens';
import iconVideo from '../images/icons/iconVideo.png';

interface LessonPlanModuleCompProps {
  id?: number;
  screen: string;
  menuItem: string;
  weekNum?: number
  isQuiz?: boolean
  /*   completed?: boolean; */
}

const LessonPlanModuleComp: React.FC<LessonPlanModuleCompProps> = (props) => {
  const { screen, menuItem, id, weekNum, isQuiz } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(selectCurrentStep);

  const { completedSteps } = useAppSelector(
    ({ visitedscreens }) => visitedscreens,
  );

  const completedNew = isQuiz ? completedSteps.includes(`Quiz/${id}/${weekNum}`) : completedSteps.includes(`SixWkLesson/${id}/${weekNum}`);

  const gotoScreen = () => {
    dispatch(completeWeekStep({ step: currentStep }));
    dispatch(changeCurrentStep(screen));
    if (isQuiz) {
      navigate(`/Quiz/${id}/${weekNum}`);
    } else {
      navigate(`/SixWkLesson/${id}/${weekNum}`);
    }

  };
  

  return (
    <div>
      <button
        type="button"
        onClick={() => gotoScreen()}
        className="weekly-menu-btn">
        <div className="flex flex-row flex-item-center flex-space-between gap0">
          <div className="bg-femmar-purple flex1 video-btn-container margin-half">
            <img src={iconVideo} className="video-btn" alt="" />
          </div>
          <p className="flex7 font14px">{menuItem}</p>
          
          {completedNew && <p className="flex1">&#x2713;</p>}
        </div>
      </button>
    </div>
  );
};

export default LessonPlanModuleComp;
