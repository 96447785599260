import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import TopComp from '../components/TopComp';

import myContent from '../jsonContent/trans_HistoryQuestionnaireIntro.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { AppRoutes } from '../constants/routes';
import {
  changeCurrentStep,
  completeWeekStep,
} from '../redux/slices/visitedscreens';
import { saveCompletedStep } from '../api/visitedscreens';

import FooterCopyright from '../components/FooterCopyright';
import PageTitleComp from '../components/PageTitleComp';
import WelcomeFooterBtns from '../components/WelcomeFooterBtn';

const HistoryQuestionnaireIntro = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const dispatch = useAppDispatch();
  const { pageTitle, pageText } = myContent.trans[langNum];

  useEffect(() => {
    const route = AppRoutes.HistoryQuestionnaireIntro.replace('/', '');
    dispatch(changeCurrentStep(route));
    saveCompletedStep({ step: route });
    dispatch(completeWeekStep({ step: route }));
  }, []);

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />

      <div className="page-text">{parse(pageText)}</div>
      <WelcomeFooterBtns showPrevButton={false} nextButtonLabel="Start Questionnaire" />
      <FooterCopyright />
    </div>
  );
};

export default HistoryQuestionnaireIntro;
