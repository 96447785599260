import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  PhytoFoodItem,
  PhytoFoodItemsPerDate,
} from '../../types/models/PhytoTracker';
import {
  getLogsForMonthActions,
  getPhytoFoodItemsActions,
} from '../actions/phytoracker';
import { logout } from './auth';

export interface PhytoTrackerState {
  items: PhytoFoodItem[];
  monthWideItems: PhytoFoodItemsPerDate[];
}

const initialState: PhytoTrackerState = {
  items: [],
  monthWideItems: [],
};

export const phytoTrackerSlice = createSlice({
  name: 'phytotracker',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.type, (state) => {
      state.items = [];
      state.monthWideItems = [];
    });
    builder.addCase(
      getPhytoFoodItemsActions.fulfilled,
      (state, action: PayloadAction<PhytoFoodItem[]>) => {
        state.items = action.payload;
      },
    );
    builder.addCase(
      getLogsForMonthActions.fulfilled,
      (state, action: PayloadAction<PhytoFoodItemsPerDate[]>) => {
        state.monthWideItems = action.payload;
      },
    );
  },
});

export default phytoTrackerSlice.reducer;
