import React from 'react';
import { Field } from 'react-final-form';
import { noop } from 'lodash';
import FieldNestedChoice from '../FieldNestedChoice/FieldNestedChoice';
import { IQuestion } from '../../../types/models/Quiz';
import { getQuestionFieldName } from '../../../utils/question.utils';
import { useAppSelector } from '../../../redux/store';
import { selectLessonDetail } from '../../../redux/slices/sixweekprogram';

import { arrayRequiredValidator } from '../../../utils/validations.utils';

interface INestedChoiceQuestionProps {
  question: IQuestion;
}

const NestedChoiceQuestion: React.FC<INestedChoiceQuestionProps> = ({
  question,
}) => {
  const lessonDetail = useAppSelector(selectLessonDetail);

  return (
    <div className="padding1 bg-white border-round">
      <h5 style={{'textAlign':'left'}}>{lessonDetail?.program_content}</h5>
      <Field
        component={FieldNestedChoice}
        name={getQuestionFieldName(question.id)}
        options={question.options}
        nestedQuestions={question.nestedQuestions}
        validate={question.optional ? noop : arrayRequiredValidator}
      />
    </div>
  );
};

export default NestedChoiceQuestion;
