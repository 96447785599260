import React from 'react';
import '../css/femmar.css';

const FooterCopyright = () => {
  return (
    <div className="footer-copyright bg-femmar-purple width100">
      © Femmar Health Corp. All Rights Reserved
    </div>
  );
};

export default FooterCopyright;
