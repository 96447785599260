import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/femmar.css';

import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import BigPinkBtns from '../components/BigPinkBtns';
import MyPlanComp from '../components/MyPlanComp';
import LessonPlanModuleComp from '../components/LessonPlanModuleComp';

import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';

import myContent from '../jsonContent/trans_SixWk6.json';
import SixWk6Image from '../images/pictures/weekly/week6.jpg';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  changeCurrentStep,
  completeWeekStep,
  selectCurrentStep,
} from '../redux/slices/visitedscreens';

const SixWk6 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(selectCurrentStep);

  const gotoScreen = (screen: string) => {
    dispatch(completeWeekStep({ step: currentStep }));
    dispatch(changeCurrentStep(screen));
    navigate(`/${screen}`);
  };
  const langNum = useAppSelector(selectLanguageIndex);
  const {
    pageTitle,
    menuItem1,
    menuItem2,
    menuItem3,
    menuItem4,
    menuItem5,
    menuItem6,
    menuItem7,
    menuItem8,
    menuItem9,
    menuItem10,
  } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <BigPinkBtns />
      <MyPlanComp />

      <img className="theBigImage" src={SixWk6Image} alt="" />

      <div className="flex flex-col flex-item-center flex-space-around gap1">
        <LessonPlanModuleComp screen="SixWk6L1Sharp" menuItem={menuItem1} />

        <LessonPlanModuleComp screen="SixWk6L2Hair" menuItem={menuItem2} />

        <LessonPlanModuleComp
          screen="SixWk6L3Positivity"
          menuItem={menuItem3}
        />
        <LessonPlanModuleComp
          screen="SixWk6L4BoneHealth"
          menuItem={menuItem4}
        />

        <LessonPlanModuleComp screen="SixWk6L5Plan" menuItem={menuItem5} />

        <LessonPlanModuleComp screen="SixWk6L6Next" menuItem={menuItem6} />

        <LessonPlanModuleComp screen="SixWk6L7Research" menuItem={menuItem7} />

        <LessonPlanModuleComp screen="SixWk6L8Quiz" menuItem={menuItem8} />

        <LessonPlanModuleComp
          screen="SixWk6L9ProgressQuestionnaire"
          menuItem={menuItem9}
        />
      </div>
      <LessonPlanModuleComp screen="SixWk6L10Congrats" menuItem={menuItem10} />

      <FooterBtns />
      <FooterCopyright />
    </div>
  );
};

export default SixWk6;
