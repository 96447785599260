import { AxiosPromise } from 'axios';
import { client } from './client';

interface ProductSupplementResponse {
  id: number;
  brand: string;
  product: string;
  supplement: string;
  directions: string;
  longDisplayNames: string;
  shortDisplayNames: string;
  link: string | null;
  updatedAt: string;
}

export const getProductSupplement = (): AxiosPromise<{
  personalizedSupplements: any; data: ProductSupplementResponse[] 
}> =>
  client.get('/users/product-suppliments');
