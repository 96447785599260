import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { selectAppointmentUser } from '../redux/slices/appointmentpool';
import Symptoms from './Symptoms';

const groupBy = function (xs: any, key: any) {
    return xs.reduce((rv: any, x: any) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

const NutritionClientDetail = () => {
 
    const dispatch = useAppDispatch();
  
    const appointmentUser = useAppSelector(selectAppointmentUser);
  
    const [userDetail, setUserDetail] = useState(null as any);
    const [dietDiary, setDietDiary] = useState(null as any);
  
    useEffect(() => {
      if (appointmentUser) {
        setUserDetail(appointmentUser);
        const diary = groupBy(appointmentUser.dietDiaryAns.result, 'quizzId');
        setDietDiary(diary);
        
      } else {
        setUserDetail(null);
      }
    }, [appointmentUser]);
  return (
    <div>
        <div className="row">
          <div className="col-12 text-left">
            {userDetail && <p className="nutrional-dash-heading">{userDetail.user.firstName}&apos;s Information:</p>}
            {userDetail && < Tab.Container id="left-tabs-example" defaultActiveKey="first" >
              <Row className="nutrional-dash-sidebar p-2">
                <Col sm={12} className="mb-3">
                  <Nav variant="tabs" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Questionnaire</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Pre Consultation</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="fourth">Symptom Tracker</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {
                        userDetail.questionnaire.result.map((item: any) => {
                          return <div key={item.id} className="mb-2">
                            <h4>Question: {item.question?.text}</h4>
                            {
                              item.answers.map((answer: any, index: number) => {

                                if (answer?.option?.text) {
                                  return <p key={answer.id}>{index + 1}) {answer?.option?.text}</p>
                                }
                                if (answer?.value && index === 1) {
                                  return <p key={answer.id}>{index}) {answer?.value} {item.answers[0].value}</p>
                                }
                                return null;
                              })
                            }
                          </div>
                        })
                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">{
                      Object.keys(dietDiary).map((key: string) => {
                        return <div key={key} className="mb-4">
                          <h3 className="mb-4">{dietDiary[key][0]?.quiz?.type}</h3>
                          <hr />
                          {
                            dietDiary[key].map((item: any) => {

                              if (item.question?.type === 1) {
                                return <div className="mb-2">
                                  <h4>Medications</h4>
                                  <p>{item.answer}</p>
                                </div>
                              }

                              return <div className="mb-2">
                                <h4>{item.question?.text}</h4>
                                <p>{item.answer}</p>
                              </div>
                            })
                          }
                        </div>
                      })
                    }</Tab.Pane>

                    <Tab.Pane eventKey="fourth">
                      <Symptoms userId={userDetail.user.id} />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>}
          </div>
        </div>
    </div>
  )
}

export default NutritionClientDetail