import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import '../css/femmar.css';
import { verifyEmail } from '../api/auth';
import { showErrorMessage } from '../utils/banner.utils';
import { AppRoutes } from '../constants/routes';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';

const EmailVerify = () => {
  const [searchParams] = useSearchParams();
  const [isVerified, setIsVerified] = useState(false);
  const validateTokenEmail = async () => {
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    if (!email || !token) {
      showErrorMessage('Email and token are required');
      return;
    }

    try {
      await verifyEmail({ email, token });
      setIsVerified(true);
    } catch (e) {
      showErrorMessage(e);
    }
  };
  useEffect(() => {
    validateTokenEmail();
  }, []);

  return (
    <div className="flex flex-col flex-item-center gap-half fill-page">
      <TopComp />
      <PageTitleComp pageTitle="Email Verification" />

      <br />
      
      {isVerified ? (
        <div className="margin2">
          <span>
            Congratulations! your email address is verified.
            <br />
            Please click&nbsp;
          </span>
          <Link to={AppRoutes.LoginB2C}> here</Link>
          <span> to login.</span>
        </div>
      ) : (
        'Verifying your email please wait...'
      )}
    </div>
  );
};

export default EmailVerify;
