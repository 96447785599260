import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAppointment, getPool } from '../../api/appointment';
import { AppointmentPool } from '../../types/models/AppointmentPool';
import { withToastForError } from '../../utils/thunk.utilts';
import { Appointment } from '../../types/models/AppointmentModel';
import { AppointmentUser } from '../../types/models/AppointmentUser';



export const getAppointmentPoolAction = createAsyncThunk<
    AppointmentPool
>(
    'appointmentpool/get',
    withToastForError(async (params) => {
        const response = await getPool();
        return response.data.data;
    }),
);

export const getAppointmentAction = createAsyncThunk<
    Appointment,
    { 'id': number }
>(
    'appointmentpool/get-appointment',
    withToastForError(async (params) => {
        const response = await getAppointment(params);
        return response.data.data;
    }),
);


export const setAppointmentUserAction = createAsyncThunk<
    AppointmentUser,
    AppointmentUser | any
>(
    'appointmentpool/set-appointment-user',
    withToastForError(async (params) => {
        if (params) {
            localStorage.setItem('appointmentUser', JSON.stringify(params));
        } else {
            localStorage.removeItem('appointmentUser');
        }
        return params;
    }),
);
