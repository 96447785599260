import React,{useEffect,useState} from 'react';
import '../css/femmar.css';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import Big3PinkBtnsComp from '../components/Big3PinkBtnsComp';
import myContent from '../jsonContent/trans_Faqs.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import FooterCopyright from '../components/FooterCopyright';
import {  directusClient } from '../api/client';

interface FAQItem {
  id: number;
  question: string;
  answer: string;
}

const Faqs = () => {
  const navigate = useNavigate();
  const [faqData, setFaqData] = useState<FAQItem[]>([]);
  const langNum = useAppSelector(selectLanguageIndex);
  const {pageTitle,pageText} = myContent.trans[langNum];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await directusClient.get(
          '/items/TransFaqs'
        );
        setFaqData(response.data.data);
      } catch (error) {
        console.error('Error fetching FAQ data:', error);
      }
    };

    fetchData();
  }, []);

  const handleFaqItemClick = (id: number) => {
    navigate(`/Faq/${id}`);
  };

  return (
    <div  className="flex flex-col flex-item-center gap-half fill-page">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <Big3PinkBtnsComp />

      <div className="flex flex-col flex-item-center flex-space-around gap1">
   
        <div className="page-text">{parse(pageText)}</div>
        {faqData.map((faqItem)=>(
          <button key={faqItem.id}
            type="button"
            className="faq-btn"
            onClick={() => handleFaqItemClick(faqItem.id)} >
           {faqItem.question}
          </button>

        ))}
       
        {/* <button
          type="button"
          className="faq-btn"
          onClick={() => navigate('/Faqs01')}>
          {q1}
        </button> */}
        
      </div>
      <FooterCopyright />
    </div>
  );
};

export default Faqs;
