import React, { useState, useEffect } from 'react';
import { Table, Alert, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { client } from '../api/client';

interface UserData {
  id: number;
  user_id: number;
  nutritionist_id: number;
  appointment_date: string;
  appointment_time_from: string;
  before_appointment: string;
  during_appointment: string;
  recommendation_doc: string | null;
  createdAt: string;
  updatedAt: string;
  nutritionist: {
    id: number;
    email: string;
    firstName: string;
  };
  user: {
    id: number;
    email: string;
    firstName: string;
  };
}

const NutrionalClientHistory = () => {
  const [showBeforeAppointmentModal, setShowBeforeAppointmentModal] =
    useState(false);
  const [showDuringAppointmentModal, setShowDuringAppointmentModal] =
    useState(false);
  const [showRecommendationDocModal, setShowRecommendationDocModal] =
    useState(false);
  const [userData, setUserData] = useState<any[] | null>(null);
  const [selectedAppointment, setSelectedAppointment] =
    useState<UserData | null>(null);

  const handleBeforeAppointmentClick = (appointment: UserData) => {
    setSelectedAppointment(appointment);
    setShowBeforeAppointmentModal(true);
  };
  const handleDuringAppointmentClick = (appointment: UserData) => {
    setSelectedAppointment(appointment);
    setShowDuringAppointmentModal(true);
  };
  const handleRecommendationDocClick = (appointment: UserData) => {
    setSelectedAppointment(appointment);
    setShowRecommendationDocModal(true);
  };

  const handleCloseModals = () => {
    setShowBeforeAppointmentModal(false);
    setShowDuringAppointmentModal(false);
    setShowRecommendationDocModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        const response = await client.get(
          `nutritionist/get-user-appointments?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        // console.log('his',response.data.data);
        setUserData(response.data.data);
      } catch (error) {
        console.error('Error fetching user appointments:', error);
      }
    };

    fetchData();
  }, []);
  const userFirstName = userData && userData.length > 0 ? userData[0].user.firstName : '';
  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      {userData && userData.length > 0 ? (
        <>
           <p className="nutrional-dash-heading">{userFirstName}&apos;s History</p>
          <Alert variant="warning">
            <p className="text-dark">
              You can click on the button in the last column to see the history
              of your user.
            </p>
          </Alert>
          <Table style={{ border: '2px solid black' }} bordered>
            <thead>
              <tr>
                {/* <th>Client Name</th> */}
                <th>Nutritionist Name</th>
                <th>Appointment Date</th>
                <th>Appointment Time</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {userData &&
                userData.map((appointment: UserData) => (
                  <tr key={appointment.id}>
                    {/* <td>{appointment.user.firstName}</td> */}
                    <td>{appointment.nutritionist.firstName}</td>
                    <td>{appointment.appointment_date}</td>
                    <td>{appointment.appointment_time_from.substring(0,5)}</td>
                    <td>
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="nutritionist-history-btn me-2 text-dark px-2"
                            onClick={() =>
                              handleBeforeAppointmentClick(appointment)
                            }>
                            Before Appointment
                          </button>
                          <button
                            type="button"
                            className="nutritionist-history-btn me-2 text-dark px-2"
                            onClick={() =>
                              handleDuringAppointmentClick(appointment)
                            }>
                            During Appointment
                          </button>
                          <button
                            type="button"
                            className="revised-program-btn me-2 text-light px-2"
                            onClick={() =>
                              handleRecommendationDocClick(appointment)
                            }>
                            Revised Program
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Modal show={showBeforeAppointmentModal} onHide={handleCloseModals}>
            <Modal.Header closeButton>
              <Modal.Title>Before Appointment Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedAppointment && (
                <p>{selectedAppointment.before_appointment}</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModals}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showDuringAppointmentModal} onHide={handleCloseModals}>
            <Modal.Header closeButton>
              <Modal.Title>During Appointment Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedAppointment && (
                <p>{selectedAppointment.during_appointment}</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModals}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showRecommendationDocModal} onHide={handleCloseModals}>
            <Modal.Header closeButton>
              <Modal.Title>Recommendation Doc Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}>
              {selectedAppointment &&
              selectedAppointment.recommendation_doc !== null ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedAppointment.recommendation_doc,
                  }}
                />
              ) : (
                <p>No recommendation document available.</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModals}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Alert variant="info">
          <p className="text-dark">No client history.</p>
        </Alert>
      )}

      {/* Modals */}
    </div>
  );
};

export default NutrionalClientHistory;
