import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLessonChildren, getLessonDetail, getPrograms, getProgramsDetails, getProgramsLessons } from '../../api/programApi';
import { Program } from '../../types/models/Program';
import { withToastForError } from '../../utils/thunk.utilts';
import { ProgramDetail } from '../../types/models/ProgramDetail';

export const getProgramsAction = createAsyncThunk<
    Program[]
>(
    'items/SixWeekProgram',
    withToastForError(async (params) => {
        const response = await getPrograms();
        return response.data.data;
    }),
);


export const getProgramLessonsAction = createAsyncThunk<
    ProgramDetail[],
    { 'filter[week_id][_eq]': number, sort: string }
>(
    'items/SixWeekProgramDetails',
    withToastForError(async (params) => {
        const response = await getProgramsLessons(params);
        return response.data.data;
    }),
);

export const getAllProgramLessonsAction = createAsyncThunk<
    ProgramDetail[]
>(
    'items/SixWeekProgramDetails/All',
    withToastForError(async (params) => {
        const response = await getProgramsLessons(params);
        return response.data.data;
    }),
);


export const getLessonDetailAction = createAsyncThunk<
    ProgramDetail,
    { id: number }
>(
    'items/SixWeekProgramDetailsSingle',
    withToastForError(async (params) => {
        const response = await getLessonDetail(params);
        return response.data.data;
    }),
);


export const getLessonsChildrenAction = createAsyncThunk<
    ProgramDetail[],
    { 'filter[parent_id][_eq]': number, sort: string }
>(
    'items/SixWeekProgramDetailsChildren',
    withToastForError(async (params) => {
        const response = await getLessonChildren(params);
        return response.data.data;
    }),
);


export const getProgramDetailction = createAsyncThunk<
    Program,
    { id: number }
>(
    'items/SixWeekProgramSingle',
    withToastForError(async (params) => {
        const response = await getProgramsDetails(params);
        return response.data.data;
    }),
);

