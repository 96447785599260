import React, { useEffect, useState } from 'react';
import '../css/femmar.css';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../redux/store';
import { selectCurrentStep } from '../redux/slices/visitedscreens';
import myBreadcrumbs from '../jsonContent/nonLessonBreadcrumbs.json';

// row 3 of header: line then crumbs then title

interface PageTitleCompProps {
  pageTitle: string;
  weekNum?: number
}

const PageTitleComp: React.FC<PageTitleCompProps> = (props) => {
  const { pageTitle, weekNum } = props;

  // last crumb is just week + week num if it's a lesson, or page name if not
  const [breadcrumb, setBreadcrumb] = useState('');

  /* const {DashboardMain, SymptomReport, PhytoTracker} = myBreadcrumbs.nonLessonBreadcrumbs;
   */

  const currentStep = useAppSelector(selectCurrentStep);

  const location = useLocation();
  const currentLocation = location.pathname;

  useEffect(() => {
    if (currentLocation.startsWith('/SixWk') || currentLocation.startsWith('/Quiz')) {

      const crumb = `Dashboard / Modules Menu / Week ${weekNum}`;
      setBreadcrumb(crumb);
    } else {
      /*   const crumb = pageTitle.replace(/([A-Z])/g, ' $1').trim(); */
      const crumb = currentLocation.replace('/', ' > ');
      const crumb2 = crumb.replace("DashboardMain", "Dashboard");  // TEMPORARY HACK UNTIL WE USE THE JSON FILE
      setBreadcrumb(crumb2); // = lesson name
      // *********** we need to add paths from nonLessonBreadcrumbs.json
    }
  }, [currentStep, weekNum]);

  return (
    <div className="flex flex-col flex-item-center flex-space-between width9060">
      <hr className="width9060" />
      <p className="text-left smaller flex-start">{breadcrumb}</p>
      <p className="h1 text-left flex-start">{pageTitle}</p>
    </div>
  );
};

export default PageTitleComp;
