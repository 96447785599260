import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUser, IUserAttributes } from '../../types/models/User';
import { RootState } from '../store';
import { loginAction } from '../actions/auth';
import { setAuthenticationToken } from '../../api/client';
import {
  getCurrentUserAction,
  getMyOrdersAction,
  registerProgramStartAction,
  updateProfileAction,
} from '../actions/user';
import { IOrder } from '../../types/models/Order';

export interface AuthState {
  user?: IUser;
  orders: IOrder[];
}

const initialState: AuthState = {
  user: undefined,
  orders: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loginAction.fulfilled,
      (state, action: PayloadAction<IUser>) => {
        state.user = action.payload;

        // set auth token to axios instance
        setAuthenticationToken(action.payload.token);
      },
    );
    builder.addCase(
      getCurrentUserAction.fulfilled,
      (state, action: PayloadAction<Omit<IUser, 'token'>>) => {
        // override as payload will not have token
        state.user = { ...state.user, ...action.payload };
      },
    );
    builder.addCase(
      registerProgramStartAction.fulfilled,
      (state, action: PayloadAction<IUserAttributes>) => {
        if (!state.user) {
          return;
        }
        state.user.userAttributes = action.payload;
      },
    );
    builder.addCase(
      getMyOrdersAction.fulfilled,
      (state, action: PayloadAction<IOrder[]>) => {
        state.orders = action.payload || [];
      },
    );
    builder.addCase(
      updateProfileAction.fulfilled,
      (state, action: PayloadAction<IUser>) => {
        // override as payload will not have token
        state.user = { ...state.user, ...action.payload };
      },
    );
  },
});

// Action creators are generated for each case reducer function
export const { logout } = authSlice.actions;

export const selectLanguageIndex = (state: RootState): number =>
  state.auth.user?.langNum || 0;

export const selectProgramStartDate = (state: RootState): string | null =>
  state.auth.user?.userAttributes?.programStartDate || null;

export const selectUser = (state: RootState): IUser | undefined =>
  state.auth.user;

export default authSlice.reducer;
