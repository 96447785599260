import React from 'react';
import { useNavigate } from 'react-router-dom';
import TopComp from '../components/TopComp';
import '../css/femmar.css';

const ManagerMenu = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row flex-wrap flex-item-center padding1 margin1">
      <TopComp />
      <button
        type="button"
        onClick={() => navigate('/managerCMS')}
        className="fmButton">
        Content Management System (future)
      </button>
      <button
        type="button"
        onClick={() => navigate('/ManagerUpload')}
        className="fmButton">
        Upload files (future)
      </button>
      <button
        type="button"
        onClick={() => navigate('/ManagerUploadShow')}
        className="fmButton">
        Show uploaded files (future)
      </button>
      <button
        type="button"
        onClick={() => navigate('/ManagerClientRpts')}
        className="fmButton">
        Client reports (needs doing)
      </button>
    </div>
  );
};

export default ManagerMenu;
