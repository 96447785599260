import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';

import myContent from '../jsonContent/trans_Consult.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import FooterCopyright from '../components/FooterCopyright';
import { createCheckoutSession, getProgramInfo } from '../api/payment';
import { showErrorMessage } from '../utils/banner.utils';
import { client } from '../api/client';
import { getCurrentUser } from '../api/user';
import { UserRoles } from '../types/models/User';

const Consult = () => {
  const navigate = useNavigate();
  const langNum = useAppSelector(selectLanguageIndex);
  const pageTitle = "Book a consultation";
  const user = useAppSelector((state) => state.auth.user);
  // const pageText = parse(myContent.trans[langNum].pageText);

  const [enable, setEnable] = useState(false);
  const [disableInitialConsult, setDisableInitialConsult] = useState(false);
  const [disableFollowupConsult, setDisableFollowupConsult] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [appointmentType, setAppointmentType] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(false);

  const handleButtonClick = () => {
    // navigate('/DayoneForm');
    // navigate('/Payment');  
  };
 
  useEffect(() => {
    getCurrentUser().then((response) => {
      if (response.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { user, PaymentStatus } = response.data;

        // Check if userAttributes and userRole are present
        if (user.userAttributes) {
          const { userRole } = user.userAttributes;

          if (userRole !== UserRoles.Vip && PaymentStatus) {
            navigate('/appointment/pool');
          }
        }
      }
    }).catch((err) => {
      showErrorMessage(err);
    });

    
    client.get('/nutritionist/get-existing-appointment').then((response: any) => {
      // console.log(" ****  response.data ", response.data);
      if (response.data.data) {
        setEnable(true);
      }
    })

    // if (data === "Initial Payment") {
    //   setDisableFollowupConsult(true);
    // } else if (data === "Followup Payment" || response.is_scheduled ===false) {
    //   setDisableInitialConsult(true);
    //   const isCompleted = appointments.some((appointment: any) => appointment.Is_completed);
    //   if (response.is_scheduled=== false) {
    //     setDisableInitialConsult(true);
    //   }else{
    //     setDisableInitialConsult(true);
    //     setDisableFollowupConsult(true);
    //   }
    // }
    client.get('/nutritionist/get-appointment-types').then((response: any) => {
      const { data, response: appointments, is_scheduled, paymentStatus :apiPaymentStatus } = response.data;
    
    console.log('response', response.data);
    setIsScheduled(is_scheduled);
    setAppointmentType(data);
    setPaymentStatus(apiPaymentStatus);
    
    if (data === "Followup Payment") {
      setDisableInitialConsult(true);
      
      // Check if there's a completed appointment
      const hasCompletedAppointment = appointments.some((appointment: any) => appointment.Is_completed === true);
      console.log('nikhil',hasCompletedAppointment)
      if (is_scheduled && hasCompletedAppointment) {
        setDisableFollowupConsult(false);  // Enable follow-up button
      } else {
        setDisableFollowupConsult(true);   // Disable follow-up button
      }
    } else if (data === "Initial Payment") {
      const hasCompletedAppointment = appointments.some((appointment: any) => appointment.Is_completed === true);
      console.log('a',hasCompletedAppointment)
      
      setDisableFollowupConsult(true);
      if (!is_scheduled) {
        setDisableInitialConsult(true);
      }else if(appointments.length> 0 && hasCompletedAppointment === false){
        setDisableInitialConsult(true);
      } 
      else {
        setDisableInitialConsult(false);
      }
    } else {
      // Default case
      setDisableInitialConsult(true);
      setDisableFollowupConsult(true);
    }
    }).catch((err) => {
      showErrorMessage(err);
    });
  }, [navigate]);
  const getInitialConsultButtonText = () => {
    if (appointmentType === 'Initial Payment' && paymentStatus ) {
      return 'Schedule 45 min';
    }if(appointmentType ==='Followup Payment'){
      return 'Completed';
    }
    return 'Schedule 45 min';
     
  };

  const getFollowupConsultButtonText = () => {
    if (appointmentType === 'Followup Payment' && paymentStatus ) {
      return 'Schedule 30 min';
    }
    
    return 'Unavailable';
  };

  const initiateCheckoutSession = async (type?:number) => {
    try {
      const response = await createCheckoutSession(user?.email as string,type !== undefined ? type : 0 );
      window.location.href = response.data.url;
    } catch (err) {
      showErrorMessage(err);
    }
  };

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />

      <PageTitleComp pageTitle={pageTitle} />
      {/* <div className="page-text">{pageText}</div> */}

      <img className="consult-main-image" src="/consultmain.jpg"  alt="Consultation" />

      <p className="consult-main-para">
        Get additional help by booking a consultation with a
        Certified Femmar Nutritionist. Our nutritionists will
        help you personalize your program and provide
        additional assistance and are ready to answer any
        questions you may have.
      </p>

      <div className="container">
        <div className="row">
          <div className="col-md-2 d-none d-md-block">&nbsp;</div>

          <div className="col-12 col-md-4">
            <Card className="consult-main-card">
              <Card.Img variant="top" className="consult-main-card-img flex-flex-center"  src="/initialconsult.jpg" alt="Initial-consulatation " />
              <Card.Body>

                <Card.Text>
                  <div className="consult-main-card-text mt-3">
                    <b>Initial Consultation  </b> <br />
                     45 minutes <br /> (Includes assessment and custom program)
                  </div>
                  <div className="consult-main-card-price mt-2">$195</div>
                </Card.Text>
                <button type="button" 
                className={`consult-main-card-btn text-dark ${disableInitialConsult ? 'disabled-btn' : ''}`} 
                onClick={()=>initiateCheckoutSession(0)} 
                disabled={disableInitialConsult}>
                {getInitialConsultButtonText()}
                  </button>
              </Card.Body>
            </Card>
          </div>

          {/* <div className="col-1 d-none d-md-block ">&nbsp;</div> */}

          <div className="col-12 col-md-4">
            <Card className="consult-main-card">
              <Card.Img variant="top" className="consult-main-card-img flex-flex-center"  src="/followupconsult.jpg"   alt="Follow-up-consulatation "/>
              <Card.Body>

                <Card.Text>
                  <div className="consult-main-card-text mt-3">
                    <b>Follow up Consultation </b> <br/>
                    30 minutes <br/> (Only available after an initial consultation)
                  </div>
                  <div className="consult-main-card-price mt-2"> $75</div>
                </Card.Text>
                <button
                  disabled={!enable || disableFollowupConsult}
                  className={`consult-main-card-btn text-dark ${(!enable || disableFollowupConsult) ? 'disabled-btn' : ''}`}
                  onClick={()=> initiateCheckoutSession(1)}
                  type="button"
                  >
                  {/* {!enable || disableFollowupConsult ? 'Unavailable' : 'Schedule 30 min'} */}
                  {getFollowupConsultButtonText()}
                </button>


              </Card.Body>
            </Card>
          </div>

          <div className="col-md-2 d-none d-md-block">&nbsp;</div>
        </div>
      </div>
      <FooterCopyright />

    </div>
  );
};

export default Consult;
