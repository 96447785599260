import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PhytoFoodItem,
  PhytoFoodItemsPerDate,
} from '../../types/models/PhytoTracker';
import { withToastForError } from '../../utils/thunk.utilts';
import { getPhytoFoodByMonth, getPhytoFoodItems } from '../../api/phytotracker';

export const getPhytoFoodItemsActions = createAsyncThunk<PhytoFoodItem[], void>(
  'phytotracker/itemslist',
  withToastForError(async () => {
    const response = await getPhytoFoodItems();
    return response.data.items;
  }),
);

export const getLogsForMonthActions = createAsyncThunk<
  PhytoFoodItemsPerDate[],
  { date: string }
>(
  'phytotracker/month-logs',
  withToastForError(async ({ date }) => {
    const response = await getPhytoFoodByMonth({ date, view: 'month' });
    return response.data.logs;
  }),
);
