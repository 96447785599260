import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Spinner, Alert } from 'react-bootstrap';
import { getMyActionDetail } from '../api/actiondetail'; 
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';

interface MyActionDetailResponse {
  id: number;
  question: string;
  left_side_ans: string;
  right_side_ans: string | null;
}

const Actiondetaildesc: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [actionDetail, setActionDetail] = useState<MyActionDetailResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchActionDetail = async () => {
      try {
        const response = await getMyActionDetail();
        const detail = response.data.data.find((item: MyActionDetailResponse) => item.id === Number(id));
        if (detail) {
          setActionDetail(detail);
        } else {
          setError('Action detail not found.');
        }
        setLoading(false);
      } catch (fetchError) {
        console.log('Error fetching action detail', fetchError);
        setError('Error fetching action detail.');
        setLoading(false);
      }
    };

    fetchActionDetail();
  }, [id]);

  const formatRightSideAnswer = (text: string | null) => {
    if (!text) return null;
    const lines = text.split('\n').filter(line => line.trim() !== '');
    const listItems = lines.map(line => line.match(/^\d\)/) ? line : null).filter(Boolean);

    if (listItems.length === lines.length) {
      return (
        <ul>
          {lines.map((line) => (
            <li key={line}>{line.replace(/^\d\)/, '').trim()}</li>
          ))}
        </ul>
      );
    }

    return lines.map((line) => <p key={line}>{line}</p>);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status"/>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!actionDetail) {
    return <Alert variant="warning">No action detail available.</Alert>;
  }

  return (
    <div className="flex flex-col flex-item-center gap-half fill-page">
      <TopComp />
      <hr className="width9060" />
      <div className="p-2 flex flex-col flex-item-center flex-space-around gap1 width9060">
        <p className="h2 flex flex-start text-dark">My Action Plan</p>
        <p className="h1 flex flex-center" style={{ color: "#a639e8" }}>{actionDetail.question}</p>
        <p><b>{actionDetail.left_side_ans}</b></p>
        <div>{formatRightSideAnswer(actionDetail.right_side_ans)}</div>
      </div>
      <FooterCopyright />
    </div>
  );
};

export default Actiondetaildesc;
