import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IQuiz } from '../../types/models/Quiz';
import { getQuizByTypeAction } from '../actions/quiz';

export interface QuizState {
  currentQuiz?: IQuiz;
  loading: boolean;
}

const initialState: QuizState = {
  currentQuiz: undefined,
  loading: true,
};

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQuizByTypeAction.fulfilled, (state, action) => {
      state.currentQuiz = action.payload.quiz;
      state.loading = false;
    });
    builder.addCase(getQuizByTypeAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getQuizByTypeAction.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function

export const selectCurrentQuiz = (state: RootState): IQuiz | undefined =>
  state.quiz.currentQuiz;

export default quizSlice.reducer;
