import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk2L1GoodShape.json';
import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';
import VideoComp from '../components/VideoComp';

const SixWk2L1GoodShape = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <LessonPageHeaderComp pageTitle={pageTitle} />

      <VideoComp url="https://vimeo.com/853042266/709f06c1a4?share=copy" />

      <p className="h2 text-center">{pageTitle}</p>
      <p className="page-text">{parse(pageText)}</p>
      <TickComp />
      <FooterBtns />
      <FooterCopyright />
    </div>
  );
};

export default SixWk2L1GoodShape;
