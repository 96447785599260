const arrayScreens = [

  'Welcome',
  'HistoryQuestionnaireIntro',
  'HistoryQuestionnaire',
  'DashboardMain',

  'SixWeekMenu',
  'SixWk0',
  'SixWk0L01Expectations',
  'SixWk0L02Behavior',
  'SixWk0L03Preparation',
  'SixWk0L04Fruit',
  'SixWk0L05Fatty',
  'SixWk0L06Wheat',
  'SixWk0L07Caffeine',
  'SixWk0L08Curb',
  'SixWk0L09Hydrated',
  'SixWk0L10Alcohol',
  'SixWk0L11Moving',
  'SixWk0L12Rest',
  'SixWk0L13Phyto',
  'SixWk0L14Flaxseeds',
  'SixWk0L15Meals',
  'SixWk0L16Portion',

  'SixWk1',
  'SixWk1L1MeetMaryon',
  'SixWk1L2ProgressQuestionnaire',
  'SixWk1L3Supplements',
  'SixWk1L4Body',
  'SixWk1L5Symptoms',
  'SixWk1L5SympAching',
  'SixWk1L5SympDry',
  'SixWk1L5SympHeadaches',
  'SixWk1L5SympHot',
  'SixWk1L5SympInsomnia',
  'SixWk1L5SympMood',
  'SixWk1L5SympPainful',
  'SixWk1L5SympPanic',
  'SixWk1L6Research',
  'SixWk1L7Quiz',

  'SixWk2',
  'SixWk2L1GoodShape',
  'SixWk2L2Robbers',
  'SixWk2L3RightFoods',
  'SixWk2L4Research',
  'SixWk2L5Quiz',

  'SixWk3',
  'SixWk3L1ProgressQuestionnaire',
  'SixWk3L2WhatPhyto',
  'SixWk3L3PhytoDiet',
  'SixWk3L4Sugar',
  'SixWk3L5Bloat',
  'SixWk3L6Constipation',
  'SixWk3L7Research',
  'SixWk3L8Quiz',

  'SixWk4',
  'SixWk4L1MLRBox',
  'SixWk4L2Supplements',
  'SixWk4L3Joylux',
  'SixWk4L4Exercise',
  'SixWk4L5Relaxation',
  'SixWk4L6Research',
  'SixWk4L7Quiz',

  'SixWk5',
  'SixWk5L1ProgressQuestionnaire',
  'SixWk5L2SexDry',
  'SixWk5L3SexLack',
  'SixWk5L4Stress',
  'SixWk5L5Anxiety',
  'SixWk5L6Sleep',
  'SixWk5L7Research',
  'SixWk5L8Quiz',

  'SixWk6',
  'SixWk6L1Sharp',
  'SixWk6L2Hair',
  'SixWk6L3Positivity',
  'SixWk6L4BoneHealth',
  'SixWk6L5Plan',
  'SixWk6L6Next',
  'SixWk6L7Research',
  'SixWk6L8Quiz',
  'SixWk6L9ProgressQuestionnaire',
  'SixWk6L10Congrats',
];

const SYMPTOM_TRACKER_QUESTION_COUNT = 14; // TODO: should be removed once questions are moved to BE

const QUESTION_PREFIX = 'questions-';

const YYYY_MM_DD = 'YYYY-MM-DD';

const MIN_PHYTO_MG = 100;

const ADMIN_URL_PREFIX = '/admin';

const REGISTER_EMAIL_KEY = 'registered-email';

export {
  arrayScreens,
  SYMPTOM_TRACKER_QUESTION_COUNT,
  QUESTION_PREFIX,
  YYYY_MM_DD,
  ADMIN_URL_PREFIX,
  MIN_PHYTO_MG,
  REGISTER_EMAIL_KEY,
};
