import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import VideoComp from '../components/VideoComp';

import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk0L07Caffeine.json';
import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';
import image from '../images/lessons/W0L07Caffeine.jpg';

const SixWk0L07Caffeine = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText } = myContent.trans[langNum];


  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <LessonPageHeaderComp pageTitle={pageTitle} />

      <img src={image} className="theBigImage" alt="" />
      <div>
        <VideoComp url="https://vimeo.com/851216620/d9791e8edd?share=copy" />
      </div>

      <p className="page-text">{parse(pageText)}</p>
      <TickComp />
      <FooterBtns />
      <FooterCopyright />

    </div>
  );
};

export default SixWk0L07Caffeine;
