import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import FieldError from '../FieldError/FieldError';
import { OnChangeEvent } from '../../../types/types';

export interface IFieldInput extends FieldRenderProps<string> {
  className?: string;
}

const FieldInput: React.FC<IFieldInput> = ({
  input,
  meta,
  compact,
  className,
  inputType,
  ...rest
}) => {
  const onChange: OnChangeEvent = (event) => {
    input.onChange(event.target.value || null);
  };
  return (
    <div>
      <input
        type={inputType || 'text'}
        className={`padding-half border-thin border-round width9030 ${className}`}
        value={input.value}
        onChange={onChange}
        {...rest}
      />
      <FieldError
        compact={compact}
        visible={!!(meta.touched && meta.error)}
        errorId={meta.error}
      />
    </div>
  );
};

export default FieldInput;
