import tempeh from '../images/phytoFoods/tempeh.png';
import redClover from '../images/phytoFoods/redClover.png';
import edamameBeansRaw from '../images/phytoFoods/edamameBeansRaw.png';
import cannedSoyaBeans from '../images/phytoFoods/cannedSoyaBeans.png';
import edamameBeansCooked from '../images/phytoFoods/edamameBeansCooked.png';
import rawSproutedSoySeeds from '../images/phytoFoods/rawSproutedSoySeeds.png';
import tofu from '../images/phytoFoods/tofu.png';
import whippedSoyDessert from '../images/phytoFoods/whippedSoyDessert.png';
import soyFruitShake from '../images/phytoFoods/soyFruitShake.png';
import soyaMilk from '../images/phytoFoods/soyaMilk.png';
import soyYogurt from '../images/phytoFoods/soyYogurt.png';
import soyLinseedBread from '../images/phytoFoods/soyLinseedBread.png';
import soyRicePancakes from '../images/phytoFoods/soyRicePancakes.png';
import phytoFruitLoaf from '../images/phytoFoods/phytoFruitLoaf.png';
import phytoMuseliSoyMilk from '../images/phytoFoods/phytoMuseliSoyMilk.png';
import phytoFixBar from '../images/phytoFoods/phytoFixBar.png';
import soyMilkLowFat from '../images/phytoFoods/soyMilkLowFat.png';

export const phytoFoodImages = {
  tempeh,
  redClover,
  edamameBeansRaw,
  cannedSoyaBeans,
  edamameBeansCooked,
  rawSproutedSoySeeds,
  tofu,
  whippedSoyDessert,
  soyFruitShake,
  soyaMilk,
  soyYogurt,
  soyLinseedBread,
  soyRicePancakes,
  phytoFruitLoaf,
  phytoMuseliSoyMilk,
  phytoFixBar,
  soyMilkLowFat,
};

export type PhytoFoodImageKey = keyof typeof phytoFoodImages;
