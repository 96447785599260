import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import { Alert } from 'react-bootstrap';
import VideoComp from '../components/VideoComp';
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';

import myContent from '../jsonContent/trans_Welcome.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';

import {
  changeCurrentStep,
  completeWeekStep,
} from '../redux/slices/visitedscreens';
import { saveCompletedStep } from '../api/visitedscreens';
import { AppRoutes } from '../constants/routes';
import WelcomeFooterBtns from '../components/WelcomeFooterBtn';

const Welcome = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const dispatch = useAppDispatch();
  const { pageTitle, pageText } = myContent.trans[langNum];

  useEffect(() => {
    const route = AppRoutes.Welcome.replace('/', '');
    dispatch(changeCurrentStep(route));
    saveCompletedStep({ step: route });
    dispatch(completeWeekStep({ step: route }));
  }, []);

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />

      <p className="h1">{pageTitle}</p>
      <Alert variant="info">
        <p className="text-dark">Please fill History Questionnaire in Order to start Six-Week Program.</p>
      </Alert>
      <div>
        <VideoComp url="https://vimeo.com/850681791/4116bcf286?share=copy" />
      </div>

      <div className="page-text page-text-portal">{parse(pageText)}</div>
      <WelcomeFooterBtns nextButtonLabel="Next page" />

      <FooterCopyright />
    </div>
  );
};

export default Welcome;
