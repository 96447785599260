/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { Alert, Spinner } from 'react-bootstrap';
import '../css/femmar.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';
import myContent from '../jsonContent/trans_MyPlanComp.json';
import arrowMore from '../images/icons/arrowMore2.png';
import arrowLess from '../images/icons/arrowLess2.png';
import { selectCurrentStep } from '../redux/slices/visitedscreens';
import { getProductSupplement } from '../api/prodsuppliment';


interface ProductSupplementResponse {
  id: number;
  brand: string;
  product: string;
  supplement: string;
  directions: string;
  longDisplayNames: string;
  shortDisplayNames: string;
  link: string | null;
  updatedAt: string;
}

const MyPlanComp = () => {
  const navigate = useNavigate();
  const langNum = useAppSelector(selectLanguageIndex);

  const { actionPlan } = myContent.trans[langNum];

  const user = useAppSelector(({ auth }) => auth.user);
  const userName = user?.firstName;
  const userNameActionPlan = `${userName}'s Plan`;

  const [actionPlanVisible, setActionPlanVisible] = useState(false);
  const [supplementScheduleVisible, setSupplementScheduleVisible] =
    useState(false);
  const [productSupplement, setProductSupplement] = useState<ProductSupplementResponse[]>([]);
  const [personalizedSupplements, setPersonalizedSupplements] = useState<{ question: string, right_side_ans: string }>({ question: '', right_side_ans: '' });
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(false); 

  // //////////////
  useEffect(() => {
    const fetchProductsupplement = async () => {
      try {
        const response = await getProductSupplement();
        const personalizedData = response.data.personalizedSupplements || { question: '', right_side_ans: '' };
        setProductSupplement(response.data.data || []);
        setPersonalizedSupplements({
          question: personalizedData.question || '',
          right_side_ans: personalizedData.right_side_ans || ''
        });
        setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-shadow
      } catch (error) {
        console.error('error fetching supplement products', error);
        setError(true);
        setLoading(false);
      }
    };
    fetchProductsupplement();
  }, []);

  // const handleBtnActionPlan = () => {
  //   // const x =
  //   //   actionPlanVisible === true
  //   //     ? setActionPlanVisible(false)
  //   //     : setActionPlanVisible(true);
  //   navigate('/ActionDetail');
  // };

  const handleBtnSupplementSchedule = () => {
    const y =
      supplementScheduleVisible === true
        ? setSupplementScheduleVisible(false)
        : setSupplementScheduleVisible(true);
  };

  const handleBtnNutritionInfo = () => {
    navigate('/SupplementInfo');
  };

  const formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    return { formattedDate, formattedTime };
  };

  const { formattedDate, formattedTime } = productSupplement.length > 0 ? formatDateTime(productSupplement[0].updatedAt) : { formattedDate: '', formattedTime: '' };
  // ////////////////77
  // const linkify = (text: string) => {
  //   const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  //   const parts = text.split(urlPattern);
  
  //   const links: string[] = [];
  //   const texts: string[] = [];
  
  //   parts.forEach(part => {
  //     if (urlPattern.test(part)) {
  //       links.push(part);
  //     } else if (part.trim() && !part.trim().startsWith('https')) {
  //       texts.push(part.trim());
  //     }
  //   });
  
  //   return (
  //     <div>
  //       {links.length > 0 && (
  //         <div className="link-section">
  //           <p><b>Links</b></p>
  //           <ul>
  //             {links.map((link, index) => (
  //               // eslint-disable-next-line react/no-array-index-key
  //               <li key={`link-${index}`} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
  //                 <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
  //               </li>
  //             ))}
  //           </ul>
  //         </div>
  //       )}
  //       {texts.length > 0 && (
  //         <div className="text-section">
  //           <p><b>Text</b></p>
  //           <ul>
  //             {texts.map((text, index) => (
  //               // eslint-disable-next-line react/no-array-index-key
  //               <li key={`text-${index}`}>{text}</li>
  //             ))}
  //           </ul>
  //         </div>
  //       )}
  //     </div>
  //   );
  // };
  const linkify = (text: string) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const parts = text.split(urlPattern);

    return parts.map((part, index) => {
      const key = `${index}-${part}`;

      if (urlPattern.test(part)) {
        return (
          <div key={key} className="link-section">
            
            <ul>
              <li>
              <a href={part} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              {part}
            </a>
              </li>
            </ul>
            
          </div>
        );
      } if (part.trim() && !part.match(/^(https?|ftp|file)/)) {
        return (
          <div key={key} className="text-section">
            
            <p>{part.trim()}</p>
          </div>
        );
      }
      return null;
    });
  };
  

  
  // if (loading) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center" >
  //       <Spinner animation="border" variant="primary" />
  //     </div>
  //   );
  // }

  if (error || productSupplement.length === 0) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center" >
        <Alert variant="info">
          No product supplements exist currently.
        </Alert>
      </div>
    );
  }

  return (
    <>
      {/* <p className="h2 margin-top-half">{userNameActionPlan}</p>

      <button
        type="button"
        onClick={handleBtnActionPlan}
        className="myPlan-expanding-btn width9030" style={{"backgroundColor":"#ffcbe1"}}>
        <div className="flex flex-row flex-item-center flex-space-around margin-left1">
          <p className="flex7 font16px color-black">My Action Plan</p>
          <i className="bi bi-arrow-right-circle flex1 arrow-more-less fw-bold" />

          {/* {actionPlanVisible === false ? (
            <img src={arrowMore} alt="" className="flex1 arrow-more-less" />
          ) : (
            <img src={arrowLess} alt="" className="flex1 arrow-more-less" />
          )} */}
        {/* </div>
      </button> */}

      {/* {actionPlanVisible === true && (
        <div className="myPlan-expanding-text">{parse(actionPlan)}</div>
      )} */}

      <button
        type="button"
        onClick={handleBtnSupplementSchedule}
        className="myPlan-expanding-btn width9030">
        <div className="flex flex-row flex-item-center flex-space-around margin-left1">
          <p className="flex7 font16px color-black">My Supplement Schedule</p>

          {supplementScheduleVisible === false ? (
            <img src={arrowMore} alt="" className="flex1 arrow-more-less" />
          ) : (
            <img src={arrowLess} alt="" className="flex1 arrow-more-less" />
          )}
        </div>
      </button>

      {supplementScheduleVisible === true && productSupplement && (
        <div className="flex flex-col flex-item-center ">
                    <p className="flex flex-center text-dark"><b>UPDATED ON: &nbsp;</b> {formattedDate} <b>&nbsp;AT&nbsp;</b> {formattedTime}</p>
          <table className="supp-schedule margin1 width9055 flex-space-around flex-wrap gap1" cellSpacing="0">
            <thead>
              <tr>
                <th>Supplement</th>
                <th>Instruction</th>
              </tr>
            </thead>
            <tbody>
              {productSupplement.map((item) => (
                
                <tr key={item.id}>
                  <td>{item.longDisplayNames}</td>
                  <td>{item.directions}</td>
                  {item.link && (
                    
                      <button
                        type="button"
                        className="fmButton ms-2"
                        onClick={() => item.link && window.open(item.link, '_blank')}>
                        Buy
                      </button>
                      
                  ) }
                </tr>
              ))}
            </tbody>
          </table>
          
          {personalizedSupplements.right_side_ans.trim() && (
            <>
            <p>&nbsp;</p>
            
              <p className="flex flex-start  mx-3 text-dark  "><b>{personalizedSupplements.question}</b></p>
              {/* <textarea
               
                className="border rounded p-2 text-dark flex flex-start mx-3 width9030"
                value={personalizedSupplements.right_side_ans}
                readOnly
              /> */}
              <div className=" linkify-content border rounded p-2 text-dark flex flex-start mx-3 width9055 flex-space-around flex-wrap gap1 text-break">
              {linkify(personalizedSupplements.right_side_ans)}
              </div>
            
            </>
          )}


          <p>&nbsp;</p>
          <button
            type="button"
            className="fmButton"
            onClick={handleBtnNutritionInfo}>
            Go to More Info Page
          </button>
        </div>
      )}
    </>
  );
};

export default MyPlanComp;
