import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import FieldError from '../FieldError/FieldError';
import RadioInputGroup from '../RadioInputGroup/RadioInputGroup';
import { IInputOption } from '../../../types/FormFields';

export interface IFieldRadioInputGroup extends FieldRenderProps<number> {
  options: IInputOption[];
}

const FieldRadioInputGroup: React.FC<IFieldRadioInputGroup> = ({
  input,
  meta,
  compact,
  options,
}) => {
  const onChange = (value: number | string) => {
    input.onChange(value);
  };
  return (
    <div>
      <RadioInputGroup
        onChange={onChange}
        value={input.value}
        options={options}
      />
      <FieldError
        compact={compact}
        visible={!!(meta.touched && meta.error)}
        errorId={meta.error}
      />
    </div>
  );
};

export default FieldRadioInputGroup;
