import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import { useNavigate, useParams } from 'react-router';
import VideoComp from '../components/VideoComp';
import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk0L01Expectations.json';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';

import { selectLessonChildren, selectLessonDetail } from '../redux/slices/sixweekprogram';
import { getLessonDetailAction, getLessonsChildrenAction } from '../redux/actions/sixweekprograms';


const SixWk0L01Expectations = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const lessonDetail = useAppSelector(selectLessonDetail);

  const navigate = useNavigate();

  const lessonChildren = useAppSelector(selectLessonChildren);

  const completedSteps = useAppSelector(
    ({ visitedscreens }) => visitedscreens.completedSteps,
  );

  const dispatch = useAppDispatch();

  const params = useParams();


  useEffect(() => {
    if (params.id) {
      dispatch(getLessonDetailAction({ id: parseInt(params.id, 10) }));
      dispatch(getLessonsChildrenAction({ 'filter[parent_id][_eq]': parseInt(params.id, 10), sort: "order,id" }));
    }
  }, [params.id]);


  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <LessonPageHeaderComp weekNum={parseInt(params.weekNum || "0", 10)} pageTitle={lessonDetail?.program_title || ''} />

      {lessonDetail?.image_url && <img src={lessonDetail?.image_url} alt=""  className="sixwkimage"/>}

      {lessonDetail?.video_url && <div>
        <VideoComp url={lessonDetail?.video_url} />
      </div>}

      <p className="page-text ">{parse(lessonDetail?.program_content || '')}</p>

      <div className="flex flex-col flex-bunched gap-half">
        {
          lessonChildren.map((item) => (
            <button
              type="button"
              style={{ backgroundColor: completedSteps.includes(`SixWkLesson/${item.id}/${params.weekNum}`) ? 'yellow' : "inherit" }}
              onClick={() => navigate(`/SixWkLesson/${item.id}/${params.weekNum}`)}
              className="fmButton">
              {item.listing_label}
            </button>
          ))
        }

      </div>
      <TickComp />
      <FooterBtns isLesson={lessonDetail?.parent_id === 0 ? 1 : 0} id={lessonDetail?.id || 0} weekNum={parseInt(params.weekNum || "0", 10)} />
      <FooterCopyright />
    </div>
  );
};

export default SixWk0L01Expectations;
