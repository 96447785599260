import React, { useEffect, useState } from 'react';
import TopComp from '../components/TopComp';
import '../css/femmar.css';
import { REGISTER_EMAIL_KEY } from '../constants/global';
import { resendVerificationEmail } from '../api/auth';
import { showErrorMessage, showSuccsesMessage } from '../utils/banner.utils';

const Register2 = () => {
  const [email] = useState(localStorage.getItem(REGISTER_EMAIL_KEY));
  const [emailSent, setEmailSent] = useState(false);

  const resendEmail = async () => {
    try {
      if (email) {
        await resendVerificationEmail({ email });
      }
    } catch (e) {
      showErrorMessage(e);
    }
    showSuccsesMessage('Mail sent successfully');
    setEmailSent(true);
  };

  useEffect(() => {
    localStorage.removeItem(REGISTER_EMAIL_KEY);
  }, []);

  return (
    <div>
      <TopComp />
      <div className="flex flex-col flex-item-center flex-space-around gap1 margin2">
        <p className="portal-h1">Verify Your Account</p>
        <p className="h2">Check Your Email</p>

        <p>
          We have sent {email} an email with instructions to verify your
          account.
        </p>
        <p>Please click on the link in the email to verify your account.</p>
        <p>If you do not see the email, please check your spam folder.</p>
        <p>Note that emails can take a few minutes to arrive.</p>
        {!emailSent && (
          <button type="button" className="color-purple" onClick={resendEmail}>
            <u>Click here if you have not received an email.</u>
          </button>
        )}
      </div>
    </div>
  );
};

export default Register2;
