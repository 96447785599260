import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import MultipleChoice from '../MultipleChoice/MultipleChoice';
import { IQuestionOptions } from '../../../types/models/Quiz';
import FieldError from '../FieldError/FieldError';

export interface IFieldMultipleChoice extends FieldRenderProps<number[]> {
  title: string;
  options: IQuestionOptions[];
  correctValue: number[];
}

const FieldMultipleChoice: React.FC<IFieldMultipleChoice> = ({
  input,
  meta,
  compact,
  title,
  options,
  correctValue,
}) => {
  const onChange = (value: number) => {
    let newValue = [];
    if (input.value.includes(value)) {
      newValue = input.value.filter((inputValue) => inputValue !== value);
    } else {
      newValue = [...input.value, value];
    }
    input.onChange(newValue);
  };
  return (
    <div className="ms-4">
      <MultipleChoice
        onChange={onChange}
        value={input.value}
        title={title}
        options={options}
        correctValue={correctValue}
      />
      <FieldError
        compact={compact}
        visible={!!(meta.touched && meta.error)}
        errorId={meta.error}
      />
    </div>
  );
};

export default FieldMultipleChoice;
