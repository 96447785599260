import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk0L10Alcohol.json';
import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';
import image from '../images/lessons/W0L10Alcohol.jpg';
import VideoComp from '../components/VideoComp';

const SixWk0L10Alcohol = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle } = myContent.trans[langNum];
  const pageText = parse(myContent.trans[langNum].pageText);

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
           <LessonPageHeaderComp pageTitle={pageTitle} />
      <img className="theBigImage" src={image} alt="" />

      <VideoComp url="https://vimeo.com/850318386/9d1ab213a6?share=copy" />
   
      <p className="page-text">{pageText}</p>
      <TickComp />
      <FooterBtns />
      <FooterCopyright />
    </div>
  );
};

export default SixWk0L10Alcohol;
