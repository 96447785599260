import React from 'react';
import QuestionComp from '../components/QuestionComp';
import { QuizTypes } from '../types/models/QuizTypes';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import FooterCopyright from '../components/FooterCopyright';

const SixWk5L8Quiz = () => {
  return (
    <div className="flex flex-col flex-item-center flex-space-between gap-half page-full">
      <TopComp />
      <PageTitleComp pageTitle="5.8 Quiz for Week 5" />
      <QuestionComp
        weekNumber={5}
        className="flex flex-col flex-item-center flex-space-between gap1"
        type={QuizTypes.Quiz}
      />
      <FooterCopyright />
    </div>
  );
};

export default SixWk5L8Quiz;
