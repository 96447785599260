import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import { useNavigate } from 'react-router-dom';
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';
import myContent from '../jsonContent/trans_SupplementInfo.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';

const SupplementInfo = () => {
  const navigate = useNavigate();

  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />

      <p className="h1">{pageTitle}</p>
      <div className="page-text">{parse(pageText)}</div>

      <FooterCopyright />
    </div>
  );
};

export default SupplementInfo;
