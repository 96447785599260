import React from 'react';
import '../css/femmar.css';
import { useNavigate } from 'react-router-dom';
import { arrayScreens } from '../constants/global';
import myContent from '../jsonContent/trans_FooterBtns.json';
import { changeCurrentStep } from '../redux/slices/visitedscreens';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';

interface FooterBtnsProps {
    nextButtonLabel?: string;
    showPrevButton?: boolean;
}

const WelcomeFooterBtns: React.FC<FooterBtnsProps> = ({
    nextButtonLabel,
    showPrevButton = true,
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const langNum = useAppSelector(selectLanguageIndex);
    const completedSteps = useAppSelector(
        ({ visitedscreens }) => visitedscreens.completedSteps,
    );
    const { prevStep, nextStep } = myContent.trans[langNum];

    const currentLocation = window.location.pathname;
    const currentLocationNoSlash = currentLocation.slice(1);
    const currentSectionIndex = arrayScreens.indexOf(currentLocationNoSlash);
    const prevSectionIndex = currentSectionIndex - 1;
    const nextSectionIndex = currentSectionIndex + 1;

    const handlePrev = () => {
        navigate(`/${arrayScreens[prevSectionIndex]}`);
        dispatch(changeCurrentStep(arrayScreens[prevSectionIndex]));
    };

    const handleNext = () => {
        navigate(`/${arrayScreens[nextSectionIndex]}`);
        dispatch(changeCurrentStep(arrayScreens[nextSectionIndex]));
    };

    return (
        <div className="margin2">
            <div className="flex flex-row flex-space-around gap3">
                {showPrevButton && prevSectionIndex > 0 && (
                    <button type="button" onClick={handlePrev} className="footer-btn">
                        <div className="flex flex-row flex-items-center flex-space-around gap0 color-black">
                            <span className="font-xxl">&lt;</span>&nbsp;
                            <span className="font14px margin-top08R">{prevStep}</span>
                        </div>
                    </button>
                )}

                {nextSectionIndex < arrayScreens.length && (
                    <button
                        type="button"
                        disabled={!completedSteps.includes(currentLocationNoSlash)}
                        onClick={handleNext}
                        className="footer-btn">
                        <div className="flex flex-row flex-items-center flex-space-around gap0 color-black">
                            <span className="font14px margin-top08R">
                                {nextButtonLabel || nextStep}
                            </span>&nbsp;
                            <span className="font-xxl">&gt;</span>
                        </div>
                    </button>
                )}
            </div>
        </div>
    );
};

export default WelcomeFooterBtns;