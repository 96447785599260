// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect, useState } from 'react';
import '../css/femmar.css';
import { Link, useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../redux/store';
import TopComp from '../components/TopComp';
import { AppRoutes } from '../constants/routes';
import { getAppointmentPoolAction } from '../redux/actions/appointmentpool';


const BookConsultant = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const appointmentPool = useAppSelector((state) => state.appointmentpool.appointmentPool);

    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(false);


    const getAppointmentPool = async () => {
        try {
            await dispatch(getAppointmentPoolAction());
        } catch (err: any) {
            console.log(err);
        }
        setLoading(false);
    };

    useEffect(() => {
        getAppointmentPool();
        
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);
    
    

    const saveBooking = () => {
        //  document.execCommand('selectAll');
        // const range = new Range();
        // const nextNode = document.getElementById("appointmentpool") as any;
        // console.log(" *** nextNode ", nextNode);
        // range.selectNodeContents(nextNode); // or selectNode(p) to select the <p> tag too
        // document.getSelection()?.removeAllRanges(); // clear existing selection if any
        // document.getSelection()?.addRange(range);
        // const textContent = document.getSelection()?.toString();
        navigate(AppRoutes.DayOneForm);
    }


    useEffect(() => {
        document.onclick = () => {
            console.log(" *** document clicked ");
        }
    }, [])

    return (
        <div className="flex flex-col flex-item-center flex-space-between gap1">
            <TopComp />
            {
                loading ? <div>Verifying your payment...</div>
                    : <div className="row" style={{ height: "80vh", width: "100%" }}>
                        {isMobile && (
                            <Alert variant="info" className="mb-3 mt-1">
                                To select a date and time, you need to scroll within the calendar below.
                            </Alert>
                        )}
                        <div className="col-md-8">
                            <iframe id="appointmentpool" width="100%" height="100%" title="Appointment Pool" src={appointmentPool?.url} />
                            
                        </div>
                        <div className="col-md-4 d-flex justify-content-center align-items-top mt-4">
                            <div>
                                <h6 className="mb-5 text-left"> <b>Instructions for scheduling an <br /> appointment</b>
                                <br /> <br />
                                1. Wait for the calendar to load <br /> <br />
                                2. Select date and time and press NEXT <br />to confirm the meeting <br /><br />
                                3. When you see the confirmation of the appointment. Enter name, email and telephone number. It is important to use 
                                the <b>same email used to signup for the program.</b>
                                <br /> <br />
                                4. Click on the pre-consultation questionnaire for the appointment.
                                  <br /> <br />
                                {/* <i> You will also receive and email <br />
                                notification and calendar invite for the <br />
                                meeting</i> */}
                                
                                </h6>
                                <a className="text-dark p-3 btn " href="#" onClick={saveBooking} style={{"fontSize":"1.1rem", "background":"#d16eff", "border":"1px solid #000","borderRadius":"4px"}}>Go to Pre-consultation Questionnaire</a>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};

export default BookConsultant;
