import { IOrder, PaymentStatuses } from '../types/models/Order';
import { UserRoles } from '../types/models/User';

export const isFreeUser = (userRole: UserRoles) => {
  return [UserRoles.Admin, UserRoles.Freebies, UserRoles.Staff].includes(
    userRole,
  );
};

export const checkIfUserPaid = (orders: IOrder[]) => {

  return true;
  // return (orders || []).some(
  //   (order) => order.paymentStatus === PaymentStatuses.success,
  // );
};
