import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import { useNavigate } from 'react-router-dom';

import TopComp  from '../components/TopComp';



import myContent from '../jsonContent/trans_Search.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppSelector } from '../redux/store';

const Search = () => {
  const navigate = useNavigate();
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle } = myContent.trans[langNum];
  const pageText = parse(myContent.trans[langNum].pageText);
  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <TopComp />

      <p className="h1">{pageTitle}</p>
      <div className="page-text">{pageText}</div>



    </div>
  );
};

export default Search;
