import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import VideoComp from '../components/VideoComp';

import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk0L04Fruit.json';
import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';
import image from '../images/lessons/W0L04Fruit.jpg';

const SixWk0L04Fruit = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle } = myContent.trans[langNum];
  const pageText = parse(myContent.trans[langNum].pageText);

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <LessonPageHeaderComp pageTitle={pageTitle} />
      <img className="theBigImage" src={image} alt="" />
      <div>
        <VideoComp url="https://vimeo.com/850719462/e81fb25ff9?share=copy" />
      </div>

      <p className="page-text">{pageText}</p>
      <TickComp />
      <FooterBtns />
      <FooterCopyright />
    </div>
  );
};

export default SixWk0L04Fruit;
