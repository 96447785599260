import React from 'react';
import parse from 'html-react-parser';
import '../css/femmar.css';
import LessonPageHeaderComp from '../components/LessonPageHeaderComp';
import FooterBtns from '../components/FooterBtns';
import FooterCopyright from '../components/FooterCopyright';
import TickComp from '../components/TickComp';
import myContent from '../jsonContent/trans_SixWk1L5SympHeadaches.json';

import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';
import VideoComp from '../components/VideoComp';



const SixWk1L5SympHeadaches = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle, pageText } = myContent.trans[langNum];

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap1">
      <LessonPageHeaderComp pageTitle={pageTitle} />


      <VideoComp url="https://vimeo.com/851372341/b101ab502b?share=copy" />

      <div className="page-text">{parse(pageText)}</div>

      <TickComp />
      <FooterBtns />
      <FooterCopyright />
    </div>
  );
};

export default SixWk1L5SympHeadaches;
