import React from 'react';
import { Field } from 'react-final-form';
import { noop } from 'lodash';
import { IQuestion } from '../../../types/models/Quiz';
import { getQuestionFieldName } from '../../../utils/question.utils';
import FieldHeight from '../FieldHeight/FieldHeight';
import { heightValidator } from '../../../utils/validations.utils';

interface IHeightQuestionProps {
  question: IQuestion;
}

const HeightQuestion: React.FC<IHeightQuestionProps> = ({ question }) => {
  return (
    <div className="padding1 bg-white border-round flex-item-center">
      <p className="h3">{question.text}</p>
      <Field
        component={FieldHeight}
        name={getQuestionFieldName(question.id)}
        validate={question.optional ? noop : heightValidator}
      />
    </div>
  );
};

export default HeightQuestion;
