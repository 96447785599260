import React, { useState } from 'react';
import '../css/femmar.css';
import parse from 'html-react-parser';
import TopComp from '../components/TopComp';
import PageTitleComp from '../components/PageTitleComp';
import FooterCopyright from '../components/FooterCopyright';
import data from '../jsonContent/nutritionalAssessmentTable.json';
import myContent from '../jsonContent/trans_NutritionalAssessment.json';
import { useAppSelector } from '../redux/store';
import { selectLanguageIndex } from '../redux/slices/auth';
import bigPicture from '../images/pictures/NutritionalDeficiences.png';

interface Ailment {
  id: number;
  sign: string;
  indicates: string;
  action: string;
  tag: string;
}

const NutritionalAssessment: React.FC = () => {
  const langNum = useAppSelector(selectLanguageIndex);
  const {
    pageTitle,
    pageText1,
    pageText2,
    pageText3,
    tagInstructionsText,
    pageText4,
    pageText5,
    pageText6,
    pageText7,
  } = myContent.trans[langNum];

  const [selectedTag, setSelectedTag] = useState(' ');

  const handleBtnNutass = (event: any) => {
    console.log(event.target.value);
    setSelectedTag(event.target.value);
  };

  return (
    <div>
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />

      <div className="flex flex-col flex-item-center gap0">
        <p className="page-text">{parse(pageText1)}</p>
        <p className="page-text">{parse(pageText2)}</p>
        <p className="page-text">{parse(pageText3)}</p>

        <img
          src={bigPicture}
          alt="Nutritional Deficiencies"
          className="width9050"
        />

        <p className="page-text">{parse(tagInstructionsText)}</p>
      </div>

      <div className="flex flex-row flex-item-center flex-bunched gap0 flex-wrap">
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Appetite">
          Appetite
        </button>
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Hair">
          Hair
        </button>
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Limbs">
          Limbs
        </button>
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Mood">
          Mood
        </button>
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Mouth">
          Mouth
        </button>
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Nails">
          Nails
        </button>
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Pain">
          Pain
        </button>
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Palpitations">
          Palpitations
        </button>
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Periods">
          Periods
        </button>
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Sight">
          Sight
        </button>
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Skin">
          Skin
        </button>
        <button
          className="fmButton"
          onClick={handleBtnNutass}
          type="button"
          value="Tired">
          Tired
        </button>
      </div>

      <br />

      <table className="border-thin width90 table-grid">
        <thead>
          <tr>
            <th>Sign or Symptom</th>
            <th>What could it indicate?</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((ailment: Ailment) => (
            <tr
              className={
                ailment.tag === selectedTag
                  ? 'bg-yellow font600'
                  : 'bg-whitesmoke color-gray'
              }
              key={ailment.id}>
              <td>{ailment.sign}</td>
              <td>{ailment.indicates}</td>
              <td>{ailment.action}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <br />

      <div className="flex flex-col flex-item-center gap0">
        <p className="page-text">{parse(pageText4)}</p>
        <p className="page-text">{parse(pageText5)}</p>
        <p className="page-text">{parse(pageText6)}</p>
        <p className="page-text">{parse(pageText7)}</p>
      </div>

      <FooterCopyright />
    </div>
  );
};

export default NutritionalAssessment;
